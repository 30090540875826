import React, { useEffect, useId, useState } from 'react';
import { AppDispatch, RootState } from '../../../services/store';
import { useDispatch, useSelector } from 'react-redux';
import PuffLoader from 'react-spinners/PuffLoader';
import { Operation as OperationType } from '../../../types/operation';
import { emptyOperation } from './utils';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import { createOperation, deleteOperation, updateOperation } from '../../../services/operations/operationSlice';
import { SearchDropdown, SearchDropdownProps } from '../../components/SearchDropdown';
import { useNavigate, useParams } from 'react-router-dom';
import { emptyCheck } from '../Check/utils';
import { Check as CheckType } from '../../../types/check';
import { Loan as LoanType } from '../../../types/loan';
import { Cession as CessionType } from '../../../types/cession';
import Dropdown from '../../components/Dropdown';
import { integer } from 'aws-sdk/clients/cloudfront';
import { fetchClientsV2 } from '../../../services/clients/clientSlice';
import { fetchDrawers } from '../../../services/drawers/drawerSlice';
import { emptyLoan } from '../Loan/utils';
import { uploadFile } from '../Check/uploadFile';
import { OPERATIONS_ROUTE } from '../../../utils/routes';
import axios from 'axios';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import { emptyCession } from '../Cession/utils';
import ConfirmationModal from '../../components/ConfirmationModal';
import Table from '../../components/Table';
import { TableHeaders as TableHeadersCheck } from '../Checks/consts';
import { formatDataToTable as formatDataToTableCheck } from '../Checks/utils';
import { TableHeaders as TableHeadersLoan } from '../Loans/consts';
import { formatDataToTable as formatDataToTableLoan } from '../Loans/utils';
import { TableHeaders as TableHeadersCession } from '../Cessions/consts';
import { formatDataToTable as formatDataToTableCession } from '../Cessions/utils';
import { fetchChecks } from '../../../services/checks/checkSlice';
import { fetchCessions } from '../../../services/cessions/cessionSlice';
import { fetchLoans } from '../../../services/loans/loansSlice';

const operationTypes = [
  { label: 'Cheque', value: 'cheque' },
  { label: 'Cesión', value: 'cesion' },
  { label: 'Prestamo', value: 'prestamo' },
];

interface OperationRequest extends OperationType {
  checks?: CheckType[];
  loans?: LoanType[];
  cessions?: CessionType[];
}

const Operation = () => {
  const { id } = useParams();
  const token = localStorage.getItem('token');

  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const clients = useSelector((state: RootState) => state.client.clientsV2);
  const isLoadingClient = useSelector((state: RootState) => state.client.isLoading);

  const drawers = useSelector((state: RootState) => state.drawer.drawers);
  const isLoadingDrawers = useSelector((state: RootState) => state.drawer.isLoading);

  const [operation, setOperation] = useState<OperationType>(emptyOperation);
  const isLoading = useSelector((state: RootState) => state.operation.isLoading);
  const error = useSelector((state: RootState) => state.operation.error);
  const success = useSelector((state: RootState) => state.operation.success);

  const [operationType, setOperationType] = useState<'cheque' | 'cesion' | 'prestamo'>('cheque');

  const [loadingSubmit, setIsLoadingSubmit] = useState(false);

  const [checks, setChecks] = useState<CheckType[]>([]);
  const [loans, setLoans] = useState<LoanType[]>([]);
  const [cessions, setCessions] = useState<CessionType[]>([]);

  const [oldChecks, setOldChecks] = useState<CheckType[]>([]);
  const [oldLoans, setOldLoans] = useState<LoanType[]>([]);
  const [oldCessions, setOldCessions] = useState<CessionType[]>([]);

  const [clientValues, setClientValues] = useState<{ value: string; label: string }[]>([]);
  const [drawerValues, setDrawerValues] = useState<{ value: string; label: string }[]>([]);

  //nasty
  const [afterEdit, setAfterEdit] = useState(false);
  const [end, setEnd] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (afterEdit && id) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}/operations/${id}`, { headers })
        .then((response) => {
          const res = response.data.data.operation;
          setOperation(res);
          handleClientSelect(res.clientId);
          setOperationType(res.operationType);
          setOldChecks(res.checks ?? []);
          setOldLoans(res.loans ?? []);
          setOldCessions(res.cessions ?? []);
        })
        .finally(() => setIsFetching(false));
    }
  }, [success])

  useEffect(() => {
    if (id !== undefined) {
      setIsFetching(true);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}/operations/${id}`, { headers })
        .then((response) => {
          const res = response.data.data.operation;
          setOperation(res);
          handleClientSelect(res.clientId);
          setOperationType(res.operationType);
          setOldChecks(res.checks ?? []);
          setOldLoans(res.loans ?? []);
          setOldCessions(res.cessions ?? []);
        })
        .finally(() => setIsFetching(false));
    }
    if (clients == undefined) dispatch(fetchClientsV2(token || ''));
    if (drawers == undefined || drawers.length == 0) dispatch(fetchDrawers(token || ''));
  }, []);

  useEffect(() => {
    if (success && !isEditing && end) {
      navigate(OPERATIONS_ROUTE);
    }
  }, [success, end]);

  const handleClientSelect = (client: string) => {
    setOperation((previousOperation: OperationType) => ({
      ...previousOperation,
      clientId: client,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOperation((previousOperation: OperationType) => ({
      ...previousOperation,
      [name]: value,
    }));
  };

  const pictureId = useId();

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    if (operation['_id']) {
      let request = { ...operation, operationType };

      if (operationType == 'cesion') {
        const newCessions: CessionType[] = cessions.map((c) => {
          return {
            ...c,
            clientId: request.clientId == '' ? undefined : request.clientId,
          };
        });
        request = {
          ...request,
          cessions: newCessions,
        };
      }
      if (operationType == 'cheque') {
        const newChecks: CheckType[] = await Promise.all(
          checks.map(async (c) => {
            let frontFileName = '';
            let backFileName = '';
            if (c.file) {
              frontFileName = `${pictureId}${(c.file as File).name}`;
              await uploadFile(c.file as File, frontFileName).then((response) => {
                if (response !== 'OK') {
                  alert('Error subiendo las imagenes');
                  return;
                }
              });
            }
            if (c.backfile) {
              backFileName = `${pictureId}${(c.file as File).name}`;
              await uploadFile(c.file as File, backFileName).then((response) => {
                if (response !== 'OK') {
                  alert('Error subiendo las imagenes');
                  return;
                }
              });
            }
            return {
              ...c,
              file: c.file ? frontFileName : undefined,
              backfile: c.backfile ? backFileName : undefined,
              clientId: request.clientId == '' ? undefined : request.clientId,
              phone: request.phone,
              drawerId: c.drawerId == '' ? undefined : c.drawerId,
            };
          }),
        );
        request = {
          ...request,
          checks: newChecks,
        };
      }
      if (operationType == 'prestamo') {
        const newLoans: LoanType[] = loans.map((l) => {
          return {
            ...l,
            clientId: request.clientId ?? '',
          };
        });
        request = {
          ...request,
          loans: newLoans,
        };
      }

      setOldCessions([...oldCessions, ...cessions]);
      setOldLoans([...oldLoans, ...loans]);
      setOldChecks([...oldChecks, ...checks]);

      setCessions([]);
      setLoans([]);
      setChecks([]);

      dispatch(updateOperation({ id: id, operationData: request, token: token || '' }));
      switch (operationType) {
        case 'cheque':
          dispatch(fetchChecks(token || ''));
          break;
        case 'cesion':
          dispatch(fetchCessions(token || ''));
          break;
        case 'prestamo':
          dispatch(fetchCessions(token || ''));
          break;
      }
    } else {
      let request: OperationRequest = { ...operation, operationType };
      if (operationType == 'cesion') {
        const newCessions: CessionType[] = cessions.map((c) => {
          return {
            ...c,
            clientId: request.clientId == '' ? undefined : request.clientId,
          };
        });
        request = {
          ...request,
          cessions: newCessions,
        };
      }
      if (operationType == 'cheque') {
        const newChecks: CheckType[] = await Promise.all(
          checks.map(async (c) => {
            let frontFileName = '';
            let backFileName = '';
            if (c.file) {
              frontFileName = `${pictureId}${(c.file as File).name}`;
              await uploadFile(c.file as File, frontFileName).then((response) => {
                if (response !== 'OK') {
                  alert('Error subiendo las imagenes');
                  return;
                }
              });
            }
            if (c.backfile) {
              backFileName = `${pictureId}${(c.file as File).name}`;
              await uploadFile(c.file as File, backFileName).then((response) => {
                if (response !== 'OK') {
                  alert('Error subiendo las imagenes');
                  return;
                }
              });
            }
            return {
              ...c,
              file: c.file ? frontFileName : undefined,
              backfile: c.backfile ? backFileName : undefined,
              clientId: request.clientId == '' ? undefined : request.clientId,
              phone: request.phone,
              drawerId: c.drawerId == '' ? undefined : c.drawerId,
            };
          }),
        );
        request = {
          ...request,
          checks: newChecks,
        };
      }
      if (operationType == 'prestamo') {
        const newLoans: LoanType[] = loans.map((l) => {
          return {
            ...l,
            clientId: request.clientId == '' ? undefined : request.clientId,
          };
        });
        request = {
          ...request,
          loans: newLoans,
        };
      }
      dispatch(createOperation({ operationData: request, token: token || '' }));
      setEnd(true);
    }
    setAfterEdit(true);
    setIsLoadingSubmit(false);
  };

  const handleOperationChange = (event: any) => {
    setOperationType(event.target.value);
  };

  useEffect(() => {
    if (clients) {
      const values = clients.map((client) => ({
        value: client._id ? client._id : client.email,
        label: client.name + ' - ' + client.email + ' - ' + `${client.legalName || ''}`,
      }));
      setClientValues(values);
    }
  }, [clients]);

  useEffect(() => {
    if (drawers) {
      const values = drawers.map((drawer) => ({
        value: drawer._id ? drawer._id : drawer.rut,
        label: drawer.name + ' - ' + drawer.rut + ' - ' + `${drawer.legalName || ''}`,
      }));
      setDrawerValues(values);
    }
  }, [drawers]);

  const addNewCheck = () => {
    const newCheck = { ...emptyCheck, currency: 'peso' };
    setChecks((old) => [...old, newCheck]);
  };

  const updateCheck = (e: any, index: integer) => {
    const { name, value } = e.target;
    const newChecks = [...checks];
    newChecks[index] = { ...newChecks[index], [name]: value };
    setChecks(newChecks);
  };

  const deleteCheck = (index: integer) => {
    const newChecks = checks.filter((item, i) => i !== index);
    setChecks(newChecks);
  };

  const addNewLoan = () => {
    const newLoan = emptyLoan;
    setLoans((old) => [...old, newLoan]);
  };

  const updateLoan = (e: any, index: integer) => {
    const { name, value } = e.target;
    const newLoans = [...loans];
    newLoans[index] = { ...newLoans[index], [name]: value };
    setLoans(newLoans);
  };

  const deleteLoan = (index: integer) => {
    const newLoans = loans.filter((item, i) => i !== index);
    setLoans(newLoans);
  };

  const addNewCession = () => {
    const newCession = emptyCession;
    setCessions((old) => [...old, newCession]);
  };

  const updateCession = (e: any, index: integer) => {
    const { name, value } = e.target;
    const newCessions = [...cessions];
    newCessions[index] = { ...newCessions[index], [name]: value };
    setCessions(newCessions);
  };

  const deleteCession = (index: integer) => {
    const newCessions = cessions.filter((item, i) => i !== index);
    setCessions(newCessions);
  };

  const updateImage = (name: string, file: File, friendly: string, index: number) => {
    const newChecks = [...checks];
    newChecks[index] = { ...newChecks[index], [name]: file, ['friendly_' + name]: friendly };
    setChecks(newChecks);
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteOperation({ id: id, token: token || '' }));
      navigate(OPERATIONS_ROUTE);
    }
  };

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: operation['_id'] !== undefined,
    deleteButton: operation['_id'] !== undefined,
  };

  const handleCheckClick = (id: string) => {
    setIsLoadingSubmit(true);
    dispatch(fetchChecks(token || '')).then(() => navigate('/cheques/' + id));
  }
  const handleCessionClick = (id: string) => {
    setIsLoadingSubmit(true);
    dispatch(fetchCessions(token || '')).then(() => navigate('/cesiones/' + id));
  }
  const handleLoanClick = (id: string) => {
    setIsLoadingSubmit(true);
    dispatch(fetchLoans(token || '')).then(() => navigate('/prestamos/' + id));
  }

  const onSelectOptionClient = (id: string) => {
    if (clients) {
      const selectedClient = clients.find((client) => client._id === id);
      if (selectedClient) {
        handleClientSelect(selectedClient._id ? selectedClient._id : '');
      }
    }
  };

  if (isFetching || isLoading || isLoadingClient || isLoadingDrawers || loadingSubmit)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20, height: '100%' }}>
        <PuffLoader color="#3D9FE0" />
      </div>
    );
  else
    return (
      <div className="clientManagement">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}></div>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar el cheque ${operation?._id}?`}
        />
        <BackButton />
        <TopContent
          title={!operation['_id'] ? 'Registrar Operación' : isEditing ? 'Editar Operación' : operation['_id'].toString().slice(-6).toString()}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={() => setIsEditing((prevState) => !prevState)}
          onClickDelete={() => setDeleteModalOpen(true)}
        />
        <div className="formRegister form operationForm newOperation">
          <div className="formRegister__wrapper">
            <div className="formRegister__inputArea">

              <SearchDropdown
                label="Cliente:"
                defaultValue={operation.clientId ?? ''}
                options={clientValues}
                onSelectOption={handleClientSelect}
                disabled={!isEditing && !!operation['_id']}
                onDelete={() => handleClientSelect('')}
              />

              <FormInput
                label="Teléfono"
                name="phone"
                value={operation['phone'] ? operation['phone']?.toString() : ''}
                onChange={handleChange}
                disabled={!isEditing && !!operation['_id']}
              />

              <div className="radioWrapper">
                <label htmlFor="cheque">
                  <span>Cheque</span>
                  <input
                    type="radio"
                    value={'cheque'}
                    onChange={handleOperationChange}
                    checked={operationType === 'cheque'}
                    name="cheque"
                    disabled={id !== undefined}
                  />
                </label>
                <label htmlFor="cesion">
                  <span>Cesión</span>
                  <input
                    type="radio"
                    value={'cesion'}
                    onChange={handleOperationChange}
                    checked={operationType === 'cesion'}
                    name="cesion"
                    disabled={id !== undefined}
                  />
                </label>
                <label htmlFor="prestamo">
                  <span>Préstamo</span>
                  <input
                    type="radio"
                    value={'prestamo'}
                    onChange={handleOperationChange}
                    checked={operationType === 'prestamo'}
                    name="prestamo"
                    disabled={id !== undefined}
                  />
                </label>
              </div>
            </div>

            {operationType == 'cesion' && <>

              <h1>Cesiones</h1>
              <br />

              {id !== undefined &&

                <Table
                  headers={Object.values(TableHeadersCession).filter((header) => header !== TableHeadersCession.CLIENT)}
                  data={oldCessions ? formatDataToTableCession(oldCessions) : []}
                  onRowClick={handleCessionClick}
                  showDelete={!operation['_id'] || isEditing}
                  showId={true}
                />}

              {(id === undefined || isEditing) && <>
                {cessions.map((c, i) => <CessionForm id={i} show={id === undefined || isEditing} cession={c} updateCession={updateCession} deleteCession={deleteCession} />)}
                <span className='btnOperations' onClick={addNewCession}>
                  + Agregar una nueva cesión
                </span>
              </>}

            </>}

            {operationType == 'prestamo' && <>

              <h1>Préstamos</h1>
              <br />

              {id !== undefined &&

                <Table
                  headers={Object.values(TableHeadersLoan).filter((header) => header !== TableHeadersLoan.CLIENT)}
                  data={oldLoans ? formatDataToTableLoan(oldLoans) : []}
                  onRowClick={handleLoanClick}
                  showDelete={!operation['_id'] || isEditing}
                  showId={true}
                />}

              {(id === undefined || isEditing) && <>
                {loans.map((l, i) => <LoanForm id={i} show={id === undefined || isEditing} loan={l} updateLoan={updateLoan} deleteLoan={deleteLoan} />)}
                <span className='btnOperations' onClick={addNewLoan}>
                  + Agregar un nuevo préstamo
                </span>
              </>}

            </>}

            {operationType == 'cheque' && <>

              <h1>Cheques</h1>
              <br />

              {id !== undefined &&

                <Table
                  headers={Object.values(TableHeadersCheck).filter((header) => header !== TableHeadersCheck.CLIENT)}
                  data={oldChecks ? formatDataToTableCheck(oldChecks) : []}
                  onRowClick={handleCheckClick}
                  showDelete={!operation['_id'] || isEditing}
                  showId={true}
                />}

              {(id === undefined || isEditing) && <>
                {checks.map((c, i) => <CheckForm navigate={navigate} show={id === undefined || isEditing} id={i} updateImage={updateImage} updateCheck={updateCheck} deleteCheck={deleteCheck} check={c} drawerValues={drawerValues} />)}
                <span className="btnOperations" onClick={addNewCheck}>
                  + Agregar un nuevo cheque
                </span>
              </>}
            </>}

            <div className="actBtn">
              {!operation['_id'] || isEditing ? (
                <ActionButton
                  onClick={handleSubmit}
                  text={isLoading ? 'Cargando...' : operation['_id'] ? 'Editar' : 'Crear'}
                  alt={operation['_id'] ? 'Edit button' : 'Submit button'}
                  disabled={!!operation['_id'] && !isEditing}
                />
              ) : null}
            </div>
            {error && (
              <p className="errorP" style={{ color: 'red' }}>
                {error}
              </p>
            )}
            {success && !error && afterEdit && (
              <p className="succsesP" style={{ color: 'green' }}>
                Operación realizada con éxito.
              </p>
            )}
          </div>
        </div>
      </div>
    );
};

export default Operation;

const CheckForm = (props: any) => {

  const { id, show, updateImage, deleteCheck, updateCheck, check, drawerValues } = props;

  const [frontFile, setFrontFile] = useState<File | null>(null);
  const [newBackFile, setNewBackFile] = useState<File | null>(null);

  const [friendlyFrontFile, setFriendlyFrontFile] = useState<string | undefined>(undefined);
  const [friendlyNewBackFile, setFriendlyNewBackFile] = useState<string | undefined>(undefined);

  const handleChange = (e: any) => {
    updateCheck(e, id);
  };

  useEffect(() => {
    setFriendlyNewBackFile(newBackFile?.name);
  }, [newBackFile]);

  useEffect(() => {
    setFriendlyFrontFile(frontFile?.name);
  }, [frontFile]);

  useEffect(() => {
    updateImage('file', frontFile, friendlyFrontFile, id);
  }, [friendlyFrontFile]);

  useEffect(() => {
    updateImage('backfile', newBackFile, friendlyNewBackFile, id);
  }, [friendlyNewBackFile]);

  return (
    <div>
      <div className="topArea">
        {check._id ? <h4>{check._id.slice(-6).toUpperCase()}</h4> : <h4>Cheque {id + 1}</h4>}
        {show && <span onClick={() => deleteCheck(id)} className='eliminar'>Borrar cheque</span>}
      </div >

      <div className="dropArea">
        <SearchDropdown
          label="Liberador:"
          defaultValue=""
          options={drawerValues}
          onSelectOption={(e) => {
            updateCheck({ target: { name: 'drawerId', value: e } }, id);
          }}
          disabled={!show}
        />

        <FormInput
          name="internalUseMessage"
          value={check.internalUseMessage}
          label={`Campo para uso interno`}
          type="text"
          onChange={handleChange}
          disabled={!show}
        />

        <FormInput name="amount" value={check.amount} label="Monto" type="number" onChange={handleChange} disabled={!show} />

        <Dropdown
          options={[
            { value: 'peso', label: 'UYU' },
            { value: 'dolar', label: 'USD' },
          ]}
          name={'currency'}
          value={check.currency}
          label={'Moneda'}
          onChange={handleChange}
          disabled={!show}
        />
      </div>

      <div style={{ display: 'flex', width: '100%' }}>
        {show && (
          <div>
            <h2>Frente y dorso de cheque</h2>
            <div className="checksImage">
              <div className="checksImage__wrapper">
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                  <div>
                    <label>Foto Frontal del Cheque:</label>
                    <input type="file" accept="image/*" onChange={(e) => e.target.files && setFrontFile(e.target.files[0])} />
                    {frontFile && (
                      <div>
                        <span style={{ fontSize: '12px' }}>Alias:</span>
                        <input
                          onChange={(e) => {
                            setFriendlyFrontFile(e.target.value);
                          }}
                          name="friendly_frontFile"
                          value={friendlyFrontFile}
                          style={{ minHeight: '30px', fontSize: '12px' }}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <label>Foto Trasera del Cheque:</label>
                    <input type="file" accept="image/*" onChange={(e) => e.target.files && setNewBackFile(e.target.files[0])} />
                    {newBackFile && (
                      <div>
                        <span style={{ fontSize: '12px' }}>Alias:</span>
                        <input
                          onChange={(e) => {
                            setFriendlyNewBackFile(e.target.value);
                          }}
                          name="friendly_backFile"
                          value={friendlyNewBackFile}
                          style={{ minHeight: '30px', fontSize: '12px' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr></hr>
    </div >
  );
};

const CessionForm = (props: any) => {

  const { id, show, cession, updateCession, deleteCession } = props;

  const handleChange = (e: any) => {
    updateCession(e, id);
  };

  return (
    <>
      <div className="topArea">
        <h4>Cesión {id + 1}</h4>
        {show && <span onClick={() => deleteCession(id)} className='eliminar'>Borrar cesión</span>}
      </div >
      <FormInput
        name="observations"
        value={cession.observations ?? ''}
        label={`Observaciones`}
        type="text"
        onChange={handleChange}
        disabled={!show}
      />
      <hr></hr>
    </>
  );
};

const LoanForm = (props: any) => {

  const { id, show, loan, updateLoan, deleteLoan } = props;

  const handleChange = (e: any) => {
    updateLoan(e, id);
  };

  return (
    <>
      <div className="topArea">
        <h4>Préstamo {id + 1}</h4>
        {show && <span onClick={() => deleteLoan(id)} className='eliminar'>Borrar préstamo</span>}
      </div >

      <FormInput name="observations" value={loan.observations ?? ''} label={`Observaciones`} type="text" onChange={handleChange} disabled={!show} />
      <hr></hr>
    </>
  );
};
