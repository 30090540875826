import React, { ReactElement } from 'react';
import ActionButton from '../ActionButton';

export type InformationModalType = {
  onClickAccept: () => void;
  isOpen: boolean;
  contentLabel: string;
  title: string;
};

const InformationModal = (props: InformationModalType) => {
  const { onClickAccept, isOpen, contentLabel, title } = props;
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-overlay-wrapper">
            <div className="modal">
              <h2>{title}</h2>
              <div className="modal-content">{contentLabel}</div>
              <div className="modal-buttons">
                <ActionButton onClick={() => onClickAccept()} text="Aceptar" alt="Accept Button" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InformationModal;
