import React from 'react';
const IconCheques = () => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g data-name="36. Bank Check" id="_36._Bank_Check">
        <path d="M31,8H28V3a3,3,0,0,0-6,0V8H3a3,3,0,0,0-3,3V29a3,3,0,0,0,3,3H23a1,1,0,0,0,1-1V28h7a1,1,0,0,0,1-1V9A1,1,0,0,0,31,8ZM24,6h2v8.59l-1,1-1-1Zm1-4a1,1,0,0,1,1,1V4H24V3A1,1,0,0,1,25,2ZM22,30H3a1,1,0,0,1,0-2H22Zm8-4H3a3,3,0,0,0-1,.18V11a1,1,0,0,1,1-1H22v5a1,1,0,0,0,.29.71l2,2a1,1,0,0,0,1.42,0l2-2A1,1,0,0,0,28,15V10h2Z" />
        <path d="M5,14h8a1,1,0,0,0,0-2H5a1,1,0,0,0,0,2Z" />
        <path d="M18,17a1,1,0,0,0-1-1H5a1,1,0,0,0,0,2H17A1,1,0,0,0,18,17Z" />
        <path d="M27,22H25.54l-1.71-2.55a1,1,0,0,0-1.66,0L20.46,22H18a1,1,0,0,0,0,2h3a1,1,0,0,0,.83-.45L23,21.8l1.17,1.75A1,1,0,0,0,25,24h2a1,1,0,0,0,0-2Z" />
      </g>
    </svg>
  );
};
export default IconCheques;
