import React, { useId } from 'react';

export type DropdownProps = {
  name: string;
  value: any;
  label: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  options: { value: string | number; label: string }[];
};

const Dropdown = (props: DropdownProps) => {
  const { label, value, options, onChange, name, disabled } = props;

  const isEmptyValue = value === '' || value === null;

  return (
    <div>
      <label>{label}</label>
      <select value={value} onChange={onChange} name={name} disabled={disabled}>
        {isEmptyValue && (
          <option value="" selected>
            -- Seleccionar --
          </option>
        )}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
