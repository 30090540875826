import React, { useEffect, useState } from 'react';

import { Document, Page } from 'react-pdf';
import PuffLoader from 'react-spinners/PuffLoader';
import { Check as CheckType, DocumentsType } from '../../../types/check';
import ActionButton from '../../components/ActionButton';
import axios from 'axios';
import { emptyCheck } from './utils';

type CheckImagesType = {
  check: CheckType;
  setSelectedImage: (file: string | null) => void;
  isEditing: boolean;
  frontFile: File | null;
  newBackFile: File | null;
  setFrontFile: (file: File) => void;
  setNewBackFile: (file: File) => void;
  setDocuments: (documents: [File, string][]) => void;
  documents: [File, string][];
  selectedImage: string | null;
  setDeletedOldDocuments: (document: string) => void;
  oldDocuments: DocumentsType[];
};

const CheckImages = ({
  check,
  setSelectedImage,
  isEditing,
  setFrontFile,
  setNewBackFile,
  setDocuments,
  documents,
  selectedImage,
  setDeletedOldDocuments,
  oldDocuments,
  frontFile,
  newBackFile
}: CheckImagesType) => {
  const [docs, setDocs] = useState<any[]>([]);
  const [file, setFile] = useState('');
  const [backfile, setBackFile] = useState('');
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [newDocument, setNewDocument] = useState<File | null>();
  const [newDocumentFriendly, setNewDocumentFriendly] = useState('');

  useEffect(() => {
    if (check['_id']) {
      let bucketname = '';
      const issuedate = new Date(check['createdDate'] ?? '');
      const bucketdate = new Date('2024-03-14T00:00:00.000+00:00');

      if (bucketdate > issuedate) bucketname = 'kona-fs-descuenta-cheque';
      else bucketname = 'descontalo';

      const getURL = async () => {
        if (check.file) {
          const fileUrl = (
            await axios.get(
              `https://descontalo-back-fef14f23ad5d.herokuapp.com/api/generate-presigned-url?key=${encodeURIComponent((check.file as string) || '')}`,
              {
                headers: { bucketName: bucketname },
              },
            )
          ).data;
          setFile(fileUrl);
        }
        if (check.backfile) {
          const backUrl = (
            await axios.get(
              `https://descontalo-back-fef14f23ad5d.herokuapp.com/api/generate-presigned-url?key=${encodeURIComponent((check.backfile as string) || '')}`,
              {
                headers: { bucketName: bucketname },
              },
            )
          ).data;
          setBackFile(backUrl);
        }
        const documents: DocumentsType[] = [];
        for (const d of check.documents || []) {
          const fileId = d.url.replace('bucket/s3/', '');
          const endpoint = `https://descontalo-back-fef14f23ad5d.herokuapp.com/api/generate-presigned-url?key=${encodeURIComponent(fileId)}`;
          const newUrl = (
            await axios.get(endpoint, {
              headers: { bucketName: bucketname },
            })
          ).data;
          documents.push({ name: d.name, url: newUrl, friendlyName: d.friendlyName });
        }
        setDocs(documents);
        setLoadingDocuments(false);
      };
      if (check != emptyCheck) {
        getURL();
      }
    }
  }, [check.documents, check.file, check.backfile]);

  const deleteDocument = (document: File) => {
    const newDocuments = documents.filter((toDelete: [File, string]) => document !== toDelete[0]);
    setDocuments(newDocuments);
  };

  const handleAddDocument = () => {
    const documentToAdd: [File, string] = [newDocument as File, newDocumentFriendly];
    const newDocuments = documents;
    newDocuments.push(documentToAdd);
    setDocuments(newDocuments);
    setNewDocument(null);
  };

  useEffect(() => {
    if (newDocument) {
      setNewDocumentFriendly(newDocument.name);
    }
  }, [newDocument])

  return (
    <>
      <div className="formRegister form">
        <div className="formRegister__wrapper">
          <h1>Documentos</h1>
          {loadingDocuments && check._id ? (
            <PuffLoader color="#3D9FE0" />
          ) : (
            <div>
              <h2>Frente y dorso de cheque</h2>
              <div className="checksImage">
                <div className="checksImage__wrapper">
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 20, margin: '20px 0px 20px 0px' }}>
                    {check.file && !isEditing ? (
                      // <div onClick={() => setSelectedImage(file)}>
                      <a href={file} target="_blank" rel="noreferrer">
                        <img src={file} className="previewImage" />
                      </a>
                    ) : // </div>
                      !(!isEditing && !!check['_id']) ? (
                        <div>
                          <label>Foto Frontal del Cheque:</label>
                          <input type="file" accept="image/*" onChange={(e) => e.target.files && setFrontFile(e.target.files[0])} />
                          {check.file && check.file != '' && !frontFile &&
                            <a href={file} target="_blank" rel="noreferrer">
                              <img src={file} className="previewImage" />
                              <div>{JSON.stringify(check.file)}-{JSON.stringify(frontFile)}</div>
                            </a>}
                        </div>
                      ) : null}
                    {check.backfile && !isEditing ? (
                      // <div onClick={() => setSelectedImage(backfile)}>
                      <a href={backfile} target="_blank" rel="noreferrer">
                        <img src={backfile} className="previewImage" />
                      </a>
                    ) : // </div>
                      !(!isEditing && !!check['_id']) ? (
                        <div>
                          <label>Foto Trasera del Cheque:</label>
                          <input type="file" accept="image/*" onChange={(e) => e.target.files && setNewBackFile(e.target.files[0])} />
                          {check.backfile && check.backfile != '' && !newBackFile &&
                            <a href={backfile} target="_blank" rel="noreferrer">
                              <img src={backfile} className="previewImage" />
                            </a>}
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>

              <div className="checksImage">
                <div className="checksImage__wrapper">
                  <h2>Otros documentos</h2>

                  {!isEditing &&
                    <div>
                      {docs.map((document) => (
                        <div key={document.name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                          <a target="_blank" style={{ margin: '5px', color: 'blue', textDecoration: 'underline' }} href={document.url}>{document.friendlyName ?? document.name}</a>
                        </div>
                      ))}
                    </div>
                  }

                  {!(!isEditing && !!check['_id']) && (
                    <div>
                      <label>Agregar Archivo:</label>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <input type="file" onChange={(e) => e.target.files && setNewDocument(e.target.files[0])} />
                        {newDocument &&
                          <input
                            type="text"
                            value={newDocumentFriendly}
                            onChange={(e) =>
                              setNewDocumentFriendly(e.target.value)
                            }
                          />
                        }
                        <button
                          style={{
                            backgroundColor: '#3D9FE0',
                            color: 'white',
                            borderRadius: '5px',
                            padding: '5px 15px 5px 15px',
                            marginBottom: '20px',
                          }}
                          disabled={!newDocument}
                          onClick={handleAddDocument}
                        >
                          +
                        </button>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <label> Archivos:</label>
                        {documents.map((document) => (
                          <div key={document[0].name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <b>{document[1]}</b>
                            <button
                              style={{
                                backgroundColor: '#3D9FE0',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 15px 5px 15px',
                              }}
                              onClick={() => deleteDocument(document[0])}
                            >
                              Eliminar
                            </button>
                          </div>
                        ))}
                        {oldDocuments.map((document) => (
                          <div key={document.name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <b>{document.friendlyName ?? document.name}</b>
                            <button
                              style={{
                                backgroundColor: '#3D9FE0',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 15px 5px 15px',
                              }}
                              onClick={() => setDeletedOldDocuments(document.url)}
                            >
                              Eliminar
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* {!isEditing && (
                <div className="images">
                  {docs.map((doc: any, id: number) => {
                    const url = doc.url.toLowerCase();
                    if (doc.name.includes('pdf') || url.includes('pdf')) {
                      return (
                        <a key={id} target="_blank" href={doc.url || ''} rel="noreferrer" className="Example__container__document">
                          <Document file={doc.url} className={'documentClass'} error={`Abrir el archivo ${doc.name}`}>
                            <Page pageNumber={1} width={200} renderTextLayer={false} renderAnnotationLayer={false} />
                            <div>{doc.name}</div>
                          </Document>
                        </a>
                      );
                    } else if (
                      url.toLocaleLowerCase().includes('.jpg') ||
                      url.toLocaleLowerCase().includes('.jpeg') ||
                      url.toLocaleLowerCase().includes('.png') ||
                      url.toLocaleLowerCase().includes('.gif') ||
                      url.toLocaleLowerCase().includes('.svg') ||
                      url.toLocaleLowerCase().includes('.webp') ||
                      url.toLocaleLowerCase().includes('.bmp') ||
                      url.toLocaleLowerCase().includes('.tiff')
                    ) {
                      return (
                        <a key={doc.url} target="_blank" href={doc.url || ''} rel="noreferrer" className="noImage">
                          <img src={doc.url} className="previewImage" />
                        </a>
                      );
                    } else {
                      return (
                        <a key={doc.url} target="_blank" href={doc.url || ''} rel="noreferrer" className="noImage">
                          {doc.name || ''}
                        </a>
                      );
                    }
                  })}
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
      {selectedImage ? (
        <div className="imageViewer" onClick={() => setSelectedImage(null)}>
          <img className="modalContent" src={selectedImage} />
          <div className="openFileContainer">
            <ActionButton
              onClick={() => window.open(selectedImage, '_blank')}
              text={'Abrir archivo en nueva ventana'}
              alt={'Abrir archivo'}
            ></ActionButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CheckImages;
