import { Check } from '../../../types/check';

export const TableHeaders = {
  ID: 'ID',
  AMOUNT: 'Monto',
  STATE: 'Estado',
  CLIENT: 'Cliente',
  DRAWER: 'Librador',
  DUE_DATE: 'Fecha de vencimiento',
};

export const TableHeaderToCheckProperty: Record<string, keyof Check> = {
  ID: '_id',
  AMOUNT: 'amount',
  STATE: 'state',
  CLIENT: 'client',
  DRAWER: 'drawer'
};
