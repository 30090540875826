import React from 'react';
import IconReturn from '../../../assets/iconReturn';

const BackButton = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <button className="backBtn" onClick={handleGoBack}>
      <IconReturn></IconReturn>
    </button>
  );
};

export default BackButton;
