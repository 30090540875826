import React, { useEffect, useState } from 'react';
import { login, LoginCredentials } from '../../../services/login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../services/store';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../components/ActionButton';
import FormInput from '../../components/FormInput';
import Logo from '../../../assets/img/logo-descontalo.png';
import IconHome from '../../../assets/iconHome';
import { CLIENTS_ROUTE } from '../../../utils/routes';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.login.isLoading);
  const error = useSelector((state: RootState) => state.login.error);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const handleLogin = () => {
    const credentials: LoginCredentials = {
      email: email,
      password: password,
    };
    dispatch(login(credentials));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (token) {
      navigate(CLIENTS_ROUTE, { replace: true });
    }
  }, [token]);

  return (
    <div className="login">
      <div className="loginArea">
        <img src={Logo} alt="" />
        <h1>Login</h1>
        <FormInput placeholder="" label="Email" name="email" value={email} onChange={handleEmailChange} />
        <FormInput type="password" placeholder="" label="Password" name="password" value={password} onChange={handlePasswordChange} />
        <ActionButton onClick={handleLogin} text={isLoading ? 'Loading...' : 'Submit'} alt="Submit button" />
        {error && (
          <p className="errorP" style={{ color: 'red' }}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
