import { Cession } from '../../../types/cession';

export const TableHeaders = {
  ID: 'ID',
  CLIENT: 'Cliente',
  ENTITY: 'Ente',
  STATE: 'Estado',
  EXPIRATION: 'Vencimiento Acordado',
  AMOUNT: 'Monto',
};

export const TableHeaderToCessionProperty: Record<string, keyof Cession> = {
  ID: '_id',
  CLIENT: 'client',
  ENTITY: 'entity',
  STATE: 'state',
  EXPIRATION: 'proposedExpiration',
  AMOUNT: 'advanceAmount',
};
