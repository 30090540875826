import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { User } from '../../../types/user';

export const formatDataToTable = (users: User[]): DataTableType[] => {
  return users.reduce((accumulator: DataTableType[], user: User) => {
    const value: DataTableType = {
      [TableHeaders.ID]: user._id,
      [TableHeaders.EMAIL]: <div>{user.email}</div>,
      [TableHeaders.NAME]: <div>{user.name}</div>,
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    name: 'Nombre',
    email: 'Email',
    creationDate: 'Fecha de Creación',
  };
  return labels[key] || key;
};
