export const HOME_ROUTE = '';
export const LOGIN_ROUTE = '/login';
export const USERS_ROUTE = '/usuarios';
export const NEW_USER_ROUTE = '/usuarios/nuevo';
export const EDIT_USER_ROUTE = '/usuarios/:id';
export const CLIENTS_ROUTE = '/clientes';
export const NEW_CLIENT_ROUTE = '/clientes/nuevo';
export const EDIT_CLIENT_ROUTE = '/clientes/:id';
export const OPERATIONS_ROUTE = '/operaciones';
export const NEW_OPERATION_ROUTE = '/operaciones/nuevo';
export const EDIT_OPERATION_ROUTE = '/operaciones/:id';
export const CHECKS_ROUTE = '/cheques';
export const NEW_CHECK_ROUTE = '/cheques/nuevo';
export const EDIT_CHECK_ROUTE = '/cheques/:id';
export const DRAWERS_ROUTE = '/libradores';
export const NEW_DRAWER_ROUTE = '/libradores/nuevo';
export const EDIT_DRAWER_ROUTE = 'libradores/:id';
export const CESSIONS_ROUTE = '/cesiones';
export const NEW_CESSIONS_ROUTE = '/cesiones/nuevo';
export const EDIT_CESSIONS_ROUTE = 'cesiones/:id';
export const LOANS_ROUTE = '/prestamos';
export const NEW_LOANS_ROUTE = '/prestamos/nuevo';
export const EDIT_LOANS_ROUTE = 'prestamos/:id';
export const APP_VERSION = '2.0.1';
export const VERSIONS_ROUTE = '/versions';
