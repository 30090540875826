/* eslint-disable prettier/prettier */
import AWS from 'aws-sdk';

export const uploadFile = async (file: File, fileName?: string): Promise<string> => {
    const S3_BUCKET = 'descontalo';
    const REGION = 'sa-east-1';

    AWS.config.update({
        accessKeyId: 'AKIAQ3EGPDK2BHEVQSOQ',
        secretAccessKey: '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',
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const key = fileName ? fileName : 'no-name';

    interface S3Params {
        Bucket: string;
        Key: string;
        Body: File;
        [key: string]: any; // Allow additional properties
    }

    const params: S3Params = {
        Bucket: S3_BUCKET,
        Key: key,
        Body: file,
        ContentDisposition: "inline",
    };

    if( key.toLocaleLowerCase().includes('.jpg') ||
    key.toLocaleLowerCase().includes('.jpeg') ||
    key.toLocaleLowerCase().includes('.png') ||
    key.toLocaleLowerCase().includes('.gif') ||
    key.toLocaleLowerCase().includes('.svg') ||
    key.toLocaleLowerCase().includes('.webp') ||
    key.toLocaleLowerCase().includes('.bmp') ||
    key.toLocaleLowerCase().includes('.tiff')){
        params.ContentType = "image";
    }else if(key.includes('.pdf')){
        params.ContentType = "application/pdf";
    }

    const upload = s3
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
            console.log('Uploading ');
        })
        .promise();

    return await upload.then((response) => {
        if (response.$response.httpResponse.statusCode === 200) {
            return "OK";
        }
        return "OK";
    }).catch(() => "ERROR");
};
