import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { Operation } from '../../../types/operation';

export const formatDataToTable = (operations: Operation[]): DataTableType[] => {
  return operations.reduce((accumulator: DataTableType[], operation: Operation) => {

    const value: DataTableType = {
      [TableHeaders.ID]: operation._id,
      [TableHeaders.CLIENT]: <div>{operation.client?.name || '-'}</div>,
      [TableHeaders.OPERATIONTYPE]: <div>{operation.operationType || '-'}</div>,
      // [TableHeaders.COMPANY]: <div>{operation.client?.legalName || '-'}</div>,
      // [TableHeaders.AMOUNT_OF_CHECKS]: <div>{checksAmount}</div>,
      // [TableHeaders.AMOUNT_OF_CESSIONS]: <div>{cessionsAmount}</div>,
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    clientId: 'ID Cliente',
    cessionId: 'IDs Cesiones',
    checkId: 'IDs Cheques',
    loanId: 'IDs Prestamos',
    totalCheckAmount: 'Monto Total de Cheques',
    amountToIssue: 'Monto a Girar',
    vatInFavor: 'IVA a Favor',
    incomeTaxInFavor: 'Renta a Favor',
    totalToReceive: 'Total a Recibir',
    interestAndCommissions: 'Intereses y Comisiones',
    numberOfChecks: 'Cantidad de Cheques',
    averageTermOfChecks: 'Plazo Promedio de Cheques',
    percentageOfInvoicing: '% de Facturacion',
    creationDate: 'Fecha de Creación',
  };
  return labels[key] || key;
};
