import React, { useState, useEffect, useId } from 'react';
import { pdfjs } from 'react-pdf';
import PuffLoader from 'react-spinners/PuffLoader';
import { Check as CheckType, DocumentsType } from '../../../types/check';
import { Drawer as DrawerType } from '../../../types/drawer';
import { Client as ClientType } from '../../../types/client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../../components/ConfirmationModal';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import { clearState, createCheck, deleteCheck, fetchChecks, updateCheck } from '../../../services/checks/checkSlice';
import { CHECKS_ROUTE } from '../../../utils/routes';
import DatePicker from '../../components/DatePicker';
import { fetchClients } from '../../../services/clients/clientSlice';
import { clearState as clearStateDrawer, createDrawer, fetchDrawers } from '../../../services/drawers/drawerSlice';
import { Client } from '../../../types/client';
import { Drawer, emptyDrawer } from '../../../types/drawer';
import Dropdown, { DropdownProps } from '../../components/Dropdown';
import DrawerForm from '../Drawer/drawerForm';
import { emptyCheck } from './utils';
import './styles.css';
import { uploadFile } from './uploadFile';
import CheckImages from './images';
import { SearchDropdown, SearchDropdownProps } from '../../components/SearchDropdown';
import { dropdownStateOptions } from './dropdownOptions';
import InformationModal from '../../components/InformationModal';
import { banks } from '../Client/consts';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const Check = () => {
  const token = localStorage.getItem('token');

  const { id } = useParams();

  const isLoading = useSelector((state: RootState) => state.check.isLoading);
  const error = useSelector((state: RootState) => state.check.error);
  const success = useSelector((state: RootState) => state.check.success);
  const checks = useSelector((state: RootState) => state.check.checks);
  const clients = useSelector((state: RootState) => state.client.clients);
  const drawers = useSelector((state: RootState) => state.drawer.drawers);
  const [check, setCheck] = useState<CheckType>(emptyCheck);
  const [isFetching, setIsFetching] = useState(false);
  const [oldDocuments, setOldDocuments] = useState<DocumentsType[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (clients.length <= 0) dispatch(fetchClients(token || ''));
  }, []);

  useEffect(() => {
    if (drawers.length <= 0) dispatch(fetchDrawers(token || ''));
  }, []);

  useEffect(() => {
    dispatch(clearState());
    dispatch(clearStateDrawer());
    if (id !== undefined) {
      setIsFetching(true);
      const foundCheck = checks.find((check: CheckType) => check._id === id);
      if (!foundCheck) {
        dispatch(fetchChecks(token || ''));
      }
      foundCheck && setCheck(foundCheck);
      foundCheck && setOldDocuments(foundCheck.documents ? foundCheck.documents : []);
    }
  }, []);

  useEffect(() => {
    const foundCheck = checks.find((check: CheckType) => check._id === id);
    foundCheck && setCheck(foundCheck);
    foundCheck && setOldDocuments(foundCheck.documents ? foundCheck.documents : []);
    setIsFetching(false);
  }, [checks]);

  return (
    <CheckContent
      check={check}
      setCheck={setCheck}
      isLoading={isFetching || isLoading}
      error={error}
      success={success}
      checks={checks}
      clients={clients}
      drawers={drawers}
      oldDocuments={oldDocuments}
      setOldDocuments={setOldDocuments}
      setIsFetching={setIsFetching}
    />
  );
};

type CheckContentType = {
  check: CheckType;
  setCheck: React.Dispatch<React.SetStateAction<CheckType>>;
  isLoading: boolean;
  error: string | null;
  success: boolean;
  checks: CheckType[];
  clients: ClientType[];
  drawers: DrawerType[];
  oldDocuments: DocumentsType[];
  setOldDocuments: React.Dispatch<React.SetStateAction<DocumentsType[]>>;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
};

const CheckContent = (props: CheckContentType) => {
  const token = localStorage.getItem('token');

  const { check, setCheck, isLoading, error, success, checks, clients, drawers, oldDocuments, setOldDocuments, setIsFetching } = props;

  const [newDrawer, setNewDrawer] = useState<Drawer>(emptyDrawer);
  const drawerError = useSelector((state: RootState) => state.drawer.error);
  const drawerSuccess = useSelector((state: RootState) => state.drawer.success);

  const [frontFile, setFrontFile] = useState<File | null>(null);
  const [newBackFile, setNewBackFile] = useState<File | null>(null);
  const [documents, setDocuments] = useState<[File, string][]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [drawerFormOpen, setDrawerFormOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);

  const dropdownBankOptions = banks.map((bank) => ({
    value: bank.name,
    label: bank.name,
  }));

  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clients.length <= 0 || drawers.length <= 0) return;
    if (id !== undefined) {
      const foundCheck = checks.find((check: CheckType) => check._id === id);
      foundCheck && handleDropdownValues(foundCheck);
    }
  }, [clients, drawers]);

  useEffect(() => {
    if (success && !isEditing) {
      navigate(CHECKS_ROUTE);
    }
  }, [success]);

  useEffect(() => {
    if (drawerSuccess) {
      handleDrawerSelect(drawers[0]);
      setDrawerFormOpen(false);
    }
  }, [drawerSuccess]);

  const handleDropdownValues = (foundCheck: CheckType) => {
    const selectedClient = clients.find((client) => client._id === foundCheck['clientId']);
    if (selectedClient) {
      handleClientSelect(selectedClient);
    }
    const selectedDrawer = drawers.find((drawer) => drawer._id === foundCheck['drawerId']);
    if (selectedDrawer) {
      handleDrawerSelect(selectedDrawer);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCheck((previousCheck) => ({
      ...previousCheck,
      [name]: value,
    }));
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteCheck({ id: id, token: token || '' }));
      navigate(CHECKS_ROUTE);
    }
  };

  const handleClientSelect = (client: Client | null) => {
    setCheck((previousCheck) => ({
      ...previousCheck,
      client: client || undefined,
      clientId: client?._id || '',
    }));
  };

  const handleDrawerSelect = (drawer: Drawer | null) => {
    setCheck((previousCheck) => ({
      ...previousCheck,
      drawer: drawer || undefined,
      drawerId: drawer?._id || '',
    }));
  };

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: check['_id'] !== undefined,
    deleteButton: check['_id'] !== undefined,
  };

  const pictureId = useId();

  const handleSubmit = async () => {
    if (check.phone == undefined && !check.backfile && !check.file && !newBackFile && !frontFile) {
      setInfoModalOpen(true);
      window.scrollTo(0, 0);
      return;
    }

    setIsFetching(true);
    let frontFileName, backFileName;
    const newDocuments: DocumentsType[] = [];

    if (frontFile) {
      frontFileName = `${pictureId}${frontFile.name}`;
      await uploadFile(frontFile, frontFileName).then((response) => {
        if (response !== 'OK') {
          alert('Error subiendo las imagenes');
          setIsFetching(false);
          return;
        }
      });
    }
    if (newBackFile) {
      backFileName = `${pictureId}${newBackFile.name}`;
      await uploadFile(newBackFile, backFileName).then((response) => {
        if (response !== 'OK') {
          alert('Error subiendo las imagenes');
          setIsFetching(false);
          return;
        }
      });
    }

    if (documents) {
      documents.forEach(async (document) => {
        const documentName = `${document[0].name}`;
        const documentURL = `bucket/s3/${documentName}`;
        const friendlyName = document[1];
        newDocuments.push({ name: documentName, url: documentURL, friendlyName });
        await uploadFile(document[0], documentName).then((response) => {
          if (response !== 'OK') {
            alert('Error subiendo las imagenes');
            setIsFetching(false);
            return;
          }
        });
      });
    }

    setDocuments([]);

    const documentsToSave: DocumentsType[] = [...oldDocuments, ...newDocuments];

    const checkWithFiles = {
      ...check,
      file: frontFile ? frontFileName : undefined,
      backfile: newBackFile ? backFileName : undefined,
      documents: documentsToSave,
    };
    setCheck(checkWithFiles);
    if (check['_id']) {
      dispatch(updateCheck({ id: id, checkData: checkWithFiles, token: token || '' }));
    } else {
      dispatch(createCheck({ checkData: checkWithFiles, token: token }));
    }
    setFrontFile(null);
    setNewBackFile(null);
    setIsFetching(false);
  };

  const onSelectOptionClient = (id: string) => {
    if (clients) {
      const selectedClient = clients.find((client) => client._id === id);
      if (selectedClient) {
        handleClientSelect(selectedClient);
      }
    }
  };

  const onSelectOptionDrawer = (id: string) => {
    if (drawers) {
      const selectedDrawer = drawers.find((drawer) => drawer._id === id);
      if (selectedDrawer) {
        handleDrawerSelect(selectedDrawer);
      }
    }
  };

  const clientSearchDropdownProps: SearchDropdownProps = {
    label: 'Cliente:',
    defaultValue: check['client'] ? (check['client']._id ? check['client']._id : check['client'].email) : '',
    disabled: (id !== undefined && !isEditing) || check.operationId !== undefined,
    onSelectOption: onSelectOptionClient,
    onDelete: () => handleClientSelect(null),
    options: clients.map((client) => ({
      value: client._id ? client._id : client.email,
      label: client.name + ' - ' + client.email + ' - ' + `${client.legalName || ''}`,
    })),
  };

  const drawerSearchDropdownProps: SearchDropdownProps = {
    label: 'Librador:',
    defaultValue: check['drawer'] ? (check['drawer']._id ? check['drawer']._id : check['drawer'].name) : '',
    disabled: id !== undefined && !isEditing,
    onSelectOption: onSelectOptionDrawer,
    onDelete: () => handleDrawerSelect(null),
    options: drawers.map((drawer) => ({
      value: drawer._id ? drawer._id : drawer.name,
      label: drawer.name,
    })),
  };

  const handleDrawerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewDrawer((previousDrawer) => ({
      ...previousDrawer,
      [name]: value,
    }));
  };

  const handleDrawerSubmit = () => {
    dispatch(createDrawer({ drawerData: newDrawer, token: token }));
  };

  const handleDeleteOldDocument = (document: string) => {
    const old = oldDocuments.filter((doc) => doc.url !== document);
    setOldDocuments(old);
  };

  interface Check {
    name?: string;
    businessName?: string;
    rut?: string;
    tradename?: string;
    rejectedReason?: string;
    clientRejectedReason?: string;
  }
  const isEmpty = (value?: string) => !value || value.trim() === '';
  const shouldShowDetails = (check: Check): boolean => {
    return !(
      isEmpty(check.name) &&
      isEmpty(check.businessName) &&
      isEmpty(check.rut) &&
      isEmpty(check.tradename) &&
      isEmpty(check.rejectedReason) &&
      isEmpty(check.clientRejectedReason)
    );
  };

  return isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="clientManagement check">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar el cheque ${check?._id}?`}
        />
        <InformationModal
          isOpen={infoModalOpen}
          onClickAccept={() => {
            setInfoModalOpen(false);
          }}
          contentLabel={'Debe seleccionar un cliente, ingresar un teléfono y adjuntar las fotos del cheque.'}
          title={'Datos obligatorios'}
        />
        <BackButton />
        <TopContent
          title={!check['_id'] ? 'Registrar Cheque' : isEditing ? 'Editar Cheque' : check['_id'].toString().slice(-6).toString()}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={() => setIsEditing((prevState) => !prevState)}
          onClickDelete={() => setDeleteModalOpen(true)}
        />
      </div>
      <div className="formRegister form">
        <div className="formRegister__wrapper">
          <div className="dropTop">
            <div className="dropCliente">
              <SearchDropdown {...clientSearchDropdownProps} />
              {id !== undefined && check['clientId'] && (
                <button className="btnDark verCliente" type="button" onClick={() => navigate(`/clientes/${check?.clientId}`)}>
                  Ver Cliente
                </button>
              )}
            </div>

            <div className="dropLibrador">
              <div>
                <SearchDropdown {...drawerSearchDropdownProps} />
                {!!drawerFormOpen && (
                  <div className="drawer-modal-overlay">
                    <div className="drawer-modal-overlay-wrapper">
                      <div className="drawer-modal">
                        <div>
                          <h2>Crear librador</h2>
                        </div>
                        <DrawerForm
                          drawer={newDrawer}
                          handleChange={handleDrawerChange}
                          isEditing={false}
                          handleSubmit={handleDrawerSubmit}
                          isLoading={isLoading}
                          error={drawerError}
                          success={drawerSuccess}
                        />
                        <ActionButton onClick={() => setDrawerFormOpen(false)} text={'Cerrar'} alt={'Cerrar'} className="close-button" />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {check['_id'] && check['drawerId'] && (
                <button className="btnDark verCliente liberador" type="button" onClick={() => navigate(`/libradores/${check?.drawerId}`)}>
                  Ver Librador
                </button>
              )}
              {!drawerFormOpen && (isEditing || !check['_id']) && (
                <div className="btnLiberador">
                  <button className="actionButton nuevoLibrador" onClick={() => setDrawerFormOpen(true)}>
                    Agregar nuevo Librador
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="formRegister__inputArea">
            {check['_id'] && <FormInput name="id" value={check._id ? check._id : ''} label="ID" type="text" onChange={() => null} disabled={true} />}
            <Dropdown
              options={[
                { value: 'peso', label: 'UYU' },
                { value: 'dolar', label: 'USD' },
              ]}
              name={'currency'}
              value={check['currency'] ? check['currency']?.toString() : ''}
              label={'Moneda'}
              onChange={handleChange}
              disabled={!isEditing || !check['_id']}
            />
            <FormInput
              name="amount"
              value={check['amount'] ? check['amount']?.toString() : ''}
              label="Monto"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <DatePicker
              name={'dueDate'}
              value={check['dueDate'] ? check['dueDate']?.toString() : ''}
              label={'Vencimiento'}
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <DatePicker
              name={'issueDate'}
              value={check['issueDate'] ? check['issueDate']?.toString() : ''}
              label={'Fecha de Emisión'}
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <Dropdown
              options={dropdownStateOptions}
              name={'state'}
              value={check['state'] ? check['state']?.toString() : ''}
              label={'Estado'}
              onChange={handleChange}
              disabled={!isEditing || !check['_id']}
            />
            <FormInput
              name="channeledBy"
              value={check['channeledBy'] ? check['channeledBy']?.toString() : ''}
              label="Canalizado por"
              type="text"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="rate"
              value={check['rate'] ? check['rate']?.toString() : ''}
              label="Tasa"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="interest"
              value={check['interest'] ? check['interest']?.toString() : ''}
              label="Interés"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="commission"
              value={check['commission'] ? check['commission']?.toString() : ''}
              label="Comisión"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="paymentAmount"
              value={check['paymentAmount'] ? check['paymentAmount']?.toString() : ''}
              label="Monto a Pagar"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="globalQuoteAdjustment"
              value={check['globalQuoteAdjustment'] ? check['globalQuoteAdjustment']?.toString() : ''}
              label="Ajuste de Cotización Global"
              type="number"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
            <Dropdown
              name={'bank'}
              value={check['bank'] ? check['bank']?.toString() : ''}
              label={'Banco'}
              options={dropdownBankOptions}
              onChange={(e) => handleChange(e)}
              disabled={!isEditing && !!check['_id']}
            />
            <FormInput
              name="phone"
              value={check['phone'] ? check['phone']?.toString() : ''}
              label="Teléfono"
              type="text"
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
          </div>
          <div className="internalMessage">
            <label htmlFor="internalUseMessage">Campo para uso interno</label>
            <textarea
              name="internalUseMessage"
              value={check['internalUseMessage'] ? check['internalUseMessage']?.toString() : ''}
              onChange={handleChange}
              disabled={!isEditing && !!check['_id']}
            />
          </div>
        </div>
      </div>
      {shouldShowDetails(check) && (
        <div className="formRegister form">
          <div className="formRegister__wrapper">
            <h1>Información sistema anterior (BACKOFFICE)</h1>

            <div className="detailWrapper">
              <div className="backoffice-detail">
                <label>Nombre</label>
                <label>{check['name'] ? check['name']?.toString() : ''}</label>
              </div>
              <div className="backoffice-detail">
                <label>Razón Social</label>
                <label>{check['businessName'] ? check['businessName']?.toString() : ''}</label>
              </div>
              <div className="backoffice-detail">
                <label>RUT</label>
                <label>{check['rut'] ? check['rut']?.toString() : ''}</label>
              </div>
              <div className="backoffice-detail">
                <label>Nombre comercial</label>
                <label>{check['tradename'] ? check['tradename']?.toString() : ''}</label>
              </div>
              <div className="backoffice-detail">
                <label>Mensaje para cliente</label>
                <label>{check['rejectedReason'] ? check['rejectedReason']?.toString() : ''}</label>
              </div>
              <div className="backoffice-detail">
                <label>Mensaje del cliente</label>
                <label>{check['clientRejectedReason'] ? check['clientRejectedReason']?.toString() : ''}</label>
              </div>
            </div>
          </div>
        </div>
      )}
      <CheckImages
        check={check}
        setSelectedImage={(image) => setSelectedImage(image)}
        isEditing={isEditing}
        frontFile={frontFile}
        newBackFile={newBackFile}
        setFrontFile={(file) => setFrontFile(file)}
        setNewBackFile={(file) => setNewBackFile(file)}
        setDocuments={(file) => setDocuments(file)}
        documents={documents}
        selectedImage={selectedImage}
        setDeletedOldDocuments={(document) => handleDeleteOldDocument(document)}
        oldDocuments={oldDocuments}
      />
      <div className="actBtn">
        {!check['_id'] || isEditing ? (
          <ActionButton
            onClick={handleSubmit}
            text={isLoading ? 'Cargando...' : check['_id'] ? 'Editar' : 'Crear'}
            alt={check['_id'] ? 'Edit button' : 'Submit button'}
            disabled={!isEditing && !!check['_id']}
          />
        ) : null}
      </div>
      {error && (
        <p className="errorP" style={{ color: 'red' }}>
          {error}
        </p>
      )}
      {success && !error && (
        <p className="succsesP" style={{ color: 'green' }}>
          Operación realizada con éxito.
        </p>
      )}
    </div>
  );
};

export default Check;
