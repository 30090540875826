import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Cession } from '../../types/cession';
import { toast } from 'react-toastify';

interface CessionState {
  cessions: Cession[];
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: CessionState = {
  cessions: [],
  isLoading: false,
  error: null,
  success: false,
};

export const fetchCessions = createAsyncThunk('cessions', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/cessions`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the cessions.');
  }
});

export const createCession = createAsyncThunk(
  'cessions/create',
  async ({ cessionData, token }: { cessionData: Cession; token: string | undefined | null }) => {
    try {
      const filteredCessionData: Partial<Cession> = Object.fromEntries(
        Object.entries(cessionData).filter(([_, value]) => value !== '' && value !== null),
      );
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/cessions/create`, filteredCessionData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error.message);
        return Promise.reject(error.response.data.error.message);
      }
      toast.error('Hubo un error al crear la cesion');
      return Promise.reject('An error occurred while trying to create the cession.');
    }
  },
);

export const deleteCession = createAsyncThunk('cessions/delete', async ({ id, token }: { id: string | undefined | null; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/cessions/delete?id=${id}`, { headers });
    return id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.error.message);
      return Promise.reject(error.response.data.error.message);
    }
    toast.error('Hubo un error al eliminar la cesion');
    return Promise.reject('An error occurred while trying to delete the cession.');
  }
});

export const updateCession = createAsyncThunk(
  'cessions/update',
  async ({ id, cessionData, token }: { id: string | null | undefined; cessionData: Cession; token: string }) => {
    try {
      const filteredCessionData: Partial<Cession> = Object.fromEntries(
        Object.entries(cessionData).filter(([_, value]) => value !== '' && value !== null),
      );
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/cessions/update?id=${id}`, filteredCessionData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error.message);
        return Promise.reject(error.response.data.error.message);
      }
      toast.error('Hubo un error al editar la cesion');
      return Promise.reject('An error occurred while trying to update the cession.');
    }
  },
);

const cessionsSlice = createSlice({
  name: 'cession',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.cessions = action.payload.data.cessions;
      })
      .addCase(fetchCessions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCessions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch cessions';
      })
      .addCase(createCession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.cessions.unshift(action.payload.data.cession);
        state.success = true;
      })
      .addCase(createCession.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create cession';
      })
      .addCase(deleteCession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.cessions = state.cessions.filter((cession) => cession._id !== action.meta.arg.id);
      })
      .addCase(deleteCession.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete cession';
      })
      .addCase(updateCession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        const { _id } = action.payload.data.cession;
        const index = state.cessions.findIndex((cession) => cession._id === _id);
        if (index !== -1) {
          state.cessions[index] = { ...state.cessions[index], ...action.payload.data.cession };
        }
      })
      .addCase(updateCession.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update cession';
      });
  },
});

export default cessionsSlice.reducer;

export const { clearState } = cessionsSlice.actions;
