import React from 'react';

export type FormInputProps = {
  type?: string;
  name: string;
  value: string | number;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  error?: string;
};

const FormInput = (props: FormInputProps) => {
  const { name, value, onChange, label, placeholder, disabled, type, hasError, error } = props;
  return (
    <div>
      {label && (
        <label className={hasError ? 'errorlabel' : ''} htmlFor={name}>
          {label} {hasError && <span style={{ fontSize: '0.6rem', verticalAlign: 'middle' }}>({error})</span>}
        </label>
      )}
      <input
        className={hasError ? 'errorinput' : ''}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        type={type || 'text'}
      />
    </div>
  );
};

export default FormInput;
