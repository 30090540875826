import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../components/TopContent';
import Table from '../../components/Table';
import { NEW_CLIENT_ROUTE } from '../../../utils/routes';
import { TableHeaderToClientProperty, TableHeaders } from './consts';
import { AppDispatch, RootState } from '../../../services/store';
import { fetchClients } from '../../../services/clients/clientSlice';
import { csvHeaderAdapt, formatDataToTable } from './utils';
import FilterModal, { FilterValues } from '../../components/FilterModal';
import { State } from '../../components/FilterModal/utils';
import { Client } from '../../../types/client';

const ClientsContent = ({ clients }: { clients: Client[] }) => {
  const isLoading = useSelector((state: RootState) => state.client.isLoading);
  const error = useSelector((state: RootState) => state.client.error);
  const navigate = useNavigate();

  const DisplayConfig = {
    filtersButton: true,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<FilterValues>({
    hasRut: false,
    state: State.None,
    completedTransactions: 0,
    ongoingTransactions: 0,
  });

  const applyFilters = (filters: FilterValues) => {
    setFilterCriteria(filters);
  };

  const clearFilters = () => {
    setFilterCriteria({ hasRut: false, state: State.None, completedTransactions: 0, ongoingTransactions: 0 });
  };

  let filteredClients = clients as Client[];

  if (
    filterCriteria.hasRut ||
    filterCriteria.state !== State.None ||
    filterCriteria.ongoingTransactions !== 0 ||
    filterCriteria.completedTransactions !== 0
  ) {
    filteredClients = filteredClients.filter((client) => {
      const filterHasRut = filterCriteria.hasRut && !client.rut;
      const filterState = filterCriteria.state !== State.None && client.state !== filterCriteria.state;
      const filterCompletedTrans =
        filterCriteria.completedTransactions !== 0 &&
        (!client.completedTransactions || client.completedTransactions < (filterCriteria.completedTransactions as number));
      const filterOngoingTransactions =
        filterCriteria.ongoingTransactions !== 0 &&
        (!client.ongoingTransactions || client.ongoingTransactions.checks.length < (filterCriteria.ongoingTransactions as number));
      return !(filterHasRut || filterState || filterCompletedTrans || filterOngoingTransactions);
    });
  }

  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchText(value.trim().toLowerCase());
  };

  if (searchText) {
    const searchFilteredClients = clients.filter((client) => {
      const clientAttributesToSearch = Object.keys(TableHeaderToClientProperty)
        .map((key) => client[TableHeaderToClientProperty[key as keyof typeof TableHeaderToClientProperty]])
        .filter((value): value is string => typeof value === 'string');

      const match = clientAttributesToSearch.some((attr) => {
        if (typeof attr === 'string') {
          return attr.toLowerCase().includes(searchText);
        }
        return false;
      });

      return match;
    });
    filteredClients = filteredClients.length > 0 ? filteredClients.filter((client) => searchFilteredClients.includes(client)) : searchFilteredClients;
  }

  const handleClientClick = (clientId: string) => {
    navigate(`/clientes/${clientId}`);
  };

  const downloadAsCSV = () => {
    const allKeys: string[] =
      filteredClients.length > 0
        ? filteredClients.reduce((keys: string[], client) => {
            Object.keys(client).forEach((key) => {
              if (
                !keys.includes(key) &&
                key !== 'bankAccounts' &&
                key !== 'password' &&
                key !== 'telefono' &&
                key !== 'ongoingTransactions' &&
                key !== 'devicePhone' &&
                key !== 'tradename' &&
                key !== 'dollarsAccount' &&
                key !== 'pesosAccount' &&
                key !== 'status' &&
                key !== 'observations' &&
                key !== '_deleted' &&
                key !== '__v'
              ) {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = filteredClients.map((client) => allKeys.map((key) => (client as any)[key] || '-').join(',')).join('\n');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'clientes.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const allKeys: string[] =
      filteredClients.length > 0
        ? filteredClients.reduce((keys: string[], client) => {
            Object.keys(client).forEach((key) => {
              if (
                !keys.includes(key) &&
                key !== 'bankAccounts' &&
                key !== 'password' &&
                key !== 'telefono' &&
                key !== 'ongoingTransactions' &&
                key !== 'devicePhone' &&
                key !== 'tradename' &&
                key !== 'dollarsAccount' &&
                key !== 'pesosAccount' &&
                key !== 'status' &&
                key !== 'observations' &&
                key !== '_deleted' &&
                key !== '__v'
              ) {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = filteredClients.map((client) => allKeys.map((key) => (client as any)[key] || '-'));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'clientes.xlsx';

    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      {isFilterModalOpen && (
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          onApply={applyFilters}
          onClear={clearFilters}
          filters={filterCriteria}
        />
      )}
      <TopContent
        title={'Clientes'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => setIsFilterModalOpen(true)}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_CLIENT_ROUTE}
        onClickDownload={() => downloadAsCSV()}
        onClickDownloadExcel={downloadAsExcel}
      />

      <div className="clientsTable wrapperTable">
        <Table
          headers={Object.values(TableHeaders).filter((header) => header !== TableHeaders.ID)}
          data={formatDataToTable(filteredClients)}
          onRowClick={handleClientClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const Clients = () => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch<AppDispatch>();
  const clients = useSelector((state: RootState) => state.client.clients);

  useEffect(() => {
    clients.length === 0 && dispatch(fetchClients(token || ''));
  }, []);

  return <ClientsContent clients={clients} />;
};

export default Clients;
