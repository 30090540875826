import React, { useState, useEffect } from 'react';
import { Cession as CessionType, DocumentsType } from '../../../types/cession';
import { emptyCession } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../../components/ConfirmationModal';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import { clearState, createCession, deleteCession, fetchCessions, updateCession } from '../../../services/cessions/cessionSlice';
import { CESSIONS_ROUTE } from '../../../utils/routes';
import DatePicker from '../../components/DatePicker';
import { fetchClients } from '../../../services/clients/clientSlice';
import { Client } from '../../../types/client';
import Dropdown, { DropdownProps } from '../../components/Dropdown';
import { PuffLoader } from 'react-spinners';
import { uploadFile } from './uploadFile';
import CessionImages from './images';
import { toast } from 'react-toastify';
import { Drawer } from '../../../types/drawer';
import { SearchDropdown, SearchDropdownProps } from '../../components/SearchDropdown';

const Cession = () => {
  const [cession, setCession] = useState<CessionType>(emptyCession);
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.cession.isLoading);
  const success = useSelector((state: RootState) => state.cession.success);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('_id');
  const cessions = useSelector((state: RootState) => state.cession.cessions);
  const clients = useSelector((state: RootState) => state.client.clients);
  const drawers = useSelector((state: RootState) => state.drawer.drawers);
  const isFetchingClients = useSelector((state: RootState) => state.client.isLoading);
  const [documents, setDocuments] = useState<[File, string][]>([]);
  const [oldDocuments, setOldDocuments] = useState<DocumentsType[]>([]);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleDeleteOldDocument = (document: string) => {
    const old = oldDocuments.filter((doc) => doc.url !== document);
    setOldDocuments(old);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
    if (id !== undefined) {
      setIsFetching(true);
      const foundCession = cessions.find((cession: CessionType) => cession._id === id);
      if (!foundCession) {
        dispatch(fetchCessions(token || ''));
      }
      foundCession && setCession(foundCession);
      foundCession && setOldDocuments(foundCession.documents ? foundCession.documents : []);
    }
  }, []);

  const handleDropdownValues = (foundCession: CessionType) => {
    const selectedClient = clients.find((client) => client._id === foundCession['clientId']);
    if (selectedClient) {
      setSelectedClient(selectedClient);
      handleClientSelect(selectedClient);
    }
    const selectedDrawer = drawers.find((drawer) => drawer._id === foundCession['drawerId']);
    if (selectedDrawer) {
      handleDrawerSelect(selectedDrawer);
    }
  };

  useEffect(() => {
    const foundCession = cessions.find((cession: CessionType) => cession._id === id);
    foundCession && setCession(foundCession);
      foundCession && setOldDocuments(foundCession.documents ? foundCession.documents : []);
    setIsFetching(false);
  }, [cessions]);

  useEffect(() => {
    if (id !== undefined) {
      const foundCession = cessions.find((cession: CessionType) => cession._id === id);
      foundCession && handleDropdownValues(foundCession);
    }
  }, [clients]);

  useEffect(() => {
    if (clients.length <= 0) dispatch(fetchClients(token || ''));
  }, []);

  useEffect(() => {
    if (success && !isEditing) {
      navigate(CESSIONS_ROUTE);
    }
  }, [success]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCession((previousCession) => ({
      ...previousCession,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditing((prevState) => !prevState);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteCession({ id: id, token: token || '' }));
      navigate(CESSIONS_ROUTE);
    }
  };

  const handleClientSelect = (client: Client | null) => {
    setSelectedClient(client);
    setCession((previousCession) => ({
      ...previousCession,
      clientId: client?._id || '',
    }));
  };

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: cession['_id'] !== undefined,
    deleteButton: cession['_id'] !== undefined,
  };

  const handleSubmit = () => {
    const newDocuments: DocumentsType[] = [];

    if (documents) {
      documents.forEach(async (document) => {
        const documentName = `${document[0].name}`;
        const documentURL = `bucket/s3/${documentName}`;
        const friendlyName = document[1];
        newDocuments.push({ name: documentName, url: documentURL, friendlyName });
        await uploadFile(document[0], documentName).then((response) => {
          if (response !== 'OK') {
            toast.error('Error subiendo las imagenes');
            setIsFetching(false);
            return;
          }
        });
      });
    }

    setDocuments([]);

    const documentsToSave: DocumentsType[] = [...oldDocuments, ...newDocuments];

    const cessionWithFiles = {
      ...cession,
      documents: documentsToSave,
    };

    if (cession['_id']) {
      dispatch(updateCession({ id: id, cessionData: cessionWithFiles, token: token || '' }));
    } else {
      if (userId) cession.processedBy = userId;
      dispatch(createCession({ cessionData: cessionWithFiles, token: token }));
    }
  };

  const onSelectOptionClient = (id: string) => {
    if (clients) {
      const selectedClient = clients.find((client) => client._id === id);
      if (selectedClient) {
        handleClientSelect(selectedClient);
      }
    }
  };

  const clientSearchDropdownProps: SearchDropdownProps = {
    label: 'Cliente:',
    defaultValue: cession['client'] ? (cession['client']._id ? cession['client']._id : cession['client'].email) : '',
    disabled: id !== undefined && !isEditing,
    onSelectOption: onSelectOptionClient,
    onDelete: () => handleClientSelect(null),
    options: clients.map((client) => ({
      value: client._id ? client._id : client.email,
      label: client.name + ' - ' + client.email + ' - ' + `${client.legalName || ''}`,
    })),
  };

  const handleDrawerSelect = (drawer: Drawer | null) => {
    setCession((previousCession) => ({
      ...previousCession,
      drawer: drawer || undefined,
      drawerId: drawer?._id || '',
    }));
  };

  const onSelectOptionDrawer = (id: string) => {
    if (drawers) {
      const selectedDrawer = drawers.find((drawer) => drawer._id === id);
      if (selectedDrawer) {
        handleDrawerSelect(selectedDrawer);
      }
    }
  };

  const drawerSearchDropdownProps: SearchDropdownProps = {
    label: 'Librador:',
    defaultValue: cession['drawer'] ? (cession['drawer']._id ? cession['drawer']._id : cession['drawer'].name) : '',
    disabled: id !== undefined && !isEditing,
    onSelectOption: onSelectOptionDrawer,
    onDelete: () => handleDrawerSelect(null),
    options: drawers.map((drawer) => ({
      value: drawer._id ? drawer._id : drawer.name,
      label: drawer.name,
    })),
  };

  const handleGoToClient = () => {
    navigate(`/clientes/${cession?.clientId}`);
  };

  return isFetching || isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="clientManagement cession">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onRequestClose={closeDeleteModal}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar el cesion ${cession?._id}?`}
        />
        <BackButton />
        <TopContent
          title={!cession['_id'] ? 'Registrar Cesión' : isEditing ? 'Editar Cesión' : cession['_id'].toString().slice(-6).toString()}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={handleEditClick}
          onClickDelete={openDeleteModal}
        />
      </div>
      <div className="formRegister form">
        <div className="formRegister__wrapper">
          {isFetchingClients ? <PuffLoader color="#3D9FE0" /> : <SearchDropdown {...clientSearchDropdownProps} />}
          {id !== undefined && cession['clientId'] && (
            <button className="btnDark verCliente" type="button" onClick={handleGoToClient}>
              Ver Cliente
            </button>
          )}
          {cession['_id'] && (
            <FormInput
              name="_id"
              value={cession['_id'] ? cession['_id']?.toString() : ''}
              label="ID"
              type="text"
              onChange={() => null}
              disabled={true}
            />
          )}
          <SearchDropdown {...drawerSearchDropdownProps} />
          <FormInput
            name="associatedInvoice"
            value={cession['associatedInvoice'] ? cession['associatedInvoice']?.toString() : ''}
            label="Factura Asociada"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <FormInput
            name="entity"
            value={cession['entity'] ? cession['entity']?.toString() : ''}
            label="Ente"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <Dropdown
            options={[
              { value: 'pendiente', label: 'Adelanto Otorgado (pendiente de liquidación)' },
              { value: 'concretado', label: 'Operación Concretada' },
              { value: 'no adelantada', label: 'No Adelantada' },
              { value: 'otros', label: 'Otros' },
            ]}
            name={'state'}
            value={cession['state'] ? cession['state']?.toString() : ''}
            label={'Estado'}
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <DatePicker
            name={'advanceDate'}
            value={cession['advanceDate'] ? cession['advanceDate']?.toString() : ''}
            label={'Fecha Adelanto'}
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <Dropdown
            options={[
              { value: 'peso', label: 'UYU' },
              { value: 'dolar', label: 'USD' },
            ]}
            name={'currency'}
            value={cession['currency'] ? cession['currency']?.toString() : ''}
            label={'Moneda'}
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <FormInput
            name="advanceAmount"
            value={cession['advanceAmount'] ? cession['advanceAmount']?.toString() : ''}
            label="Monto Adelanto"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <DatePicker
            name={'proposedExpiration'}
            value={cession['proposedExpiration'] ? cession['proposedExpiration']?.toString() : ''}
            label={'Vencimiento Propuesto'}
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <FormInput
            name="agreedRate"
            value={cession['agreedRate'] ? cession['agreedRate']?.toString() : ''}
            label="Tasa Acordada"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <FormInput
            name="observations"
            value={cession['observations'] ? cession['observations']?.toString() : ''}
            label="Observaciones"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          <FormInput
            name="channeledBy"
            value={cession['channeledBy'] ? cession['channeledBy']?.toString() : ''}
            label="Canalizado Por"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!cession['_id']}
          />
          {cession['_id'] && (
            <FormInput
              name="user"
              value={cession.user?.name ? cession.user.name : ''}
              label="Procesado Por:"
              type="text"
              onChange={handleChange}
              disabled={true}
            />
          )}
        </div>
        <CessionImages
          cession={cession}
          setSelectedImage={(image) => setSelectedImage(image)}
          isEditing={isEditing}
          setDocuments={(file) => setDocuments(file)}
          documents={documents}
          selectedImage={selectedImage}
          setDeletedOldDocuments={(document) => handleDeleteOldDocument(document)}
          oldDocuments={oldDocuments}
        />
        <div className="actBtn">
          {!cession['_id'] || isEditing ? (
            <ActionButton
              onClick={handleSubmit}
              text={isLoading ? 'Cargando...' : cession['_id'] ? 'Editar' : 'Crear'}
              alt={cession['_id'] ? 'Edit button' : 'Submit button'}
              disabled={id !== undefined && !isEditing}
            />
          ) : null}
        </div>
        {success && (
          <p className="succsesP" style={{ color: 'green' }}>
            Operación realizada con éxito.
          </p>
        )}
      </div>
    </div>
  );
};

export default Cession;
