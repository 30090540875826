import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

import { TableHeaders } from '../../views/Clients/consts';

export type DataTableType = {
  [key: string]: React.ReactNode;
};

export type TableType = {
  headers: string[];
  data: DataTableType[];
  onRowClick: (id: string) => void;
  showId?: boolean;
  showDelete?: boolean;
  onDelete?: (checkId: string) => void;
  isLoading?: boolean;
};

const Table = (props: TableType) => {
  const { headers, data, onRowClick, showId, showDelete, onDelete, isLoading } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(13);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;

  const currentItems = data.slice(indexOfFirstItem, indexOfFirstItem + itemsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleRowClick = (id: string) => {
    onRowClick(id);
  };

  const renderPagination = () => {
    const visiblePages = 5;
    const pages = [];

    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (totalPages <= visiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(visiblePages / 2)) {
        endPage = visiblePages;
      } else if (currentPage + Math.floor(visiblePages / 2) >= totalPages) {
        startPage = totalPages - visiblePages + 1;
      }
    }

    pages.push(
      <button key={1} onClick={() => handlePageChange(1)}>
        {1}
      </button>,
    );

    if (currentPage > 2) {
      pages.push(<span key="ellipsis-start">...</span>);
    }

    if (currentPage !== 1 && currentPage !== totalPages) {
      pages.push(
        <button key={currentPage} onClick={() => handlePageChange(currentPage)} className="active">
          {currentPage}
        </button>,
      );
    }

    if (currentPage < totalPages - 1) {
      pages.push(<span key="ellipsis-end">...</span>);
    }

    if (totalPages > 1) {
      pages.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>,
      );
    }

    return (
      <div className="pagination">
        <button className="btnPrev" onClick={handlePreviousPage} disabled={currentPage === 1}>
          <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
            <title />
            <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
          </svg>
        </button>
        {pages}
        <button className="btnNext" onClick={handleNextPage} disabled={currentPage === totalPages}>
          <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
            <title />
            <path d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z" />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div className="userTable__container">
      <div className="userTable__itemTitle">
        {headers.map((header, index) => (
          <div key={index} className={`column ${header.replaceAll(' ', '-')}`}>
            {/* <div key={index} className={`column`}> */}
            {header}
          </div>
        ))}
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
          <PuffLoader color="#3D9FE0" />
        </div>
      ) : (
        currentItems?.map((data: DataTableType, index) => {
          return (
            <div key={index} className="userTable__itemContent" onClick={() => handleRowClick(data[TableHeaders.ID] as string)}>
              {headers.map((header: string) => {
                if (!showId && header === 'ID') return;
                else {
                  if (header === 'ID') {
                    if (data[header]) {
                      const idText = data[header] as string;
                      return <div className='column ID'>{idText.slice(-6).toUpperCase()}</div>;
                    } else return 'NUEVO';
                  }
                  return (
                    <div key={header} className={`column ${header.replaceAll(' ', '-')}`}>
                      {/* <div key={header} className={`column`}> */}
                      {data[header]}
                    </div>
                  );
                }
              })}
              {showDelete && onDelete && (
                <div>
                  <button
                    className="btnDelete"
                    style={{
                      backgroundColor: '#9E4772',
                      color: 'white',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete && onDelete(data[TableHeaders.ID] as string);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" viewBox="0 0 256 256">
                      <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                    </svg>
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
      {!isLoading && renderPagination()}
    </div>
  );
};

export default Table;
