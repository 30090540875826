import React from 'react';
import moment from 'moment';

export type DatePickerProps = {
  name: string;
  value: string;
  label: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DatePicker = (props: DatePickerProps) => {
  const { label, value, onChange, name, disabled } = props;

  const parsedDate = moment.utc(value).format('YYYY-MM-DD');

  return (
    <div>
      <label>{label}</label>
      <input type="date" value={parsedDate} onChange={onChange} name={name} disabled={disabled}></input>
    </div>
  );
};

export default DatePicker;
