import React from 'react';
import FormInput from '../../components/FormInput';
import { Drawer as DrawerType } from '../../../types/drawer';
import ActionButton from '../../components/ActionButton';
import Dropdown from '../../components/Dropdown';
// import { banks } from '../Client/consts';

export type DrawerFormType = {
  drawer: DrawerType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditing: boolean;
  handleSubmit: () => void;
  isLoading: boolean;
  error: string | null;
  success: boolean;
};

const DrawerForm = ({ drawer, handleChange, isEditing, handleSubmit, isLoading, error, success }: DrawerFormType) => {
  // const dropdownOptions = banks.map((bank) => ({
  //   value: bank.name,
  //   label: bank.name,
  // }));

  return (
    <>
      <div className="formRegister form">
        <FormInput
          name={'name'}
          value={drawer ? drawer['name']?.toString() : ''}
          label={'Nombre'}
          onChange={handleChange}
          type={'string'}
          disabled={!isEditing && !!drawer['_id']}
        />
        <FormInput
          name={'rut'}
          value={drawer ? drawer['rut']?.toString() : ''}
          label={'RUT'}
          onChange={handleChange}
          type={'number'}
          disabled={!isEditing && !!drawer['_id']}
        />
        <FormInput
          name={'legalName'}
          value={drawer ? drawer['legalName']?.toString() : ''}
          label={'Razón social'}
          onChange={handleChange}
          type={'string'}
          disabled={!isEditing && !!drawer['_id']}
        />
        {/* <Dropdown
          name={'bank'}
          value={drawer ? drawer['bank']?.toString() : ''}
          label={'Banco'}
          options={dropdownOptions}
          onChange={handleChange}
          disabled={!isEditing && !!drawer['_id']}
        /> */}
        <FormInput
          name={'tradeName'}
          value={drawer ? drawer['tradeName']?.toString() : ''}
          label={'Nombre comercial'}
          onChange={handleChange}
          type={'string'}
          disabled={!isEditing && !!drawer['_id']}
        />

        {!isLoading ? (
          <ActionButton
            onClick={handleSubmit}
            text={isLoading ? 'Cargando...' : drawer['_id'] ? 'Editar' : 'Crear'}
            alt={drawer['_id'] ? 'Edit button' : 'Submit button'}
            disabled={drawer['_id'] !== undefined && !isEditing}
          />
        ) : null}
      </div>
      {/* TODO: hacer que esto sea un toast */}
      {error && (
        <p className="errorP" style={{ color: 'red' }}>
          {error}
        </p>
      )}
      {success && (
        <p className="succsesP" style={{ color: 'green' }}>
          {'Operación realizada con éxito.'}
        </p>
      )}
    </>
  );
};

export default DrawerForm;
