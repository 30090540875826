import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { Check } from '../../../types/check';

export const formatDataToTable = (checks: Check[]): DataTableType[] => {
  return checks.reduce((accumulator: DataTableType[], check: Check) => {
    const value: DataTableType = {
      [TableHeaders.ID]: check._id,
      [TableHeaders.AMOUNT]: (
        <div style={{ textAlign: 'end' }}>
          {parseCheckCurrency(check.currency)}{' '}
          {parseFloat(check.amount?.toString() || '')
            .toFixed(2)
            .replace(/\./g, '#')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            .replace(/,/g, '.')
            .replace(/#/g, ',')}
        </div>
      ),
      [TableHeaders.STATE]: <div style={{ width: '100%' }}>{parseCheckState(check.state)}</div>,
      [TableHeaders.CLIENT]: <div>{check.client?.name || '-'}</div>,
      [TableHeaders.DRAWER]: <div>{check.drawer?.name || '-'}</div>,
      [TableHeaders.DUE_DATE]: (
        <div>
          {check.dueDate
            ? new Date(check.dueDate.toString()).getUTCDate().toString().padStart(2, '0') +
              '/' +
              (new Date(check.dueDate.toString()).getUTCMonth() + 1).toString().padStart(2, '0') +
              '/' +
              new Date(check.dueDate.toString()).getUTCFullYear().toString()
            : '-'}
        </div>
      ),
    };
    return [...accumulator, value];
  }, []);
};

export const parseCheckCurrency = (currency: string | undefined) => {
  if (currency === 'peso') return '$';
  else if (currency === 'dolar') return 'USD';
  else return '';
};

export const parseCheckState = (state: number | undefined) => {
  if (state == 0) {
    return <div className="greenStatus">Aprobado por Descontalo</div>;
  } else if (state == 1) {
    return <div className="greenStatus">Aprobado por Cliente</div>;
  } else if (state == 2) {
    return <div className="greenStatus">Recibido y Endosado Correctamente</div>;
  } else if (state == 3) {
    return <div className="redStatus">No Recibido o No Cumple Requisitos</div>;
  } else if (state == 4) {
    return <div className="redStatus">Rechazado por Descontalo</div>;
  } else if (state == 5) {
    return <div className="redStatus">Rechazado por Cliente</div>;
  } else if (state == 6) {
    return <div className="redStatus">Operación No Concretada</div>;
  } else if (state == 7) {
    return <div className="yellowStatus">Recibido sin procesar</div>;
  } else if (state == 8) {
    return <div className="greenStatus">Transacción Completada</div>;
  } else if (state == 9) {
    return <div className="greenStatus">En RED PAGOS</div>;
  } else if (state == 10) {
    return <div className="greenStatus">En CARTERA</div>;
  } else if (state == 11) {
    return <div className="greenStatus">Depositado</div>;
  } else if (state == 12) {
    return <div className="greenStatus">Cobrado</div>;
  } else if (state == 13) {
    return <div className="greenStatus">Refinanciado</div>;
  } else if (state == 14) {
    return <div className="greenStatus">Rebotado y Cobrado</div>;
  } else if (state == 15) {
    return <div className="redStatus">Rebotado y No Cobrado</div>;
  } else if (state == 16) {
    return <div className="greenStatus">Entregado a un tercero</div>;
  } else {
    return <div>-</div>;
  }
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    client: 'Nombre del Cliente',
    drawer: 'Nombre del Librador',
    clientId: 'ID Cliente',
    drawerId: 'ID Librador',
    operationId: 'ID Operacion',
    amount: 'Monto',
    issueDate: 'Fecha Emision',
    dueDate: 'Vencimiento',
    stripe: 'Banda',
    channeledBy: 'Canalizado por',
    rate: 'Tasa',
    interest: 'Interes',
    comission: 'Comision',
    paymentAmount: 'Monto a Pagar',
    globalQuoteAdjustment: 'Ajuste Cotizacion Global',
    state: 'Estado',
    currency: 'Moneda',
    name: 'Nombre',
    phone: 'Telefono',
    creationDate: 'Fecha de Creación',
    commission: 'Comisión',
    internalUseMessage: 'Mensaje de Uso Interno',
    lastModifiedDate: 'Ultima Fecha de Modificación',
    rut: 'RUT',
    businessName: 'Nombre Empresa',
    tradename: 'Nombre Comercial',
    bank: 'Banco',
    approvalCode: 'Código de Aprovación',
  };
  return labels[key] || key;
};

export const parseCheckStateCSV = (state: number | string | undefined): string => {
  switch (state) {
    case '0':
      return 'Aprobado por Descontalo';
    case '1':
      return 'Aprobado por Cliente';
    case '2':
      return 'Recibido y Endosado Correctamente';
    case '3':
      return 'No Recibido o No Cumple Requisitos';
    case '4':
      return 'Rechazado por Descontalo';
    case '5':
      return 'Rechazado por Cliente';
    case '6':
      return 'Operación No Concretada';
    case '7':
      return 'Recibido sin procesar';
    case '8':
      return 'Transacción Completada';
    case '9':
      return 'En RED PAGOS';
    case '10':
      return 'En CARTERA';
    case '11':
      return 'Depositado';
    case '12':
      return 'Cobrado';
    case '13':
      return 'Refinanciado';
    case '14':
      return 'Rebotado y Cobrado';
    case '15':
      return 'Rebotado y No Cobrado';
    case '16':
      return 'Entregado a un Tercero';
    default:
      return state ? state.toString() : '-';
  }
};
