import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { Loan } from '../../../types/loan';

export const formatDataToTable = (loans: Loan[]): DataTableType[] => {
  return loans.reduce((accumulator: DataTableType[], loan: Loan) => {
    const value: DataTableType = {
      [TableHeaders.ID]: loan._id,
      [TableHeaders.AMOUNT]: <div>{loan.amount || '-'}</div>,
      [TableHeaders.TERM]: <div>{loan.term || '-'}</div>,
      [TableHeaders.CLIENT]: <div>{loan.client?.name || '-'}</div>,
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    amount: 'Monto préstamo',
    startDate: 'Inicio',
    endDate: 'Fin',
    term: 'Plazo',
    interestRate: 'Tasa',
    installments: 'Cuotas',
    fundSource: 'Origen de fondos',
    lendingCompany: 'Empresa que presta',
    collateral: 'Garantía/s',
    documentation: 'Documentación',
    installmentPaymentDate: 'Fecha de pago de cuota',
    amountPaid: 'Monto pagado',
    businessType: 'Tipo de negocio',
    referredBy: 'Referenciado por',
    channeledBy: 'Canalizado por',
    amortizationType: 'Tipo de amortización',
    _id: 'ID',
    clientId: 'ID Cliente',
    creationDate: 'Fecha de Creación',
  };
  return labels[key] || key;
};
