import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { Client } from '../../../types/client';

export const formatDataToTable = (clients: Client[]): DataTableType[] => {
  return clients.reduce((accumulator: DataTableType[], client: Client) => {
    const value: DataTableType = {
      [TableHeaders.ID]: client._id,
      [TableHeaders.NAME]: <div>{client.name || '-'}</div>,
      [TableHeaders.RUT]: <div>{client.rut || '-'}</div>,
      [TableHeaders.LEGAL_NAME]: <div>{client.legalName || '-'}</div>,
      [TableHeaders.STATE]: <div>{client.state || '-'}</div>,
      [TableHeaders.COMPLETED_TRANSACTIONS]: <div>{client.completedTransactions || '-'}</div>,
      [TableHeaders.ONGOING_TRANSACTIONS]: <div>{client.ongoingTransactions?.checks.length || '-'}</div>,
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    name: 'Nombre',
    email: 'Email',
    ci: 'CI',
    address: 'Direccion',
    rut: 'RUT',
    phone: 'Telefono',
    legalName: 'Razón Social',
    tradeName: 'Nombre Fantasia',
    mainIndustry: 'Rubro Principal',
    fiscalAddress: 'Domicilio Fiscal',
    clientRating: 'Calificacion Cliente',
    baseOrigin: 'Origen Base',
    contactOrigin: 'Origen de Contacto',
    usedProducts: 'Productos Utilizados',
    state: 'Estado',
    nextSteps: 'Pasos a Seguir',
    fixedReferenceRate: 'Tasa Fija de Referencia',
    reference: 'Referencia',
    extraContactOrigin: 'Origen de Contacto Adicional',
    logsQuantity: 'Cantidad de Logs',
    amountTransacted: 'Monto Total Concretado (por moneda)',
    completedTransactions: 'Cantidad de Operaciones Concretadas',
    firstTransaction: 'Fecha Primera Operacion',
    lastTransaction: 'Fecha Última Operacion',
    password: 'Contraseña',
    telefono: 'Telefono',
  };
  return labels[key] || key;
};
