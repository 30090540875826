import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Loan } from '../../types/loan';

interface LoanState {
  loans: Loan[];
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: LoanState = {
  loans: [],
  isLoading: false,
  error: null,
  success: false,
};

export const fetchLoans = createAsyncThunk('loans', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/loans`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the loans.');
  }
});

export const createLoan = createAsyncThunk('loans/create', async ({ loanData, token }: { loanData: Loan; token: string | undefined | null }) => {
  try {
    const filteredLoanData: Partial<Loan> = Object.fromEntries(Object.entries(loanData).filter(([_, value]) => value !== '' && value !== null));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/loans/create`, filteredLoanData, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to create the loan.');
  }
});

export const deleteLoan = createAsyncThunk('loans/delete', async ({ id, token }: { id: string | undefined | null; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/loans/delete?id=${id}`, { headers });
    return id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to delete the loan.');
  }
});

export const updateLoan = createAsyncThunk(
  'loans/update',
  async ({ id, loanData, token }: { id: string | null | undefined; loanData: Loan; token: string }) => {
    try {
      const filteredLoanData: Partial<Loan> = Object.fromEntries(Object.entries(loanData).filter(([_, value]) => value !== '' && value !== null));
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/loans/update?id=${id}`, filteredLoanData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data.error.message);
      }
      return Promise.reject('An error occurred while trying to update the loan.');
    }
  },
);

const loansSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.loans = action.payload.data.loans;
      })
      .addCase(fetchLoans.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLoans.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch loans';
      })
      .addCase(createLoan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.loans.unshift(action.payload.data.loan);
        state.success = true;
      })
      .addCase(createLoan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createLoan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create loan';
      })
      .addCase(deleteLoan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.loans = state.loans.filter((loan) => loan._id !== action.meta.arg.id);
      })
      .addCase(deleteLoan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLoan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete loan';
      })
      .addCase(updateLoan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        const { _id } = action.payload.data.loan;
        const index = state.loans.findIndex((loan) => loan._id === _id);
        if (index !== -1) {
          state.loans[index] = { ...state.loans[index], ...action.payload.data.loan };
        }
      })
      .addCase(updateLoan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLoan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update loan';
      });
  },
});

export default loansSlice.reducer;

export const { clearState } = loansSlice.actions;
