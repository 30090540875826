import React from 'react';
const IconOperations = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1004_9)">
        <path d="M20 2V12H22V2H20Z" fill="white" />
        <path d="M24 2V12H26V2H24Z" fill="white" />
        <path d="M28 2V12H30V2H28Z" fill="white" />
        <path
          d="M16 20C15.4714 20.012 14.9459 19.9167 14.4552 19.7199C13.9645 19.5232 13.5187 19.229 13.1449 18.8551C12.771 18.4813 12.4768 18.0355 12.2801 17.5448C12.0833 17.0541 11.988 16.5286 12 16C11.988 15.4714 12.0833 14.9459 12.2801 14.4552C12.4768 13.9645 12.771 13.5187 13.1449 13.1449C13.5187 12.771 13.9645 12.4768 14.4552 12.2801C14.9459 12.0833 15.4714 11.988 16 12V10C14.8133 10 13.6533 10.3519 12.6666 11.0112C11.6799 11.6705 10.9109 12.6075 10.4567 13.7039C10.0026 14.8003 9.88378 16.0067 10.1153 17.1705C10.3468 18.3344 10.9182 19.4035 11.7574 20.2426C12.5965 21.0818 13.6656 21.6532 14.8295 21.8847C15.9933 22.1162 17.1997 21.9974 18.2961 21.5433C19.3925 21.0892 20.3295 20.3201 20.9888 19.3334C21.6481 18.3467 22 17.1867 22 16H20C20.012 16.5286 19.9167 17.0541 19.7199 17.5448C19.5232 18.0355 19.229 18.4813 18.8551 18.8551C18.4813 19.229 18.0355 19.5232 17.5448 19.7199C17.0541 19.9167 16.5286 20.012 16 20Z"
          fill="white"
        />
        <path
          d="M28.8931 18.4536L26.0982 16L24.7803 17.5044L27.5725 19.9561L25.2125 24.0439L21.7852 22.8848C20.9784 23.5604 20.0599 24.0898 19.0709 24.4492L18.3603 28H13.6403L12.9303 24.4492C11.9482 24.0845 11.0365 23.5529 10.2355 22.8779L6.78866 24.0439L4.42866 19.9561L7.15306 17.561C6.96664 16.5269 6.96427 15.468 7.14606 14.4331L4.42776 12.0439L6.78886 7.9561L10.2156 9.1152C11.0222 8.4396 11.9407 7.91017 12.9297 7.5508L13.6403 4H16.0003V2H13.6403C13.1779 2.00002 12.7298 2.16024 12.3723 2.45339C12.0147 2.74654 11.7698 3.15451 11.6792 3.6079L11.1755 6.1265C10.7161 6.34655 10.2727 6.59832 9.84836 6.88L7.42896 6.0615C6.99103 5.91293 6.51513 5.9209 6.08242 6.08404C5.64971 6.24718 5.287 6.55539 5.05616 6.9561L2.69556 11.0439C2.46446 11.4444 2.37921 11.9125 2.4543 12.3688C2.52939 12.825 2.7602 13.2411 3.10746 13.5464L5.03836 15.2432C5.02126 15.4946 5.00026 15.7446 5.00026 16C5.00026 16.2578 5.01026 16.5127 5.02806 16.7656L3.10746 18.4536C2.7602 18.7589 2.52939 19.175 2.4543 19.6312C2.37921 20.0875 2.46446 20.5556 2.69556 20.9561L5.05616 25.0439C5.287 25.4446 5.64971 25.7528 6.08242 25.916C6.51513 26.0791 6.99103 26.0871 7.42896 25.9385L9.86306 25.1152C10.2824 25.3979 10.7208 25.6513 11.1751 25.8735L11.6788 28.3921C11.7694 28.8456 12.0144 29.2536 12.372 29.5467C12.7297 29.8399 13.1778 30.0001 13.6403 30H18.3603C18.8226 30 19.2707 29.8398 19.6282 29.5466C19.9858 29.2535 20.2307 28.8455 20.3214 28.3921L20.8251 25.8735C21.2842 25.6535 21.7276 25.4017 22.1518 25.1201L24.5712 25.9385C25.0091 26.0871 25.485 26.0791 25.9177 25.916C26.3504 25.7528 26.7131 25.4446 26.944 25.0439L29.3046 20.9561C29.5357 20.5557 29.621 20.0875 29.546 19.6313C29.471 19.1751 29.2403 18.7589 28.8931 18.4536Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1004_9">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconOperations;
