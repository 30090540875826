import * as XLSX from 'xlsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { fetchDrawers } from '../../../services/drawers/drawerSlice';
import TopContent from '../../components/TopContent';
import Table from '../../components/Table';
import { TableHeaderToDrawerProperty, TableHeaders } from './consts';
import { csvHeaderAdapt, formatDataToTable } from './utils';
import { NEW_DRAWER_ROUTE } from '../../../utils/routes';
import { Drawer } from '../../../types/drawer';

const DrawersContent = ({ drawers }: { drawers: Drawer[] }) => {
  const isLoading = useSelector((state: RootState) => state.drawer.isLoading);
  const error = useSelector((state: RootState) => state.drawer.error);
  const navigate = useNavigate();

  const DisplayConfig = {
    filtersButton: false,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  let filteredDrawers = drawers;

  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchText(value.trim().toLowerCase());
  };

  if (searchText) {
    const searchFilteredDrawers = drawers.filter((drawer) => {
      const drawerAttributesToSearch = Object.keys(TableHeaderToDrawerProperty)
        .map((key) => drawer[TableHeaderToDrawerProperty[key as keyof typeof TableHeaderToDrawerProperty]])
        .filter((value): value is string => typeof value === 'string');

      const match = drawerAttributesToSearch.some((attr) => {
        if (typeof attr === 'string') {
          return attr.toLowerCase().includes(searchText);
        }
        return false;
      });

      return match;
    });
    filteredDrawers = filteredDrawers.length > 0 ? filteredDrawers.filter((drawer) => searchFilteredDrawers.includes(drawer)) : searchFilteredDrawers;
  }

  const handleDrawerClick = (drawerId: string) => {
    navigate(`/libradores/${drawerId}`);
  };

  const downloadAsCSV = () => {
    const allKeys: string[] =
      filteredDrawers.length > 0
        ? filteredDrawers.reduce((keys: string[], drawer) => {
            Object.keys(drawer).forEach((key) => {
              if (!keys.includes(key) && key !== '__v') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = filteredDrawers.map((drawer) => allKeys.map((key) => (drawer as any)[key] || '-').join(',')).join('\n');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'libradores.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const allKeys: string[] =
      filteredDrawers.length > 0
        ? filteredDrawers.reduce((keys: string[], drawer) => {
            Object.keys(drawer).forEach((key) => {
              if (!keys.includes(key) && key !== '__v') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = filteredDrawers.map((drawer) => allKeys.map((key) => (drawer as any)[key] || '-'));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Libradores');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'libradores.xlsx';

    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <TopContent
        title={'Libradores'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => null}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_DRAWER_ROUTE}
        onClickDownload={() => downloadAsCSV()}
        onClickDownloadExcel={downloadAsExcel}
      />
      <div className="drawersTable wrapperTable">
        <Table
          headers={Object.values(TableHeaders).filter((header) => header !== TableHeaders.ID)}
          data={formatDataToTable(filteredDrawers)}
          onRowClick={handleDrawerClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const Drawers = () => {
  const token = localStorage.getItem('token');

  const dispatch = useDispatch<AppDispatch>();

  const drawers = useSelector((state: RootState) => state.drawer.drawers);

  useEffect(() => {
    drawers.length === 0 && dispatch(fetchDrawers(token || ''));
  }, []);

  return <DrawersContent drawers={drawers} />;
};

export default Drawers;
