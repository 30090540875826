import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { fetchOperations } from '../../../services/operations/operationSlice';
import { TableHeaderToOperationProperty, TableHeaders } from './consts';
import TopContent from '../../components/TopContent';
import Table from '../../components/Table';
import { csvHeaderAdapt, formatDataToTable } from './utils';
import { useNavigate } from 'react-router';
import { NEW_OPERATION_ROUTE } from '../../../utils/routes';
import { Operation } from '../../../types/operation';

const OperationsContent = ({ operations }: { operations: Operation[] }) => {
  const isLoading = useSelector((state: RootState) => state.operation.isLoading);
  const navigate = useNavigate();

  const DisplayConfig = {
    filtersButton: false,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  let filteredOperations = operations;
  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchText(value.trim().toLowerCase());
  };

  if (searchText) {
    filteredOperations = operations.filter((operation) => {
      const attributesToSearch: (string | undefined)[] = Object.keys(TableHeaderToOperationProperty)
        .map((key) => {
          const operationProperty = TableHeaderToOperationProperty[key as keyof typeof TableHeaderToOperationProperty];
          if (operationProperty === 'client') {
            return [operation.client?.name, operation.client?.legalName];
          } else {
            return operation[operationProperty];
          }
        })
        .flat()
        .filter((value): value is string => typeof value === 'string');

      return attributesToSearch.some((attr: any) => attr.toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  const downloadAsCSV = () => {
    const allKeys: string[] =
      filteredOperations.length > 0
        ? filteredOperations.reduce((keys: string[], operation) => {
            Object.keys(operation).forEach((key) => {
              if (!keys.includes(key) && key !== '__v' && key !== 'client' && key !== 'checks' && key !== 'cessions') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = filteredOperations
      .map((operation) =>
        allKeys
          .map((key) => {
            const value = (operation as any)[key];
            return Array.isArray(value) ? value.join(';') : value || '-';
          })
          .join(','),
      )
      .join('\n');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'operaciones.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const allKeys: string[] =
      filteredOperations.length > 0
        ? filteredOperations.reduce((keys: string[], operation) => {
            Object.keys(operation).forEach((key) => {
              if (!keys.includes(key) && key !== '__v' && key !== 'client' && key !== 'checks' && key !== 'cessions') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = filteredOperations.map((operation) =>
      allKeys.map((key) => {
        const value = (operation as any)[key];
        return Array.isArray(value) ? value.join(';') : value || '-';
      }),
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Operaciones');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'operaciones.xlsx';

    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleRowClick = (operationId: string) => {
    navigate(`/operaciones/${operationId}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <TopContent
        title={'Operaciones'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => null}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_OPERATION_ROUTE}
        onClickDownload={downloadAsCSV}
        onClickDownloadExcel={downloadAsExcel}
      />
      <div className="operationsTable wrapperTable">
        <Table
          headers={Object.values(TableHeaders)}
          data={formatDataToTable(filteredOperations)}
          onRowClick={handleRowClick}
          isLoading={isLoading}
          showId
        />
      </div>
    </div>
  );
};

const Operations = () => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch<AppDispatch>();
  const operations = useSelector((state: RootState) => state.operation.operations);

  useEffect(() => {
    operations.length === 0 && dispatch(fetchOperations(token || ''));
  }, []);

  return <OperationsContent operations={operations} />;
};

export default Operations;
