import React from 'react';
import { Navigate } from 'react-router-dom';
import { LOGIN_ROUTE } from '../utils/routes';

interface AuthorizationProps {
  element: React.ReactNode;
  [key: string]: any;
}

const Authorization: React.FC<AuthorizationProps> = ({ element, ...rest }) => {
  const token = localStorage.getItem('token');

  if (token) {
    return <>{element}</>;
  } else {
    return <Navigate to={LOGIN_ROUTE} replace />;
  }
};

export default Authorization;
