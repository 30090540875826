import React from 'react';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import { Client } from '../../../types/client';
import Dropdown from '../../components/Dropdown';
import { accountTypes, banks, currencies } from './consts';

type BankAccountsType = {
  addBankAccount: () => void;
  deleteBankAccount: (id: number) => void;
  client: Client;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  inputsDisabled: boolean;
};

const BankAccounts = ({ client, handleChange, inputsDisabled, addBankAccount, deleteBankAccount }: BankAccountsType) => {
  const dropdownBankOptions = banks.map((bank) => ({
    value: bank.name,
    label: bank.name,
  }));

  const dropdownTypeOptions = currencies.map((currency) => ({
    value: currency.name,
    label: currency.name,
  }));

  const dropdownAccountTypeOptions = accountTypes.map((type) => ({
    value: type.name,
    label: type.name,
  }));

  return (
    <div className="formRegister__wrapper">
      <h1>Cuentas Bancarias</h1>
      {client['bankAccounts'] && client['bankAccounts'].length > 0 ? (
        <div>
          {client['bankAccounts'].map((bankAccount, index) => {
            return (
              <div key={index} style={{ borderBottom: '1px solid grey' }}>
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 40,
                    paddingBottom: 20,
                    paddingTop: 20,
                  }}
                >
                  <Dropdown
                    name={'bank'}
                    value={bankAccount ? bankAccount.bank?.toString() : ''}
                    label={'Banco'}
                    options={dropdownBankOptions}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    disabled={inputsDisabled}
                  />
                  <Dropdown
                    name={'type'}
                    value={bankAccount ? bankAccount.type?.toString() : ''}
                    label={'Moneda'}
                    options={dropdownTypeOptions}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    disabled={inputsDisabled}
                  />
                  <Dropdown
                    name={'accountType'}
                    value={bankAccount ? bankAccount.accountType?.toString() : ''}
                    label={'Tipo'}
                    options={dropdownAccountTypeOptions}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    disabled={inputsDisabled}
                  />
                  <FormInput
                    name={'name'}
                    value={bankAccount ? bankAccount.name?.toString() : ''}
                    label={'Nombre'}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    type={'text'}
                    disabled={inputsDisabled}
                  />
                  <FormInput
                    name={'number'}
                    value={bankAccount ? bankAccount.number?.toString() : ''}
                    label={'Numero'}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    type={'number'}
                    disabled={inputsDisabled}
                  />
                  <FormInput
                    name={'branch'}
                    value={bankAccount ? (bankAccount.branch ? bankAccount.branch?.toString() : '') : ''}
                    label={'Sucursal'}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    type={'text'}
                    disabled={inputsDisabled}
                  />
                  {!inputsDisabled && (
                    <button
                      className="btnDelete"
                      style={{
                        color: '#9E4772',
                        cursor: 'pointer',
                      }}
                      onClick={() => deleteBankAccount(bankAccount.number)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" viewBox="0 0 256 256">
                        <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                      </svg>
                    </button>
                  )}
                </div>
                <div>
                  <FormInput
                    name={'completeAccount'}
                    value={bankAccount ? (bankAccount.completeAccount ? bankAccount.completeAccount?.toString() : 'No Disponible') : 'No Disponible'}
                    label={'Cuenta Completa'}
                    onChange={(e) => handleChange(e, bankAccount.number)}
                    type={'text'}
                    disabled={true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>Sin cuentas bancarias.</p>
      )}
      <div style={{ padding: 20 }}>
        <ActionButton onClick={addBankAccount} text={'Agregar Cuenta'} alt={''} disabled={inputsDisabled} />
      </div>
    </div>
  );
};

export default BankAccounts;
