import { Cession } from './cession';
import { Check } from './check';

export type Client = {
  _id?: string;
  email: string;
  name: string;
  ci: number;
  address: string;
  rut?: string;
  phone: string;
  legalName?: string;
  tradeName?: string;
  mainIndustry?: string;
  fiscalAddress?: string;
  clientRating?: string;
  baseOrigin?: string;
  contactOrigin?: string;
  bankAccounts?: BankAccountType[];
  logsQuantity?: number;
  usedProducts?: string;
  state?: number;
  nextSteps?: string;
  fixedReferenceRate?: number;
  amountTransacted: number;
  completedTransactions: number;
  ongoingTransactions?: OngoingTransactions;
  firstTransaction?: Date;
  lastTransaction?: Date;
  reference?: string;
  extraContactOrigin?: string;
  creationDate?: Date;
};

export type BankAccountType = {
  bank: string;
  name: string;
  type: 'USD' | 'UYU' | '';
  accountType: 'CA' | 'CC' | '';
  number: number;
  completeAccount?: string;
  branch?: string;
};

export const emptyClient: Client = {
  email: '',
  name: '',
  ci: 0,
  address: '',
  phone: '',
  amountTransacted: 0,
  completedTransactions: 0,
};

export type OngoingTransactions = {
  checks: Check[];
  cessions: Cession[];
};
