import React, { useState, useEffect } from 'react';
import { DocumentsType, Loan as LoanType } from '../../../types/loan';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../../components/ConfirmationModal';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import DatePicker from '../../components/DatePicker';
import { fetchClients } from '../../../services/clients/clientSlice';
import { Client } from '../../../types/client';
import { clearState, createLoan, deleteLoan, fetchLoans, updateLoan } from '../../../services/loans/loansSlice';
import { emptyLoan } from './utils';
import { LOANS_ROUTE } from '../../../utils/routes';
import { PuffLoader } from 'react-spinners';
import { SearchDropdown, SearchDropdownProps } from '../../components/SearchDropdown';
import LoanImages from './images';
import { uploadFile } from './uploadFile';
import { toast } from 'react-toastify';

const Loan = () => {
  const [loan, setLoan] = useState<LoanType>(emptyLoan);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.loan.isLoading);
  const error = useSelector((state: RootState) => state.loan.error);
  const success = useSelector((state: RootState) => state.loan.success);
  const token = localStorage.getItem('token');
  const loans = useSelector((state: RootState) => state.loan.loans);
  const clients = useSelector((state: RootState) => state.client.clients);

  const [documents, setDocuments] = useState<[File, string][]>([]);
  const [oldDocuments, setOldDocuments] = useState<DocumentsType[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleDeleteOldDocument = (document: string) => {
    const old = oldDocuments.filter((doc) => doc.url !== document);
    setOldDocuments(old);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
    if (id !== undefined) {
      setIsFetching(true);
      const foundLoan = loans.find((loan: LoanType) => loan._id === id);
      if (!foundLoan) {
        dispatch(fetchLoans(token || ''));
      }
      foundLoan && setLoan(foundLoan);
    }
  }, []);

  const handleDropdownValues = (foundLoan: LoanType) => {
    const selectedClient = clients.find((client) => client._id === foundLoan['clientId']);
    if (selectedClient) {
      setSelectedClient(selectedClient);
      handleClientSelect(selectedClient);
    }
  };

  useEffect(() => {
    const foundLoan = loans.find((loan: LoanType) => loan._id === id);
    foundLoan && setLoan(foundLoan);
    foundLoan && setOldDocuments(foundLoan.documents ? foundLoan.documents : []);
    setIsFetching(false);
  }, [loans]);

  useEffect(() => {
    if (clients.length <= 0) return;
    if (id !== undefined) {
      const foundLoan = loans.find((loan: LoanType) => loan._id === id);
      foundLoan && handleDropdownValues(foundLoan);
    }
  }, [clients]);

  useEffect(() => {
    if (success && !isEditing) {
      navigate(LOANS_ROUTE);
    }
  }, [success]);

  useEffect(() => {
    if (clients.length <= 0) dispatch(fetchClients(token || ''));
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoan((previousLoan) => ({
      ...previousLoan,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditing((prevState) => !prevState);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteLoan({ id: id, token: token || '' }));
      navigate(LOANS_ROUTE);
    }
  };

  const handleClientSelect = (client: Client | null) => {
    setLoan((previousLoan) => ({
      ...previousLoan,
      client: client || undefined,
      clientId: client?._id || '',
    }));
  };

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: loan['_id'] !== undefined,
    deleteButton: loan['_id'] !== undefined,
  };

  const handleSubmit = () => {
    const newDocuments: DocumentsType[] = [];

    if (documents) {
      documents.forEach(async (document) => {
        const documentName = `${document[0].name}`;
        const documentURL = `bucket/s3/${documentName}`;
        const friendlyName = document[1];
        newDocuments.push({ name: documentName, url: documentURL, friendlyName });
        await uploadFile(document[0], documentName).then((response) => {
          if (response !== 'OK') {
            toast.error('Error subiendo las imagenes');
            setIsFetching(false);
            return;
          }
        });
      });
    }

    setDocuments([]);

    const documentsToSave: DocumentsType[] = [...oldDocuments, ...newDocuments];

    const loanWithFiles = {
      ...loan,
      documents: documentsToSave,
    };

    if (loan['_id']) {
      dispatch(updateLoan({ id: id, loanData: loanWithFiles, token: token || '' }));
    } else {
      dispatch(createLoan({ loanData: loanWithFiles, token: token }));
    }
  };

  const onSelectOptionClient = (id: string) => {
    if (clients) {
      const selectedClient = clients.find((client) => client._id === id);
      if (selectedClient) {
        handleClientSelect(selectedClient);
      }
    }
  };

  const clientSearchDropdownProps: SearchDropdownProps = {
    label: 'Cliente:',
    defaultValue: loan['client'] ? (loan['client']._id ? loan['client']._id : loan['client'].email) : '',
    disabled: id !== undefined && !isEditing,
    onSelectOption: onSelectOptionClient,
    onDelete: () => handleClientSelect(null),
    options: clients.map((client) => ({
      value: client._id ? client._id : client.email,
      label: client.name + ' - ' + client.email + ' - ' + `${client.legalName || ''}`,
    })),
  };

  const handleGoToClient = () => {
    navigate(`/clientes/${loan?.clientId}`);
  };

  return isFetching || isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="clientManagement loan">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onRequestClose={closeDeleteModal}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar el préstamo ${loan?._id}?`}
        />
        <BackButton />
        <TopContent
          title={!loan['_id'] ? 'Registrar Préstamo' : isEditing ? 'Editar Préstamo' : loan['_id']}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={handleEditClick}
          onClickDelete={openDeleteModal}
        />
      </div>
      <div className="formRegister form">
        <div className="formRegister__wrapper">
          <SearchDropdown {...clientSearchDropdownProps} />
          {id !== undefined && loan['clientId'] && (
            <button className="btnDark verCliente" type="button" onClick={handleGoToClient}>
              Ver Cliente
            </button>
          )}
          {loan['_id'] && <FormInput name="id" value={loan._id ? loan._id : ''} label="ID" type="text" onChange={() => null} disabled={true} />}
          <FormInput
            name="amount"
            value={loan['amount'] ? loan['amount']?.toString() : ''}
            label="Monto"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <DatePicker
            name={'startDate'}
            value={loan['startDate'] ? loan['startDate']?.toString() : ''}
            label={'Inicio'}
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <DatePicker
            name={'endDate'}
            value={loan['endDate'] ? loan['endDate']?.toString() : ''}
            label={'Finalización'}
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="term"
            value={loan['term'] ? loan['term']?.toString() : ''}
            label="Plazo"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="interestRate"
            value={loan['interestRate'] ? loan['interestRate']?.toString() : ''}
            label="Tasa"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="installments"
            value={loan['installments'] ? loan['installments']?.toString() : ''}
            label="Cuotas"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="fundSource"
            value={loan['fundSource'] ? loan['fundSource']?.toString() : ''}
            label="Origen de Fondos"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="lendingCompany"
            value={loan['lendingCompany'] ? loan['lendingCompany']?.toString() : ''}
            label="Empresa que presta"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="collateral"
            value={loan['collateral'] ? loan['collateral']?.toString() : ''}
            label="Garantía"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="documentation"
            value={loan['documentation'] ? loan['documentation']?.toString() : ''}
            label="Documentación"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <DatePicker
            name={'installmentPaymentDate'}
            value={loan['installmentPaymentDate'] ? loan['installmentPaymentDate']?.toString() : ''}
            label={'Fecha de Pago de Cuota'}
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="amountPaid"
            value={loan['amountPaid'] ? loan['amountPaid']?.toString() : ''}
            label="Monto Pagado"
            type="number"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="businessType"
            value={loan['businessType'] ? loan['businessType']?.toString() : ''}
            label="Tipo de Negocio"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="referredBy"
            value={loan['referredBy'] ? loan['referredBy']?.toString() : ''}
            label="Referenciado Por"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="channeledBy"
            value={loan['channeledBy'] ? loan['channeledBy']?.toString() : ''}
            label="Canalizado Por"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="amortizationType"
            value={loan['amortizationType'] ? loan['amortizationType']?.toString() : ''}
            label="Tipo de Amortización"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
          <FormInput
            name="observations"
            value={loan['observations'] ? loan['observations']?.toString() : ''}
            label="Observaciones"
            type="text"
            onChange={handleChange}
            disabled={!isEditing && !!loan['_id']}
          />
        </div>
        <LoanImages
          loan={loan}
          setSelectedImage={(image) => setSelectedImage(image)}
          isEditing={isEditing}
          setDocuments={(file) => setDocuments(file)}
          documents={documents}
          selectedImage={selectedImage}
          setDeletedOldDocuments={(document) => handleDeleteOldDocument(document)}
          oldDocuments={oldDocuments}
        />
        <div className="actBtn">
          {!loan['_id'] || isEditing ? (
            <ActionButton
              onClick={handleSubmit}
              text={isLoading ? 'Cargando...' : loan['_id'] ? 'Editar' : 'Crear'}
              alt={loan['_id'] ? 'Edit button' : 'Submit button'}
              disabled={!isEditing && !!loan['_id']}
            />
          ) : null}
        </div>
        {/* TODO: pasarlo a toast */}
        {error && (
          <p className="errorP" style={{ color: 'red' }}>
            {error}
          </p>
        )}
        {success && !error && (
          <p className="succsesP" style={{ color: 'green' }}>
            Operación realizada con éxito.
          </p>
        )}
      </div>
    </div>
  );
};

export default Loan;
