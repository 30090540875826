import { Loan } from '../../../types/loan';

export const TableHeaders = {
  ID: 'ID',
  AMOUNT: 'Monto',
  TERM: 'Plazo',
  CLIENT: 'Cliente',
};

export const TableHeaderToLoanProperty: Record<string, keyof Loan> = {
  ID: '_id',
  AMOUNT: 'amount',
  TERM: 'term',
  CLIENT: 'client',
};
