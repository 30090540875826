export const State = {
  None: -1,
  Pending: 0,
  Success: 1,
  InProgress: 2,
};

export const BaseOrigin = {
  None: -1,
  Pending: 0,
  Success: 1,
  InProgress: 2,
};

export const CheckState = {
  None: -1,
  DescontaloApproved: 0,
  ClientApproved: 1,
  ReceivedAndEndorsed: 2,
  NotReceivedOrEndorsed: 3,
  DescontaloRejected: 4,
  ClientRejected: 5,
  TransactionNotCompleted: 6,
  ReceivedWithoutProccessing: 7,
  TransactionCompleted: 8,
  RedPagos: 9,
  Wallet: 10,
  Deposited: 11,
  Cashed: 12,
  Refinanced: 13,
  BouncedAndCashed: 14,
  BouncedAndNotCashed: 15,
  HandedToThirdParty: 16,
};

export const getCustomLabel = (filterKey: string): string => {
  switch (filterKey) {
    case 'state':
      return 'Estado';
    case 'hasRut':
      return 'Con RUT';
    case 'ongoingTransactions':
      return 'Cantidad de Transacciones En Curso';
    case 'completedTransactions':
      return 'Transacciones Completadas';
    case 'checkState':
      return 'Estado del Cheque';
    case 'dateTo':
      return 'Fecha Hasta';
    case 'dateFrom':
      return 'Fecha Desde';
    default:
      return filterKey;
  }
};

export const getCheckStateLabel = (stateValue: number): string => {
  switch (stateValue) {
    case CheckState.DescontaloApproved:
      return 'Aprobado por Descontalo';
    case CheckState.ClientApproved:
      return 'Aprobado por Cliente';
    case CheckState.ReceivedAndEndorsed:
      return 'Recibido y Endosado Correctamente';
    case CheckState.NotReceivedOrEndorsed:
      return 'No Recibido o No Cumple Requisitos';
    case CheckState.DescontaloRejected:
      return 'Rechazado por Descontalo';
    case CheckState.ClientRejected:
      return 'Rechazado por Cliente';
    case CheckState.TransactionNotCompleted:
      return 'Operación No Concretada';
    case CheckState.ReceivedWithoutProccessing:
      return 'Recibido sin procesar';
    case CheckState.TransactionCompleted:
      return 'Transacción Completada';
    case CheckState.RedPagos:
      return 'En RED PAGOS';
    case CheckState.Wallet:
      return 'En CARTERA';
    case CheckState.Deposited:
      return 'Depositado';
    case CheckState.Cashed:
      return 'Cobrado';
    case CheckState.Refinanced:
      return 'Refinanciado';
    case CheckState.BouncedAndCashed:
      return 'Rebotado y Cobrado';
    case CheckState.BouncedAndNotCashed:
      return 'Rebotado y No Cobrado';
    case CheckState.HandedToThirdParty:
      return 'Entregado a un Tercero';
    default:
      return 'Sin Filtro';
  }
};
