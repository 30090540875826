import React from 'react';
const IconLibradores = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6667 13.7574H22.8067C22.5467 13.9683 22.284 14.1763 22.048 14.4338L20.0013 16.6665H18.668V13.7574H18.6667ZM16 18.121C16 16.1429 14.7013 14.4701 12.9133 14.1458L8.85867 13.4083C8.36133 13.3181 8 12.8527 8 12.3029C8 11.5014 8.59733 10.8483 9.33333 10.8483H12C12.736 10.8483 13.3333 11.5014 13.3333 12.3029H16C16 9.89705 14.2053 7.93923 12 7.93923V6.48469H9.33333V7.93923C7.128 7.93923 5.33333 9.89705 5.33333 12.3029C5.33333 14.281 6.632 15.9538 8.42 16.2781L12.4747 17.0156C12.972 17.1058 13.3333 17.5712 13.3333 18.121C13.3333 18.9225 12.736 19.5756 12 19.5756H9.33333C8.59733 19.5756 8 18.9225 8 18.121H5.33333C5.33333 20.5269 7.128 22.4847 9.33333 22.4847V23.9392H12V22.4847C14.2053 22.4847 16 20.5269 16 18.121ZM28 0.666504H4C1.79467 0.666504 0 2.62432 0 5.03014V29.7574H12V26.8483H2.66667V5.03014C2.66667 4.22869 3.264 3.57559 4 3.57559H28C28.736 3.57559 29.3333 4.22869 29.3333 5.03014V11.004C30.2853 11.2149 31.1907 11.676 32 12.3436V5.03014C32 2.62432 30.2053 0.666504 28 0.666504ZM26.6667 7.93923H18.6667V10.8483H26.6667V7.93923ZM30.828 15.036C31.5827 15.8592 32 16.956 32 18.121C32 19.2861 31.5827 20.3829 30.828 21.2076L20.3227 32.6665H14.6667V26.4949L25.172 15.036C26.732 13.3341 29.268 13.3341 30.828 15.036ZM29.3333 18.121C29.3333 17.7327 29.1947 17.3676 28.9427 17.0927C28.4213 16.524 27.5787 16.5254 27.0573 17.0927L17.3333 27.6992V29.7574H19.2187L28.9427 19.1494C29.1947 18.8745 29.3333 18.5094 29.3333 18.121Z"
        fill="white"
      />
    </svg>
  );
};
export default IconLibradores;
