import React, { useState, useEffect } from 'react';
import IconDelete from '../../../assets/iconDelete';
import IconDropdown from '../../../assets/iconDropdown';

export type SearchDropdownProps = {
  label: string;
  defaultValue: string | '';
  disabled?: boolean;
  onSelectOption: (e: any) => void;
  options: { value: string; label: string }[];
  onDelete?: () => void;
  error?: boolean;
};

export function SearchDropdown(props: SearchDropdownProps) {
  const { defaultValue, disabled, options, onSelectOption, label, onDelete, error } = props;
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (options.length > 0) {
      searchNameWithSelected();
    }
  }, [options]);

  const searchNameWithSelected = (): void => {
    const foundOption = options.find((option) => option.value === defaultValue);
    if (foundOption) {
      setSelected(foundOption.label);
      setInputValue(foundOption.value);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    setSelected('');
    setInputValue(value);
    setOpen(value !== '');
  };

  const onItemSelected = (option: any): void => {
    onSelectOption(option.value);
    setInputValue(`${option.label} | ${option.value}`);
    setOpen(false);
    setSelected(option.label);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const clearSelectedOption = (): void => {
    setSelected('');
    setInputValue('');
    onDelete && onDelete();
  };

  return (
    <div className="dropdown-container optionDropdown">
      <label>{label}</label>
      <div className="input-container">
        <input
          type="text"
          value={selected || inputValue}
          placeholder={selected !== '' ? `${selected} | ${defaultValue}` : 'Escriba para buscar una opción'}
          onChange={onInputChange}
          onKeyDown={handleKeyPress}
          className={error ? 'errorinput input-search' : 'input-search'}
          disabled={disabled}
        />
        <div className="arrow-trash">
          {selected !== '' && !disabled && onDelete && (
            <button className="dlt" onClick={clearSelectedOption}>
              <IconDelete />
            </button>
          )}
          {!disabled && (
            <button onClick={() => setOpen(!open)}>
              <IconDropdown />
            </button>
          )}
        </div>
      </div>
      {(inputValue !== '' || open) && (
        <div className={`dropdown ${open ? 'visible' : 'noVisible'}`}>
          {options
            .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
            .map((item) => (
              <div className="item-dropdown" key={item.value} onClick={() => onItemSelected(item)}>
                {item.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
