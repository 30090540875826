import * as XLSX from 'xlsx';
import React, { useEffect, useRef, useState } from 'react';
import { NEW_CHECK_ROUTE } from '../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate } from 'react-router';
import { csvHeaderAdapt, formatDataToTable, parseCheckStateCSV } from './utils';
import { TableHeaderToCheckProperty, TableHeaders } from './consts';
import { addChecks, fetchChecks } from '../../../services/checks/checkSlice';
import FilterModal, { FilterValues } from '../../components/FilterModal';
import { CheckState } from '../../components/FilterModal/utils';
import { Check } from '../../../types/check';
import axios from 'axios';
import PaginationTable from '../../components/Table/PaginationTable';
import ActionButton from '../../components/ActionButton';
import CircleButton from '../../components/CircleButton';
import IconEdit from '../../../assets/iconEdit';
import { Link } from 'react-router-dom';
import IconDelete from '../../../assets/iconDelete';

const Checks = () => {
  const token = localStorage.getItem('token');
  const [checks, setChecks] = useState<Check[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0, pages: 1 });
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/v2/checks?page=1&limit=10&search=`, { headers: headers })
      .then((response) => {
        const { data } = response.data;
        setChecks(data.checks);
        setPagination({ limit: data.limit, page: data.page, total: data.total, pages: data.pages });
      }).catch((error) => { setChecks([]) }).finally(() => setIsLoading(false));
  }, []);

  const DisplayConfig = {
    filtersButton: false,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<FilterValues>({
    checkState: CheckState.None,
    dateFrom: '',
    dateTo: '',
  });

  const applyFilters = (filters: FilterValues) => {
    setFilterCriteria(filters);
  };

  const clearFilters = () => {
    setFilterCriteria({ checkState: CheckState.None, dateTo: '', dateFrom: '' });
  };

  const handleSearch = (search: string) => {
    setSearchTerm(search);
    setIsLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/v2/checks?page=1&limit=10&search=${search}`, { headers: headers })
      .then((response) => {
        const { data } = response.data;
        setChecks(response.data.data.checks);
        setPagination({ limit: data.limit, page: data.page, total: data.total, pages: data.pages });
      }).catch((error) => { setChecks([]) }).finally(() => setIsLoading(false));
  };

  const handleRowClick = (checkId: string) => {
    navigate(`/cheques/${checkId}`);
  };

  const handlePagination = (page: number, limit: number) => {
    setIsLoading(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/v2/checks?page=${page}&limit=${limit}&search=${searchTerm}`, { headers: headers })
      .then((response) => {
        const { data } = response.data;
        setChecks(response.data.data.checks);
        setPagination({ limit: data.limit, page: data.page, total: data.total, pages: data.pages });
      }).catch((error) => { setChecks([]) }).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    dispatch(addChecks(checks));
  }, [checks])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      {isFilterModalOpen && (
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          onApply={applyFilters}
          onClear={clearFilters}
          filters={filterCriteria}
        />
      )}

      <Top
        title={'Cheques'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => setIsFilterModalOpen(true)}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_CHECK_ROUTE}
        onClickDownload={() => { }} //TODO: Implement download
      />

      <div className="checkTable wrapperTable">
        <PaginationTable
          isLoading={isLoading}
          headers={Object.values(TableHeaders)}
          data={formatDataToTable(checks)}
          onRowClick={handleRowClick}
          showId={true}
          pagination={pagination}
          fetchData={handlePagination}
        />
      </div>
    </div>
  );
};
export default Checks;

const Search = ({ fetchData }: { fetchData: (search: string) => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetch = async () => {
      // if (searchTerm) {
      try {
        fetchData(searchTerm);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      // }
    };

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetch();
    }, 200);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  return (
    <input
      className='inputSearch'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Buscar..."
    />
  );
};

const Top = (props: any) => {
  const {
    title,
    onClickOpenFilters,
    onChangeSearchBar,
    newElementRoute,
    onClickDownload,
    onClickEdit,
    onClickDelete,
    onClickDownloadExcel,
    displayElements: { addButton, editButton, filtersButton, searchBar, downloadButton, deleteButton },
  } = props;

  const isTableMode = filtersButton || searchBar;
  return (
    <div className={`topContent ${!isTableMode ? 'rowView' : ''}`}>
      <h1>{title}</h1>
      <div className="topContent__wrapper">
        {isTableMode && (
          <div className="leftArea">
            {filtersButton && <ActionButton onClick={onClickOpenFilters} text={'Filtros'} alt={'Filtros'} />}
            {searchBar && <Search fetchData={onChangeSearchBar} />}
          </div>
        )}
        <div className={!isTableMode ? 'elementView' : 'rightArea'}>
          {(addButton || editButton) && (
            <>
              {editButton ? (
                <CircleButton onClick={onClickEdit} alt={'modificar'} content={<IconEdit />} className="modificar btnDark" />
              ) : (
                <Link to={newElementRoute} className="btnAdd">
                  <CircleButton onClick={() => null} alt={'agregar'} content={<div>+</div>} />
                </Link>
              )}
            </>
          )}
          {deleteButton && <CircleButton onClick={onClickDelete} alt={'eliminar'} content={<IconDelete />} className="eliminar btnDark" />}
          {downloadButton && <CircleButton onClick={onClickDownload} alt={'descargar-csv'} content={<div>Descargar CSV</div>} />}
          {onClickDownloadExcel && <CircleButton onClick={onClickDownloadExcel} alt={'descargar-excel'} content={<div>Descargar Excel</div>} />}
        </div>
      </div>
    </div>
  )
}
