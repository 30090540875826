import React from 'react';
const IconPrestamos = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 6.00003C12.328 6.00003 13 6.67203 13 7.50003C13 8.32803 12.328 9.00003 11.5 9.00003C10.672 9.00003 10 8.32803 10 7.50003C10 6.67203 10.672 6.00003 11.5 6.00003ZM5.5 3.00003C6.328 3.00003 7 2.32803 7 1.50003C7 0.672033 6.328 3.30242e-05 5.5 3.30242e-05C4.672 3.30242e-05 4 0.672033 4 1.50003C4 2.32803 4.672 3.00003 5.5 3.00003ZM23.236 13.015L16.431 20.652C14.535 22.78 11.814 24 8.965 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H12.858C13.997 11 14.996 11.609 15.547 12.518L18.763 8.98403C19.305 8.38903 20.045 8.04003 20.849 8.00303C21.657 7.96103 22.423 8.24303 23.017 8.78503C24.231 9.89203 24.329 11.789 23.236 13.014V13.015ZM21.67 10.264C21.471 10.082 21.211 9.99703 20.942 10.002C20.672 10.015 20.424 10.131 20.242 10.331L15.816 15.195C15.431 16.266 14.477 17.086 13.302 17.254L8.141 17.991C7.596 18.07 7.088 17.69 7.01 17.143C6.932 16.596 7.312 16.09 7.858 16.012L13.019 15.275C13.578 15.195 14 14.709 14 14.144C14 13.514 13.488 13.002 12.858 13.002H4C2.897 13.002 2 13.899 2 15.002V20.002C2 21.105 2.897 22.002 4 22.002H8.965C11.244 22.002 13.422 21.026 14.938 19.324L21.743 11.686C22.11 11.274 22.077 10.637 21.67 10.265V10.264ZM5.71 8.84203C5.877 8.94903 6.064 9.00103 6.25 9.00103C6.579 9.00103 6.901 8.83903 7.092 8.54203L11.591 1.54103C11.889 1.07703 11.755 0.458033 11.29 0.159033C10.827 -0.139967 10.207 -0.00496697 9.908 0.460033L5.408 7.46003C5.11 7.92403 5.245 8.54303 5.71 8.84203Z"
        fill="white"
      />
    </svg>
  );
};
export default IconPrestamos;
