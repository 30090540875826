import React from 'react';
import ActionButton from '../ActionButton';
import SearchBar from '../SearchBar';
import CircleButton from '../CircleButton';
import { Link } from 'react-router-dom';
import IconEdit from '../../../assets/iconEdit';
import IconDelete from '../../../assets/iconDelete';

export type TopContentType = {
  title: string;
  displayElements: {
    filtersButton: boolean;
    searchBar: boolean;
    addButton: boolean;
    editButton: boolean;
    downloadButton: boolean;
    deleteButton?: boolean;
  };
  onClickOpenFilters: () => void;
  onChangeSearchBar: (searchValue: string) => void;
  newElementRoute: string;
  onClickDownload: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickDownloadExcel?: () => void;
};

const TopContent = (props: TopContentType) => {
  const {
    title,
    onClickOpenFilters,
    onChangeSearchBar,
    newElementRoute,
    onClickDownload,
    onClickEdit,
    onClickDelete,
    onClickDownloadExcel,
    displayElements: { addButton, editButton, filtersButton, searchBar, downloadButton, deleteButton },
  } = props;

  const isTableMode = filtersButton || searchBar;

  return (
    <div className={`topContent ${!isTableMode ? 'rowView' : ''}`}>
      <h1>{title}</h1>
      <div className="topContent__wrapper">
        {isTableMode && (
          <div className="leftArea">
            {filtersButton && <ActionButton onClick={onClickOpenFilters} text={'Filtros'} alt={'Filtros'} />}
            {searchBar && <SearchBar onChange={onChangeSearchBar} placeholder={'Buscar...'} />}
          </div>
        )}
        <div className={!isTableMode ? 'elementView' : 'rightArea'}>
          {(addButton || editButton) && (
            <>
              {editButton ? (
                <CircleButton onClick={onClickEdit} alt={'modificar'} content={<IconEdit />} className="modificar btnDark" />
              ) : (
                <Link to={newElementRoute} className="btnAdd">
                  <CircleButton onClick={() => null} alt={'agregar'} content={<div>+</div>} />
                </Link>
              )}
            </>
          )}
          {deleteButton && <CircleButton onClick={onClickDelete} alt={'eliminar'} content={<IconDelete />} className="eliminar btnDark" />}
          {downloadButton && <CircleButton onClick={onClickDownload} alt={'descargar-csv'} content={<div>Descargar CSV</div>} />}
          {onClickDownloadExcel && <CircleButton onClick={onClickDownloadExcel} alt={'descargar-excel'} content={<div>Descargar Excel</div>} />}
        </div>
      </div>
    </div>
  );
};

export default TopContent;
