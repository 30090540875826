import React from 'react';
const IconUser = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6ZM12 3C10.3431 3 9 4.34315 9 6C9 7.65685 10.3431 9 12 9C13.6569 9 15 7.65685 15 6C15 4.34315 13.6569 3 12 3Z"
        fill="white"
      />
      <path
        d="M4.06189 21C4.55399 17.0537 7.92038 14 12 14C12.4302 14 12.8517 14.0339 13.2623 14.0989L14.25 14.2553L14.5628 12.2799L13.5751 12.1235C13.0614 12.0421 12.5353 12 12 12C6.47715 12 2 16.4772 2 22V23H14.1908V21H4.06189Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 13.5405L19.7284 14.315C20.2616 14.8819 20.8969 15.1668 21.625 15.1668H23V17.398C23 19.9615 21.5185 22.2757 19.2924 22.9564L19 23.0458L18.7076 22.9564C16.4815 22.2757 15 19.9615 15 17.398L15.0001 15.1668H16.375C17.1032 15.1668 17.7383 14.8819 18.2715 14.315L19 13.5405ZM17 17.125V17.398C17 19.1126 17.9029 20.4398 19 20.9335C20.0971 20.4398 21 19.1126 21 17.398V17.1254C20.2636 17.0255 19.5905 16.7487 19 16.3254C18.4095 16.7487 17.7364 17.0251 17 17.125Z"
        fill="white"
      />
    </svg>
  );
};
export default IconUser;
