import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login/loginSlice';
import clientSlice from './clients/clientSlice';
import operationSlice from './operations/operationSlice';
import checkSlice from './checks/checkSlice';
import drawerSlice from './drawers/drawerSlice';
import cessionSlice from './cessions/cessionSlice';
import loansSlice from './loans/loansSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    client: clientSlice,
    operation: operationSlice,
    check: checkSlice,
    drawer: drawerSlice,
    cession: cessionSlice,
    loan: loansSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
