import { Drawer } from '../../../types/drawer';

export const TableHeaders = {
  ID: 'ID',
  NAME: 'Nombre',
  RUT: 'RUT',
  LEGAL_NAME: 'Razón Social',
  TRADE_NAME: 'Nombre Comercial',
};

export const TableHeaderToDrawerProperty: Record<string, keyof Drawer> = {
  ID: '_id',
  NAME: 'name',
  RUT: 'rut',
  LEGAL_NAME: 'legalName',
  TRADE_NAME: 'tradeName',
};
