import React, { useEffect, useState } from 'react';

import { Document, Page } from 'react-pdf';
import PuffLoader from 'react-spinners/PuffLoader';
import { Cession as CessionType, DocumentsType } from '../../../types/cession';
import ActionButton from '../../components/ActionButton';
import axios from 'axios';
import './styles.css';
import { emptyCession } from './utils';

type CessionImagesType = {
  cession: CessionType;
  setSelectedImage: (file: string | null) => void;
  isEditing: boolean;
  setDocuments: (documents: [File, string][]) => void;
  documents: [File, string][];
  selectedImage: string | null;
  setDeletedOldDocuments: (document: string) => void;
  oldDocuments: DocumentsType[];
};

const CessionImages = ({
  cession,
  setSelectedImage,
  isEditing,
  setDocuments,
  documents,
  selectedImage,
  setDeletedOldDocuments,
  oldDocuments,
}: CessionImagesType) => {
  const [docs, setDocs] = useState<any[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [newDocument, setNewDocument] = useState<File | null>();
  const [newDocumentFriendly, setNewDocumentFriendly] = useState('');

  useEffect(() => {
    if (cession['_id']) {
      const bucketname = 'descontalo';

      const getURL = async () => {
        const documents: DocumentsType[] = [];
        for (const d of cession.documents || []) {
          const fileId = d.url.replace('bucket/s3/', '');
          const endpoint = `https://descontalo-back-fef14f23ad5d.herokuapp.com/api/generate-presigned-url?key=${encodeURIComponent(fileId)}`;
          const newUrl = (
            await axios.get(endpoint, {
              headers: { bucketName: bucketname },
            })
          ).data;
          documents.push({ name: d.name, url: newUrl, friendlyName: d.friendlyName });
        }
        setDocs(documents);
        setLoadingDocuments(false);
      };
      if (cession != emptyCession) {
        getURL();
      }
    }
  }, [cession.documents]);

  const deleteDocument = (document: File) => {
    const newDocuments = documents.filter((toDelete: [File, string]) => document !== toDelete[0]);
    setDocuments(newDocuments);
  };

  const handleAddDocument = () => {
    const documentToAdd: [File, string] = [newDocument as File, newDocumentFriendly];
    const newDocuments = documents;
    newDocuments.push(documentToAdd);
    setDocuments(newDocuments);
    setNewDocument(null);
  };

  useEffect(() => {
    if (newDocument) {
      setNewDocumentFriendly(newDocument.name);
    }
  }, [newDocument])

  return (
    <>
      <div className="formRegister form">
        <div className="formRegister__wrapper">
          <h1>Documentos</h1>
          {loadingDocuments && cession._id ? (
            <PuffLoader color="#3D9FE0" />
          ) : (
            <div>
              <div className="checksImage">
                <div className="checksImage__wrapper">

                  {!isEditing &&
                    <div>
                      {docs.map((document) => (
                        <div key={document.name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                          <a target="_blank" style={{ margin: '5px', color: 'blue', textDecoration: 'underline' }} href={document.url}>{document.friendlyName ?? document.name}</a>
                        </div>
                      ))}
                    </div>
                  }

                  {!(!isEditing && !!cession['_id']) && (
                    <div>
                      <label>Agregar Archivo:</label>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <input type="file" onChange={(e) => e.target.files && setNewDocument(e.target.files[0])} />
                        {newDocument &&
                          <input
                            type="text"
                            value={newDocumentFriendly}
                            onChange={(e) =>
                              setNewDocumentFriendly(e.target.value)
                            }
                          />
                        }
                        <button
                          style={{
                            backgroundColor: '#3D9FE0',
                            color: 'white',
                            borderRadius: '5px',
                            padding: '5px 15px 5px 15px',
                            marginBottom: '20px',
                          }}
                          disabled={!newDocument}
                          onClick={handleAddDocument}
                        >
                          +
                        </button>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <label> Archivos:</label>
                        {documents.map((document) => (
                          <div key={document[0].name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <b>{document[1]}</b>
                            <button
                              style={{
                                backgroundColor: '#3D9FE0',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 15px 5px 15px',
                              }}
                              onClick={() => deleteDocument(document[0])}
                            >
                              Eliminar
                            </button>
                          </div>
                        ))}
                        {oldDocuments.map((document) => (
                          <div key={document.name} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <b>{document.friendlyName ?? document.name}</b>
                            <button
                              style={{
                                backgroundColor: '#3D9FE0',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 15px 5px 15px',
                              }}
                              onClick={() => setDeletedOldDocuments(document.url)}
                            >
                              Eliminar
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedImage ? (
        <div className="imageViewer" onClick={() => setSelectedImage(null)}>
          <img className="modalContent" src={selectedImage} />
          <div className="openFileContainer">
            <ActionButton
              onClick={() => window.open(selectedImage, '_blank')}
              text={'Abrir archivo en nueva ventana'}
              alt={'Abrir archivo'}
            ></ActionButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CessionImages;
