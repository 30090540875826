import React from 'react';
import { Drawer } from '../../../types/drawer';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';

export const formatDataToTable = (drawers: Drawer[]): DataTableType[] => {
  return drawers.reduce((accumulator: DataTableType[], drawer: Drawer) => {
    const value: DataTableType = {
      [TableHeaders.ID]: drawer._id,
      [TableHeaders.NAME]: <div>{drawer.name || '-'}</div>,
      [TableHeaders.RUT]: <div>{drawer.rut || '-'}</div>,
      [TableHeaders.LEGAL_NAME]: <div>{drawer.legalName || '-'}</div>,
      [TableHeaders.TRADE_NAME]: <div>{drawer.tradeName || '-'}</div>,
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    name: 'Nombre',
    rut: 'RUT',
    legalName: 'Razón Social',
    tradeName: 'Nombre Comercial',
    creationDate: 'Fecha de Creación',
  };
  return labels[key] || key;
};
