import React from 'react';
import { DataTableType } from '../../components/Table';
import { TableHeaders } from './consts';
import { Cession } from '../../../types/cession';

export const formatDataToTable = (cessions: Cession[]): DataTableType[] => {
  return cessions.reduce((accumulator: DataTableType[], cession: Cession) => {
    const value: DataTableType = {
      [TableHeaders.ID]: cession._id,
      [TableHeaders.CLIENT]: <div>{cession.client?.name || '-'}</div>,
      [TableHeaders.ENTITY]: <div>{cession.entity || '-'}</div>,
      [TableHeaders.STATE]: cession.state,
      [TableHeaders.EXPIRATION]: <div>{new Date(cession.proposedExpiration?.toString() || '').toLocaleDateString('es-UY') || '-'}</div>,
      [TableHeaders.AMOUNT]: (
        <div style={{ textAlign: 'end' }}>
          {cession.currency}{' '}
          {typeof cession.advanceAmount === 'number'
            ? parseFloat(cession.advanceAmount?.toString() || '')
                .toFixed(2)
                .replace(/\./g, '#')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                .replace(/,/g, '.')
                .replace(/#/g, ',')
            : '-'}
        </div>
      ),
    };
    return [...accumulator, value];
  }, []);
};

export const csvHeaderAdapt = (key: string): string => {
  const labels: { [key: string]: string } = {
    _id: 'ID',
    clientId: 'ID Cliente',
    operationId: 'Id Operacion',
    associatedInvoice: 'Factura Asociada',
    entity: 'Ente',
    advanceDate: 'Fecha Adelanto',
    currency: 'Moneda',
    advanceAmount: 'Monto Adelanto',
    proposedExpiration: 'Vencimiento Propuesto',
    agreedRate: 'Tasa Acordada',
    observations: 'Observaciones',
    processedBy: 'Procesado Por',
    channeledBy: 'Canalizado Por',
    creationDate: 'Fecha de Creación',
  };
  return labels[key] || key;
};
