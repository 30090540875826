export const banks = [
  {
    name: 'BROU',
  },
  {
    name: 'BHU',
  },
  {
    name: 'Santander',
  },
  {
    name: 'Heritage',
  },
  {
    name: 'BBVA',
  },
  {
    name: 'Itaú',
  },
  {
    name: 'Citibank',
  },
  {
    name: 'HSBC',
  },
  {
    name: 'Scotiabank',
  },
  {
    name: 'Bandes',
  },
  {
    name: 'Otro',
  },
];

export const currencies = [
  {
    name: 'UYU',
  },
  {
    name: 'USD',
  },
];

export const accountTypes = [
  {
    name: 'CC',
  },
  {
    name: 'CA',
  },
];
