import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { Client } from '../../types/client';
import { RootState } from '../store';
import { toast } from 'react-toastify';

interface ClientState {
  clients: Client[];
  clientsV2: Client[] | undefined;
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: ClientState = {
  clients: [],
  clientsV2: undefined,
  isLoading: false,
  error: null,
  success: false,
};

export const fetchClientsV2 = createAsyncThunk('v2/clients', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/clients`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the clients.');
  }
});

export const fetchClients = createAsyncThunk('clients', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the clients.');
  }
});

export const createClient = createAsyncThunk(
  'clients/create',
  async ({ clientData, token }: { clientData: Client; token: string | undefined | null }) => {
    try {
      const filteredClientData: Partial<Client> = Object.fromEntries(
        Object.entries(clientData).filter(([_, value]) => value !== '' && value !== null),
      );
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/clients/create`, filteredClientData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error.message);
        return Promise.reject(error.response.data.error.message);
      }
      toast.error('Hubo un error al crear el cliente');
      return Promise.reject('Hubo un error al crear el cliente');
    }
  },
);

export const deleteClient = createAsyncThunk('clients/delete', async ({ _id, token }: { _id: string; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/clients/delete?_id=${_id}`, { headers });
    return _id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.error.message);
      return Promise.reject(error.response.data.error.message);
    }
    toast.error('Hubo un error al eliminar el cliente');
    return Promise.reject('An error occurred while trying to delete the client.');
  }
});

export const updateClient = createAsyncThunk('clients/update', async ({ clientData, token }: { clientData: Client; token: string }) => {
  try {
    const filteredClientData: Partial<Client> = Object.fromEntries(Object.entries(clientData).filter(([_, value]) => value !== '' && value !== null));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/clients/update?_id=${clientData._id}`, filteredClientData, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      toast.error(error.response.data.error.message);
      return Promise.reject(error.response.data.error.message);
    }
    toast.error('Hubo un error al crear el cliente');
    return Promise.reject('An error occurred while trying to update the client.');
  }
});

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsV2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.clientsV2 = action.payload.data.clients;
      })
      .addCase(fetchClientsV2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchClientsV2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch clients';
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.clients = action.payload.data.clients;
      })
      .addCase(fetchClients.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch clients';
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.clients.unshift(action.payload.data.client);
        state.success = true;
      })
      .addCase(createClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create client';
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        const { _id } = action.payload.data.client;
        const index = state.clients.findIndex((client) => client._id === _id);
        if (index !== -1) {
          state.clients[index] = { ...state.clients[index], ...action.payload.data.client };
        }
      })
      .addCase(updateClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update client';
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.clients = state.clients.filter((client) => client._id !== action.meta.arg._id);
      })
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete client';
      });
  },
});

export default clientSlice.reducer;

export const { clearState } = clientSlice.actions;
