import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Client } from '../../../types/client';
import Table from '../../components/Table';
import { Operation as OperationType } from '../../../types/operation';
import { TableHeaders as TableHeadersCheck } from '../Checks/consts';
import { formatDataToTable } from '../Checks/utils';
import { TableHeaders as TableHeadersLoan } from '../Loans/consts';
import { formatDataToTable as formatDataToTableLoan } from '../Loans/utils';
import { TableHeaders as TableHeadersCession } from '../Cessions/consts';
import { formatDataToTable as formatDataToTableCession } from '../Cessions/utils';
import { PuffLoader } from 'react-spinners';

type ClientOperationsType = {
  client: Client;
  operations?: OperationType[]
};

const ClientOperations = ({ client, operations }: ClientOperationsType) => {
  const navigate = useNavigate();

  return (
    <div className="formRegister__wrapper clientOperations">
      <h1>Operaciones en curso</h1>

      {operations === undefined &&
        <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <PuffLoader color="#3D9FE0" />
        </div>
      }

      {operations?.map(o =>
        <div style={{ borderBottom: 'solid grey 1px', padding: '10px' }}>
          {o.operationType === 'cheque' && <>
            <div style={{ display: 'flex' }}>
              <h2 style={{ marginRight: '20px' }}>{o._id?.slice(-6).toString()} - Cheques</h2>
              <span className='btnOperations' onClick={() => navigate('/operaciones/' + o._id)}>Ver operación</span>
            </div>
            <Table
              headers={Object.values(TableHeadersCheck).filter((header) => header !== TableHeadersCheck.CLIENT)}
              data={o.checks ? formatDataToTable(o.checks) : []}
              onRowClick={(id) => navigate('/cheques/' + id)}
              showDelete={false}
              showId={true}
            /></>}
          {o.operationType === 'prestamo' && <>
            <div style={{ display: 'flex' }}>
              <h2 style={{ marginRight: '20px' }}>{o._id?.slice(-6).toString()} - Prestamos</h2>
              <span className='btnOperations' onClick={() => navigate('/operaciones/' + o._id)}>Ver operación</span>
            </div>
            <Table
              headers={Object.values(TableHeadersLoan).filter((header) => header !== TableHeadersLoan.CLIENT)}
              data={o.loans ? formatDataToTableLoan(o.loans) : []}
              onRowClick={(id) => navigate('/prestamos/' + id)}
              showDelete={false}
              showId={true}
            /></>}
          {o.operationType === 'cesion' && <>
            <div style={{ display: 'flex' }}>
              <h2 style={{ marginRight: '20px' }}>{o._id?.slice(-6).toString()} - Cesiones</h2>
              <span className='btnOperations' onClick={() => navigate('/operaciones/' + o._id)}>Ver operación</span>
            </div>
            <Table
              headers={Object.values(TableHeadersCession).filter((header) => header !== TableHeadersCession.CLIENT)}
              data={o.cessions ? formatDataToTableCession(o.cessions) : []}
              onRowClick={(id) => navigate('/cesiones/' + id)}
              showDelete={false}
              showId={true}
            /></>}
        </div>
      )}

    </div>
  );
};

export default ClientOperations;
