import React, { ReactElement } from 'react';

export type CircleButtonType = {
  onClick?: () => void;
  content: ReactElement;
  alt: string;
  className?: string;
};

const CircleButton = (props: CircleButtonType) => {
  const { onClick, content, alt, className } = props;
  return (
    <button onClick={onClick} aria-label={alt} className={className ? className : 'btnDark'}>
      {content}
    </button>
  );
};

export default CircleButton;
