import { User } from '../../../types/user';

export const TableHeaders = {
  ID: 'ID',
  EMAIL: 'Email',
  NAME: 'Name',
};

export const TableHeaderToUserProperty: Record<string, keyof User> = {
  ID: '_id',
  NAME: 'name',
  EMAIL: 'email',
};
