import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { Drawer } from '../../types/drawer';

interface DrawerState {
  drawers: Drawer[];
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: DrawerState = {
  drawers: [],
  isLoading: false,
  error: null,
  success: false,
};

export const fetchDrawers = createAsyncThunk('drawers', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/drawers`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the drawers.');
  }
});

export const createDrawer = createAsyncThunk(
  'drawers/create',
  async ({ drawerData, token }: { drawerData: Drawer; token: string | undefined | null }) => {
    try {
      const filteredDrawerData: Partial<Drawer> = Object.fromEntries(
        Object.entries(drawerData).filter(([_, value]) => value !== '' && value !== null),
      );
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/drawers/create`, filteredDrawerData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data.error.message);
      }
      return Promise.reject('An error occurred while trying to create the drawer.');
    }
  },
);

export const deleteDrawer = createAsyncThunk('drawers/delete', async ({ _id, token }: { _id: string; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/drawers/delete?id=${_id}`, { headers });
    return _id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to delete the drawer.');
  }
});

export const updateDrawer = createAsyncThunk('drawers/update', async ({ drawerData, token }: { drawerData: Drawer; token: string }) => {
  try {
    const filteredDrawerData: Partial<Drawer> = Object.fromEntries(Object.entries(drawerData).filter(([_, value]) => value !== '' && value !== null));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/drawers/update?id=${drawerData._id}`, filteredDrawerData, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to update the drawer.');
  }
});

const drawerSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrawers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.drawers = action.payload.data.drawers;
      })
      .addCase(fetchDrawers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDrawers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch drawers';
      })
      .addCase(createDrawer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.drawers.unshift(action.payload.data.drawer);
        state.success = true;
      })
      .addCase(createDrawer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createDrawer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create drawer';
      })
      .addCase(updateDrawer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        const { _id } = action.payload.data.drawer;
        const index = state.drawers.findIndex((drawer) => drawer._id === _id);
        if (index !== -1) {
          state.drawers[index] = { ...state.drawers[index], ...action.payload.data.drawer };
        }
      })
      .addCase(updateDrawer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateDrawer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update drawer';
      })
      .addCase(deleteDrawer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.drawers = state.drawers.filter((drawer) => drawer._id !== action.meta.arg._id);
      })
      .addCase(deleteDrawer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteDrawer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete drawer';
      });
  },
});

export default drawerSlice.reducer;

export const { clearState } = drawerSlice.actions;
