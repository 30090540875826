import React from 'react';

export type ActionButtonType = {
  onClick: () => void;
  text: string;
  alt: string;
  disabled?: boolean;
  className?: string;
};

const ActionButton = (props: ActionButtonType) => {
  const { onClick, text, alt, disabled, className } = props;
  return (
    <button className={`actionButton ${className || ''}`} onClick={disabled ? () => null : onClick} aria-label={alt} disabled={disabled}>
      {text}
    </button>
  );
};

export default ActionButton;
