import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Operation } from '../../types/operation';

interface OperationState {
  operations: Operation[];
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: OperationState = {
  operations: [],
  isLoading: false,
  error: null,
  success: false,
};

export const fetchOperations = createAsyncThunk('operations', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/operations`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the operations.');
  }
});

export const createOperation = createAsyncThunk('operation/create', async ({ operationData, token }: { operationData: Operation; token: string }) => {
  try {
    const filteredOperationData: Partial<Operation> = Object.fromEntries(
      Object.entries(operationData).filter(([_, value]) => value !== '' && value !== null),
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/operations/create`, filteredOperationData, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to create the operation.');
  }
});

export const deleteOperation = createAsyncThunk('operations/delete', async ({ id, token }: { id: string; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/operations/delete?id=${id}`, { headers });
    return id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to delete the operation.');
  }
});

export const updateOperation = createAsyncThunk(
  'operations/update',
  async ({ id, operationData, token }: { id: string | undefined; operationData: Operation; token: string }) => {
    try {
      const filteredOperationData: Partial<Operation> = Object.fromEntries(
        Object.entries(operationData).filter(([_, value]) => value !== '' && value !== null),
      );
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/operations/update?id=${id}`, filteredOperationData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data.error.message);
      }
      return Promise.reject('An error occurred while trying to update the client.');
    }
  },
);

const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.operations = action.payload.data.operations;
      })
      .addCase(fetchOperations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOperations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch operations';
      })
      .addCase(createOperation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.operations.unshift(action.payload.data.operation);
        console.log('operations', state.operations);
        state.success = true;
      })
      .addCase(createOperation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createOperation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create operation';
      })
      .addCase(updateOperation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { _id } = action.payload.data.operation;
        const index = state.operations.findIndex((operation) => operation._id === _id);
        if (index !== -1) {
          state.operations[index] = { ...state.operations[index], ...action.payload.data.operation };
        }
        state.success = true;
      })
      .addCase(updateOperation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateOperation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update operation';
      })
      .addCase(deleteOperation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.operations = state.operations.filter((operation) => operation._id !== action.meta.arg.id);
      })
      .addCase(deleteOperation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteOperation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete operation';
      });
  },
});

export default operationSlice.reducer;

export const { clearError } = operationSlice.actions;
