import React, { useEffect, useState } from 'react';
import { Drawer as DrawerType, emptyDrawer } from '../../../types/drawer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate, useParams } from 'react-router';
import { clearState, createDrawer, deleteDrawer, fetchDrawers, updateDrawer } from '../../../services/drawers/drawerSlice';
import { DRAWERS_ROUTE } from '../../../utils/routes';
import ConfirmationModal from '../../components/ConfirmationModal';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import DrawerForm from './drawerForm';
import { PuffLoader } from 'react-spinners';
import Table from '../../components/Table';

import { TableHeaders as TableHeadersCheck } from '../Checks/consts';
import { formatDataToTable } from '../Checks/utils';
import axios from 'axios';
import { Check as CheckType } from '../../../types/check';

const Drawer = () => {
  const [drawer, setDrawer] = useState<DrawerType>(emptyDrawer);
  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.drawer.isLoading);
  const error = useSelector((state: RootState) => state.drawer.error);
  const success = useSelector((state: RootState) => state.drawer.success);
  const token = localStorage.getItem('token');
  const drawers = useSelector((state: RootState) => state.drawer.drawers);
  const { id } = useParams();
  const navigate = useNavigate();

  const [checks, setChecks] = useState<CheckType[]>([]);

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: !!drawer['_id'],
    deleteButton: drawer['_id'] !== undefined,
  };

  useEffect(() => {
    dispatch(clearState());
    if (id !== undefined) {
      setIsFetching(true);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      axios.get(`${process.env.REACT_APP_API_URL}/drawers/${id}/checks`, { headers })
        .then(res => {
          setChecks(res.data.data.checks);
        }).catch(err => console.log('error al cargar operaciones'));

      const foundDrawer = drawers.find((drawer: DrawerType) => drawer._id === id);
      if (!foundDrawer) {
        dispatch(fetchDrawers(token || ''));
      }
      foundDrawer && setDrawer(foundDrawer);
    }
  }, []);

  useEffect(() => {
    const foundDrawer = drawers.find((drawer: DrawerType) => drawer._id === id);
    foundDrawer && setDrawer(foundDrawer);
    setIsFetching(false);
  }, [drawers]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDrawer((previousDrawer) => ({
      ...previousDrawer,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (drawer['_id']) {
      dispatch(updateDrawer({ drawerData: drawer, token: token || '' }));
    } else {
      dispatch(createDrawer({ drawerData: drawer, token: token }));
    }
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteDrawer({ _id: drawer?._id || '', token: token || '' }));
      navigate(DRAWERS_ROUTE);
    }
  };

  const handleEditButton = () => {
    setIsEditing((prevState) => !prevState);
  };

  useEffect(() => {
    if (success && !isEditing) {
      navigate(DRAWERS_ROUTE);
    }
  }, [success]);

  return isFetching || isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="drawerManagement">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={deleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar a ${drawer?.name}?`}
        />
        <BackButton />
        <TopContent
          title={!drawer['_id'] ? 'Registrar Librador' : isEditing ? 'Editar Librador' : drawer['name']}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={handleEditButton}
          onClickDelete={() => setDeleteModalOpen(true)}
        />
      </div>
      <DrawerForm
        drawer={drawer}
        handleChange={handleChange}
        isEditing={isEditing}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        error={error}
        success={success}
      />

      {checks.length > 0 && <OperationsTable checks={checks} />}
    </div>
  );
};


export default Drawer;

const OperationsTable = ({ checks }: { checks: CheckType[] }) => {

  const navigate = useNavigate();

  return (
    <div className="formRegister__wrapper clientOperations">
      <h1>Cheques</h1>

      <div style={{ borderBottom: 'solid grey 1px', padding: '10px' }}>
        <Table
          headers={Object.values(TableHeadersCheck).filter((header) => header !== TableHeadersCheck.CLIENT)}
          data={checks ? formatDataToTable(checks) : []}
          onRowClick={(id) => navigate('/cheques/' + id)}
          showDelete={false}
          showId={true}
        />
      </div>
    </div>
  )
}
