import React, { ReactElement } from 'react';
import ActionButton from '../ActionButton';

export type ConfirmationModalType = {
  onRequestClose: () => void;
  onClickAccept: () => void;
  isOpen: boolean;
  contentLabel: string;
};

const ConfirmationModal = (props: ConfirmationModalType) => {
  const { onRequestClose, onClickAccept, isOpen, contentLabel } = props;
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-overlay-wrapper">
            <div className="modal">
              <h2>Confirmación</h2>
              <div className="modal-content">{contentLabel}</div>
              <div className="modal-buttons">
                <ActionButton onClick={() => onRequestClose()} text="Cancelar" alt="Cancel Button" />
                <ActionButton onClick={() => onClickAccept()} text="Aceptar" alt="Accept Button" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
