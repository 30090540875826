import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './ui/views/Login';
import Home from './ui/views/Home';
import Clients from './ui/views/Clients';
import SideBar from './ui/components/SideBar';
import Authorization from './HOC/authorization';
import {
  CLIENTS_ROUTE,
  NEW_CLIENT_ROUTE,
  NEW_USER_ROUTE,
  USERS_ROUTE,
  OPERATIONS_ROUTE,
  EDIT_CLIENT_ROUTE,
  EDIT_USER_ROUTE,
  NEW_CHECK_ROUTE,
  EDIT_CHECK_ROUTE,
  CHECKS_ROUTE,
  NEW_OPERATION_ROUTE,
  EDIT_OPERATION_ROUTE,
  DRAWERS_ROUTE,
  NEW_DRAWER_ROUTE,
  EDIT_DRAWER_ROUTE,
  CESSIONS_ROUTE,
  NEW_CESSIONS_ROUTE,
  EDIT_CESSIONS_ROUTE,
  LOGIN_ROUTE,
  LOANS_ROUTE,
  NEW_LOANS_ROUTE,
  EDIT_LOANS_ROUTE,
  VERSIONS_ROUTE,
} from './utils/routes';
import Users from './ui/views/Users';
import User from './ui/views/User';
import Operation from './ui/views/Operation';
import Client from './ui/views/Client';
import Operations from './ui/views/Operations';
import Check from './ui/views/Check';
import Checks from './ui/views/Checks';
import Drawers from './ui/views/Drawers';
import Drawer from './ui/views/Drawer';
import Cessions from './ui/views/Cessions';
import Cession from './ui/views/Cession';
import Loans from './ui/views/Loans';
import Loan from './ui/views/Loan';
import { ToastContainer } from 'react-toastify';
import Versions from './ui/views/Versions';

function App() {
  return (
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route
        path={'/*'}
        element={
          <div className="wrapper">
            <SideBar />
            <div className="content">
              <Routes>
                <Route index element={<Authorization element={<Clients />} />} />
                <Route path={CLIENTS_ROUTE} element={<Authorization element={<Clients />} />} />
                <Route path={USERS_ROUTE} element={<Authorization element={<Users />} />} />
                <Route path={NEW_USER_ROUTE} element={<Authorization element={<User />} />} />
                <Route path={EDIT_USER_ROUTE} element={<Authorization element={<User />} />} />
                <Route path={OPERATIONS_ROUTE} element={<Authorization element={<Operations />} />} />
                <Route path={NEW_OPERATION_ROUTE} element={<Authorization element={<Operation />} />} />
                <Route path={EDIT_OPERATION_ROUTE} element={<Authorization element={<Operation />} />} />
                <Route path={NEW_CLIENT_ROUTE} element={<Authorization element={<Client />} />} />
                <Route path={EDIT_CLIENT_ROUTE} element={<Authorization element={<Client />} />} />
                <Route path={CHECKS_ROUTE} element={<Authorization element={<Checks />} />} />
                <Route path={NEW_CHECK_ROUTE} element={<Authorization element={<Check />} />} />
                <Route path={EDIT_CHECK_ROUTE} element={<Authorization element={<Check />} />} />
                <Route path={DRAWERS_ROUTE} element={<Authorization element={<Drawers />} />} />
                <Route path={NEW_DRAWER_ROUTE} element={<Authorization element={<Drawer />} />} />
                <Route path={EDIT_DRAWER_ROUTE} element={<Authorization element={<Drawer />} />} />
                <Route path={CESSIONS_ROUTE} element={<Authorization element={<Cessions />} />} />
                <Route path={NEW_CESSIONS_ROUTE} element={<Authorization element={<Cession />} />} />
                <Route path={EDIT_CESSIONS_ROUTE} element={<Authorization element={<Cession />} />} />
                <Route path={LOANS_ROUTE} element={<Authorization element={<Loans />} />} />
                <Route path={NEW_LOANS_ROUTE} element={<Authorization element={<Loan />} />} />
                <Route path={EDIT_LOANS_ROUTE} element={<Authorization element={<Loan />} />} />
                <Route path={VERSIONS_ROUTE} element={<Authorization element={<Versions />} />} />
              </Routes>
            </div>
            <ToastContainer autoClose={4000} />
          </div>
        }
      />
    </Routes>
  );
}

export default App;
