import React from 'react';

import FormInput from '../../components/FormInput';
import Dropdown from '../../components/Dropdown';
import DatePicker from '../../components/DatePicker';
import { ClientSection } from './types';

const ClientInternal = ({ client, handleChange, inputsDisabled }: ClientSection) => {
  return (
    <div className="formRegister__wrapper">
      <h1>Internos</h1>
      <div className="formRegister__inputArea">
        <Dropdown
          options={[
            { value: 'good', label: 'Bueno' },
            { value: 'bad', label: 'Malo' },
          ]}
          name={'clientRating'}
          value={client['clientRating'] ? client['clientRating']?.toString() : ''}
          label={'Clasificación Cliente'}
          onChange={handleChange}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'baseOrigin'}
          value={client['baseOrigin'] ? client['baseOrigin']?.toString() : ''}
          label={'Origen Base'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <Dropdown
          options={[
            { value: 'whatsApp', label: 'WhatsApp' },
            { value: 'cliengo', label: 'Cliengo' },
            { value: 'referidoSocioDescontalo', label: 'Referido Socio Descontalo' },
            { value: 'referidoPersonalDescontalo', label: 'Referido Personal Descontalo' },
            { value: 'referidoOtroCliente', label: 'Referido Otro Cliente' },
            { value: 'referidoOtros', label: 'Referido Otros' },
            { value: 'baseDatosInternet', label: 'Base de Datos Internet' },
            { value: 'baseDatosOtros', label: 'Base de Datos Otros' },
            { value: 'bcuCuentasClausuradas', label: 'BCU Cuentas Clausuradas' },
            { value: 'otroOrigen', label: 'Otro Origen' },
          ]}
          name={'contactOrigin'}
          value={client['contactOrigin'] ? client['contactOrigin']?.toString() : ''}
          label={'Contacto de Origen'}
          onChange={handleChange}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'extraContactOrigin'}
          value={client['extraContactOrigin'] ? client['extraContactOrigin']?.toString() : ''}
          label={'Contacto de origen(extra)'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        {/* TODO: hacer qeu esto se vea bien con lso fields que tiene */}
        <FormInput
          name={'logsQuantity'}
          value={client['logsQuantity'] ? client['logsQuantity']?.toString() : ''}
          label={'Cantidad de Logs'}
          onChange={handleChange}
          type={'number'}
          disabled={true}
          placeholder={!client['logsQuantity'] ? 'Campo autogenerable' : ''}
        />
        <FormInput
          name={'usedProducts'}
          value={client['usedProducts'] ? client['usedProducts']?.toString() : ''}
          label={'Productos utilizados'}
          onChange={handleChange}
          type={'string'}
          disabled={true}
          placeholder={!client['usedProducts'] ? 'Campo autogenerable' : ''}
        />
        <span className="dropDownInternal">
          <Dropdown
            options={[
              { value: 'active', label: 'Activo' },
              { value: 'non-active', label: 'Inactivo' },
              { value: 'cancelled', label: 'Cancelado' },
              { value: 'potential', label: 'Potencial' },
              { value: 'recurring', label: 'Recurrente' },
            ]}
            name={'state'}
            value={client['state'] ? client['state']?.toString() : ''}
            label={'Estado'}
            onChange={handleChange}
            disabled={inputsDisabled}
          />
        </span>
        <FormInput
          name={'nextSteps'}
          value={client['nextSteps'] ? client['nextSteps']?.toString() : ''}
          label={'Pasos a seguir'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'fixedReferenceRate'}
          value={client['fixedReferenceRate'] ? client['fixedReferenceRate']?.toString() : ''}
          label={'Tasa fija de referencia'}
          onChange={handleChange}
          type={'number'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'amountTransacted'}
          value={client['amountTransacted'] ? client['amountTransacted']?.toString() : ''}
          label={'Monto total concretado'}
          onChange={handleChange}
          type={'number'}
          disabled={true}
          placeholder={!client['amountTransacted'] ? 'Campo autogenerable' : ''}
        />
        {!!client['_id'] && inputsDisabled && (
          <DatePicker
            name={'firstTransaction'}
            value={client['firstTransaction'] ? client['firstTransaction']?.toString() : ''}
            label={'Fecha Primera Transaccion'}
            onChange={handleChange}
            disabled={true}
          />
        )}
        <FormInput
          name={'completedTransactions'}
          value={client['completedTransactions'] ? client['completedTransactions']?.toString() : ''}
          label={'Operaciones concretadas'}
          onChange={handleChange}
          type={'number'}
          disabled={true}
          placeholder={!client['completedTransactions'] ? 'Campo autogenerable' : ''}
        />
        {!!client['_id'] && inputsDisabled && (
          <DatePicker
            name={'lastTransaction'}
            value={client['lastTransaction'] ? client['lastTransaction']?.toString() : ''}
            label={'Fecha Ultima Transaccion'}
            onChange={handleChange}
            disabled={true}
          />
        )}
      </div>
    </div>
  );
};

export default ClientInternal;
