import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { User } from '../../types/user';

export interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginState {
  token: string;
  isLoading: boolean;
  error: string | undefined | null;
  success: boolean;
  users: User[];
}

const initialState: LoginState = {
  token: '',
  isLoading: false,
  error: null,
  success: false,
  users: [],
};

export const login = createAsyncThunk('login', async (credentials: LoginCredentials) => {
  try {
    const { email, password } = credentials;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
    localStorage.setItem('token', response.data.data.token);
    localStorage.setItem('_id', response.data.data.user._id);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while logging in.');
  }
});

export const registerUser = createAsyncThunk(
  'register',
  async ({ registrationData, token }: { registrationData: User; token: string | undefined | null }) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, registrationData, {
        headers: headers,
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
      return Promise.reject('An error occurred while trying to create the new user.');
    }
  },
);

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch users.');
  }
});

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ userId, userData, token }: { userId: string | null | undefined; userData: User; token: string }) => {
    try {
      const filteredUserData: Partial<User> = Object.fromEntries(Object.entries(userData).filter(([_, value]) => value !== '' && value !== null));
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/update?id=${userId}`, filteredUserData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data.error.message);
      }
      return Promise.reject('An error occurred while trying to update the user.');
    }
  },
);

export const deleteUser = createAsyncThunk('users/delete', async ({ id, token }: { id: string; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete?id=${id}`, { headers });
    return id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to delete the client.');
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = '';
    },
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.data.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users.unshift(action.payload.data.user);
        state.success = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Registration failed';
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload.data.users;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        const updatedUser = action.payload.data.user;
        const index = state.users.findIndex((user) => user._id === updatedUser._id);
        if (index !== -1) {
          state.users[index] = updatedUser;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update user';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.users = state.users.filter((user) => user._id !== action.meta.arg.id);
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete client';
      });
  },
});

export const { logout, clearState } = loginSlice.actions;

export default loginSlice.reducer;
