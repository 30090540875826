import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../services/login/loginSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  CESSIONS_ROUTE,
  CHECKS_ROUTE,
  CLIENTS_ROUTE,
  LOGIN_ROUTE,
  DRAWERS_ROUTE,
  HOME_ROUTE,
  OPERATIONS_ROUTE,
  USERS_ROUTE,
  LOANS_ROUTE,
  VERSIONS_ROUTE,
  APP_VERSION,
} from '../../../utils/routes';
import ActionButton from '../ActionButton';
import IconHome from '../../../assets/iconHome';
import IconClient from '../../../assets/iconClient';
import IconUser from '../../../assets/iconUser';
import IconOperations from '../../../assets/iconOperations';
import IconLogout from '../../../assets/iconLogout';
import Logo from '../../../assets/img/logo-descontalo.png';
import IconCheques from '../../../assets/iconCheques';
import IconPrestamos from '../../../assets/iconPrestamos';
import IconCesiones from '../../../assets/iconCesiones';
import IconLibradores from '../../../assets/iconLibradores';

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('token');
    localStorage.removeItem('_id');
    navigate(LOGIN_ROUTE, { replace: true });
  };

  return (
    <div className="sidebar">
      <img className="logoSidebar" src={Logo} alt="" />
      {/* 
      <NavLink to={HOME_ROUTE} className="home sidebar__link">
        <IconHome></IconHome> Dashboard
      </NavLink>*/}
      <NavLink to={CLIENTS_ROUTE} className="home client sidebar__link">
        <IconClient></IconClient>Clientes
      </NavLink>
      <NavLink to={USERS_ROUTE} className="user sidebar__link">
        <IconUser></IconUser>Usuarios internos
      </NavLink>
      <NavLink to={DRAWERS_ROUTE} className="user sidebar__link">
        <IconLibradores></IconLibradores>Libradores
      </NavLink>
      <div className="subLinks">
        <NavLink to={OPERATIONS_ROUTE} className="oprations sidebar__link">
          <IconOperations></IconOperations>Operaciones
        </NavLink>
        <NavLink to={CHECKS_ROUTE} className="sidebar__link">
          <IconCheques></IconCheques>Cheques
        </NavLink>

        <NavLink to={LOANS_ROUTE} className="sidebar__link">
          <IconPrestamos></IconPrestamos> Préstamos
        </NavLink>
        <NavLink to={CESSIONS_ROUTE} className="sidebar__link">
          <IconCesiones></IconCesiones> Cesiones
        </NavLink>
      </div>

      <div className="btnLogout">
        <div className="version" onClick={() => navigate(VERSIONS_ROUTE)}>
          Versión {APP_VERSION}
        </div>
        <button onClick={handleLogout}>
          <IconLogout></IconLogout> Logout
        </button>
      </div>
    </div>
  );
};

export default SideBar;
