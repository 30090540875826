export const dropdownStateOptions = [
  { value: 0, label: 'Aprobado por Descontalo' },
  { value: 1, label: 'Aprobado por Cliente' },
  { value: 2, label: 'Recibido y Endosado Correctamente' },
  { value: 3, label: 'No Recibido o No Cumple Requisitos' },
  { value: 4, label: 'Rechazado por Descontalo' },
  { value: 5, label: 'Rechazado por Cliente' },
  { value: 6, label: 'Operación No Concretada' },
  { value: 7, label: 'Recibido sin procesar' },
  { value: 8, label: 'Transacción Completada' },
  { value: 9, label: 'En RED PAGOS' },
  { value: 10, label: 'En CARTERA' },
  { value: 11, label: 'Depositado' },
  { value: 12, label: 'Cobrado' },
  { value: 13, label: 'Refinanciado' },
  { value: 14, label: 'Rebotado y Cobrado' },
  { value: 15, label: 'Rebotado y No Cobrado' },
  { value: 16, label: 'Entregado a un Tercero' },
];
