export type Drawer = {
  _id?: string;
  name: string; // Librador cheque
  rut: string; // rut librador
  legalName: string; // razon social
  // bank: string; // banco cheque
  tradeName: string; // nombre comercial
  creationDate?: Date;
};

export const emptyDrawer: Drawer = {
  name: '',
  rut: '',
  legalName: '',
  // bank: '',
  tradeName: '',
};
