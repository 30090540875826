import React from 'react';

const Versions = () => {
  const versionLog = {
    '2.0.1': [
      {
        category: 'Generales',
        changes: [
          "Agregar campo 'canalizado por' para todas las operaciones (cheques, préstamos y cesiones). Cambiar el nombre de 'Banda' a 'Canalizado por'.",
          'Excel: descargas - Todos los campos. (tener cuidado con los formatos).',
        ],
      },
      {
        category: 'Clientes',
        changes: ['Agregar fecha de vencimiento en operaciones en curso'],
      },
      {
        category: 'Librador',
        changes: [
          'Quitar opción BANCO. Esta información debería estar en CHEQUE ya que un librador puede tener varios bancos',
          'RUT debería ser un identificador único. Si ya está cargado tendría que saltar un error que es repetido.',
          'Al buscar un librador ver reflejado el historial de LOGS (moneda, monto, operaciones, endosantes) → mostrar tablas con operaciones como en clientes',
        ],
      },
      {
        category: 'Operaciones',
        changes: [
          'Como crear una operación con varios cheques. Creo que es mejor crear las operaciones y agregar los cheques directamente. En este momento el proceso es al revés',
          'En cheque hacer un botón que cree una operación al crear ese cheque y que asocie la operación con ese cheque',
        ],
      },
      {
        category: 'Cheques',
        changes: [
          'Agregar campo banco',
          'Agregar campo celular',
          'Al agregar un archivo poder editar el nombre del archivo (debería verse el nombre y no el código) → guardarle el nombre del archivo en MongoDB (friendlyname a los archivos)',
          "Otros documentos: Ver los adjuntos en 'Lista'. Visualmente nos queda mejor leer el nombre del archivo en una sola línea. La vista previa ayuda (Igual al sistema viejo)",
          'Foto cheques: hacer que siempre abra en una tab distinta',
          'Campos obligatorios: Cliente – Foto – Cel',
          'Los cálculos de tasa, interés monto aún no se calculan automáticamente.',
          'Aún está pendiente agregar la cotización para enviar al cliente (atado con el de arriba → verlo)',
        ],
      },
      {
        category: 'Cesiones',
        changes: [
          'En vez de ENTE debería ser campo desplegable contra base de libradores.',
          "En ESTADO agregar la opción 'No adelantado y otros'",
          "Falta agregar 'moneda'",
          'Orden de visualización: Cliente – Ente – Estado – Vencimiento – Moneda Monto en tabla',
          'Cesiones no permite cambiar estado',
        ],
      },
      {
        category: 'Préstamos',
        changes: [
          "Replicar estructura de cheques (nosotros cargaríamos la cantidad de cuotas individualmente como si fuera un cheque). Opción 'préstamo' al cargar en cheques.",
        ],
      },
      {
        category: 'Usabilidad y Datos Menores',
        changes: ['Achicar panel de la izquierda. Tal vez quitando íconos y/o achicando letras y espacios.'],
      },
      {
        category: 'Logs Meeting',
        changes: [
          'Una operación no puede tener más de una entidad (solo cheques, o solo cesiones o solo préstamos)',
          'Quieren opción de crear operación al crear cheques',
          'Filtro de cheques sin operaciones',
          'Asociar cheques a operaciones viejas',
          'En tablas: cheques: cliente librador monto',
          'Al crear un cheque no permite editar moneda y estado',
          'Exportación: parsear fechas día/mes/año',
          'Exportación: clientes que muestre () y libradores(nombre)',
          'Fix: En cheque no se ve la imagen de dorso',
          'Fix: Operaciones en pesos no funciona correctamente',
          'Mostrar lista desplegable al elegir banco',
          'En lista de cheques mostrar librador',
          'Poder modificar imagen frente y dorso en cheque',
          'Mejora de UI al editar fotos de cheque'
        ],
      },
    ],
  };

  return (
    <div className="previous-versions">
      <h1>Historial de actualización</h1>
      {Object.entries(versionLog).map(([version, categories]) => (
        <div key={version}>
          <h2>Versión {version}</h2>
          {categories.map((item, idx) => (
            <div key={idx}>
              <h3>{item.category}</h3>
              <ul>
                {item.changes.map((change, index) => (
                  <li key={index}>{change}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Versions;
