import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../services/store';
import FormInput from '../../components/FormInput';
import ActionButton from '../../components/ActionButton';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import { User as UserType, emptyUser } from '../../../types/user';
import { useNavigate, useParams } from 'react-router-dom';
import { clearState, deleteUser, fetchUsers, registerUser, updateUser } from '../../../services/login/loginSlice';
import ConfirmationModal from '../../components/ConfirmationModal';
import { USERS_ROUTE } from '../../../utils/routes';
import { PuffLoader } from 'react-spinners';

const User = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState<UserType>(emptyUser);
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const isLoading = useSelector((state: RootState) => state.login.isLoading);
  const error = useSelector((state: RootState) => state.login.error);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('_id');
  const success = useSelector((state: RootState) => state.login.success);
  const users = useSelector((state: RootState) => state.login.users);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      const foundUser = users.find((user: UserType) => user._id === id);
      if (!foundUser) {
        dispatch(fetchUsers(token || ''));
      }
      foundUser && setUser(foundUser);
    }
  }, []);

  useEffect(() => {
    const foundUser = users.find((user: UserType) => user._id === id);
    foundUser && setUser(foundUser);
  }, [users]);

  useEffect(() => {
    if (success && !isEditing) {
      navigate(USERS_ROUTE);
    }
  }, [success]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((previousUser) => ({
      ...previousUser,
      [name]: value,
    }));
    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setIsValidEmail(isValidEmail);
    }
  };

  const handleEditButton = () => {
    setIsEditing((prevState) => !prevState);
    setShowPassword((prevState) => !prevState);
    setUser((previousUser) => ({
      ...previousUser,
      ['password']: '',
    }));
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteUser({ id: id, token: token || '' }));
      navigate(USERS_ROUTE);
    }
  };

  const DisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: false,
    editButton: !!user['_id'],
    deleteButton: !!user['_id'] && user['_id'] !== userId,
  };

  const handleSubmit = () => {
    if (user['_id']) {
      dispatch(updateUser({ userId: id, userData: user, token: token || '' }));
    } else {
      dispatch(registerUser({ registrationData: user, token: token }));
    }
    setShowPassword(false);
  };

  return isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="clientManagement usuariosInternos">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar a ${user?.email}?`}
        />
        <BackButton />
        <TopContent
          title={!user['_id'] ? 'Registrar Usuario Interno' : isEditing ? `Editar a ${user['name']}` : user['name']}
          displayElements={DisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={() => null}
          onClickEdit={handleEditButton}
          onClickDelete={() => setDeleteModalOpen(true)}
        />
      </div>
      <div className="formRegister form">
        <FormInput
          name={'email'}
          value={user ? user['email']?.toString() : ''}
          label={'Email'}
          onChange={handleChange}
          type={'string'}
          disabled={!isEditing && !!user['_id']}
        />
        {!isValidEmail && <p className="errorP">El Email no es válido.</p>}
        <FormInput
          name={'name'}
          value={user ? user['name']?.toString() : ''}
          label={'Nombre'}
          onChange={handleChange}
          type={'string'}
          disabled={!isEditing && !!user['_id']}
        />
        <FormInput
          name={'password'}
          value={user ? user['password']?.toString() : ''}
          label={'Contraseña'}
          onChange={handleChange}
          type={showPassword ? 'string' : 'password'}
          disabled={!isEditing && !!user['_id']}
        />
        {!isLoading ? (
          <ActionButton
            onClick={handleSubmit}
            text={isLoading ? 'Cargando...' : user['_id'] ? 'Editar' : 'Crear'}
            alt={user['_id'] ? 'Edit button' : 'Submit button'}
            disabled={!isEditing && !!user['_id']}
          />
        ) : null}
        {/* TODO: pasarlo a toast */}
        {error && <p className="errorP">{error}</p>}
        {success && !error && <p className="succsesP">Operación realizada con éxito.</p>}
      </div>
    </div>
  );
};

export default User;
