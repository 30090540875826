import { Client } from '../../../types/client';

export const TableHeaders = {
  ID: 'ID',
  NAME: 'Nombre',
  RUT: 'RUT',
  LEGAL_NAME: 'Razón Social',
  STATE: 'Estado',
  COMPLETED_TRANSACTIONS: 'Transacciones Completadas',
  ONGOING_TRANSACTIONS: 'Transacciones En Curso',
};

export const TableHeaderToClientProperty: Record<string, keyof Client> = {
  ID: '_id',
  NAME: 'name',
  RUT: 'rut',
  LEGAL_NAME: 'legalName',
  STATE: 'state',
  COMPLETED_TRANSACTIONS: 'completedTransactions',
  ONGOING_TRANSACTIONS: 'ongoingTransactions',
};
