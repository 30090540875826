import React from 'react';
const IconLogout = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4844 53.9998H66C67.5913 53.9998 69.1174 53.3677 70.2426 52.2424C71.3678 51.1172 72 49.5911 72 47.9998C72 46.4085 71.3678 44.8824 70.2426 43.7572C69.1174 42.6319 67.5913 41.9998 66 41.9998H20.4844L28.2422 34.242C29.3621 33.1158 29.9898 31.5915 29.9876 30.0033C29.9854 28.415 29.3535 26.8925 28.2304 25.7694C27.1073 24.6463 25.5848 24.0144 23.9965 24.0122C22.4083 24.01 20.884 24.6377 19.7578 25.7576L1.75779 43.7576C1.20052 44.3146 0.758453 44.9759 0.456846 45.7038C0.155238 46.4317 0 47.2119 0 47.9998C0 48.7877 0.155238 49.5679 0.456846 50.2958C0.758453 51.0237 1.20052 51.685 1.75779 52.242L19.7578 70.242C20.3143 70.8016 20.9758 71.246 21.7044 71.5495C22.4329 71.8531 23.2142 72.0099 24.0034 72.011C24.7927 72.0121 25.5744 71.8575 26.3038 71.5559C27.0332 71.2544 27.6959 70.8119 28.254 70.2538C28.8121 69.6957 29.2546 69.033 29.5561 68.3036C29.8576 67.5742 30.0123 66.7925 30.0112 66.0033C30.0101 65.214 29.8533 64.4327 29.5497 63.7042C29.2462 62.9756 28.8018 62.3141 28.2422 61.7576L20.4844 53.9998Z"
        fill="#3D9FE0"
      />
      <path
        d="M90 9.64515e-07H42C41.2119 -0.000445981 40.4315 0.154444 39.7034 0.455813C38.9752 0.757183 38.3136 1.19912 37.7564 1.75636C37.1991 2.3136 36.7572 2.97522 36.4558 3.70337C36.1544 4.43153 35.9996 5.21194 36 6V18C36 19.5913 36.6321 21.1174 37.7574 22.2426C38.8826 23.3679 40.4087 24 42 24C43.5913 24 45.1174 23.3679 46.2426 22.2426C47.3679 21.1174 48 19.5913 48 18V12H84V84H48V78C48 76.4087 47.3679 74.8826 46.2426 73.7574C45.1174 72.6321 43.5913 72 42 72C40.4087 72 38.8826 72.6321 37.7574 73.7574C36.6321 74.8826 36 76.4087 36 78V90C35.9996 90.7881 36.1544 91.5685 36.4558 92.2966C36.7572 93.0248 37.1991 93.6864 37.7564 94.2436C38.3136 94.8009 38.9752 95.2428 39.7034 95.5442C40.4315 95.8456 41.2119 96.0004 42 96H90C90.7881 96.0004 91.5685 95.8456 92.2966 95.5442C93.0248 95.2428 93.6864 94.8009 94.2436 94.2436C94.8009 93.6864 95.2428 93.0248 95.5442 92.2966C95.8456 91.5685 96.0004 90.7881 96 90V6C96.0004 5.21194 95.8456 4.43153 95.5442 3.70337C95.2428 2.97522 94.8009 2.3136 94.2436 1.75636C93.6864 1.19912 93.0248 0.757183 92.2966 0.455813C91.5685 0.154444 90.7881 -0.000445981 90 9.64515e-07Z"
        fill="#3D9FE0"
      />
    </svg>
  );
};
export default IconLogout;
