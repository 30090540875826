import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, AppDispatch } from '../../../services/store';
import { clearState, createClient, deleteClient, fetchClients, updateClient } from '../../../services/clients/clientSlice';
import { BankAccountType, Client as ClientType, emptyClient } from '../../../types/client';
import ActionButton from '../../components/ActionButton';
import BackButton from '../../components/BackButton';
import TopContent from '../../components/TopContent';
import ConfirmationModal from '../../components/ConfirmationModal';
import { CLIENTS_ROUTE } from '../../../utils/routes';
import ClientPersonal from './clientPersonal';
import ClientCompany from './clientCompany';
import ClientInternal from './clientInternal';
import BankAccounts from './bankAccounts';
import ClientOperations from './clientOperations';
import { csvHeaderAdapt } from '../Clients/utils';
import { PuffLoader } from 'react-spinners';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

type ClientContentType = {
  client: ClientType;
  isLoading: boolean;
  setClient: React.Dispatch<React.SetStateAction<ClientType>>;
};

const ClientContent = (props: ClientContentType) => {
  const { client, isLoading, setClient } = props;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCi, setIsValidCi] = useState(true);

  const [operations, setOperations] = useState(undefined);

  const success = useSelector((state: RootState) => state.client.success);
  const token = localStorage.getItem('token');

  const topDisplayConfig = {
    filtersButton: false,
    searchBar: false,
    addButton: false,
    downloadButton: !!client['_id'],
    editButton: !!client['_id'],
    deleteButton: !!client['_id'],
  };

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    axios.get(`${process.env.REACT_APP_API_URL}/clients/${id}/opreations`, { headers })
      .then(res => {
        console.log(res.data.data);
        setOperations(res.data.data.operations);
      }).catch(err => console.log('error al cargar operaciones'));
  }, [])

  useEffect(() => {
    if (success && !isEditing) {
      navigate(CLIENTS_ROUTE);
    }
  }, [success]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setClient((previousClient) => ({
      ...previousClient,
      [name]: value,
    }));
    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setIsValidEmail(isValidEmail);
    }
    if (name === 'ci') {
      const isValidCi = /^\d{7,8}$/.test(value);
      setIsValidCi(isValidCi);
    }
  };

  const handleChangeBankAccounts = (e: React.ChangeEvent<HTMLInputElement>, number: number) => {
    const { value, name } = e.target;
    setClient((previousClient) => ({
      ...previousClient,
      ['bankAccounts']: previousClient.bankAccounts?.map((account) => {
        if (account.number === number) {
          let updatedValue = value;
          if (name === 'name') {
            updatedValue = value.replace(/\//g, '');
          }
          const completeAccount = `${name === 'bank' ? value : account.bank || ''} / ${name === 'type' ? value : account.type || ''} / ${name === 'accountType' ? value : account.accountType || ''
            } / ${name === 'name' ? updatedValue : account.name || ''} / ${name === 'number' ? value : account.number || ''} / ${name === 'branch' ? value : account.branch || ''
            }`;
          return { ...account, [name]: value, completeAccount };
        }
        return account;
      }),
    }));
  };

  const addBankAccount = () => {
    setClient((previousClient) => {
      const previewsBankAccounts: BankAccountType[] = previousClient['bankAccounts'] || [];
      const newBankAccounts: BankAccountType[] = [...previewsBankAccounts, { bank: '', number: 0, type: '', name: '', accountType: '' }];
      return {
        ...previousClient,
        ['bankAccounts']: newBankAccounts,
      };
    });
  };

  const deleteBankAccount = (number: number) => {
    if (client.bankAccounts) {
      const index = client.bankAccounts.findIndex((account) => account.number === number);
      if (index !== -1) {
        const updatedBankAccounts = [...client.bankAccounts];
        updatedBankAccounts.splice(index, 1);
        setClient((previousClient) => ({
          ...previousClient,
          bankAccounts: updatedBankAccounts,
        }));
      }
    }
  };

  const handleSubmit = () => {
    if (client['_id']) {
      dispatch(updateClient({ clientData: client, token: token || '' }));
    } else {
      dispatch(createClient({ clientData: client, token: token }));
    }
  };

  const handleDelete = () => {
    if (id !== undefined) {
      dispatch(deleteClient({ _id: client?._id || '', token: token || '' }));
      navigate(CLIENTS_ROUTE);
    }
  };

  const handleEditButton = () => {
    setIsEditing((prevState) => !prevState);
  };

  const downloadAsCSV = () => {
    const fileName = `client_${client.name}_${client.ci}.csv`;
    const allKeys: string[] = Object.keys(client).filter((key) => key !== 'bankAccounts' && key !== 'ongoingTransactions' && key !== '__v');
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = allKeys.map((key) => (client as any)[key] || '-').join(',');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const fileName = `client_${client.name}_${client.ci}.xlsx`;
    const allKeys: string[] = Object.keys(client).filter((key) => key !== 'bankAccounts' && key !== 'ongoingTransactions' && key !== '__v');
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = [allKeys.map((key) => (client as any)[key] || '-')];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Client Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = fileName;

    link.click();
    URL.revokeObjectURL(link.href);
  };

  return isLoading ? (
    <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <PuffLoader color="#3D9FE0" />
    </div>
  ) : (
    <div className="clientManagement">
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          onClickAccept={handleDelete}
          contentLabel={`¿Está seguro que quiere borrar a ${client?.name}?`}
        />
        <BackButton />
        <TopContent
          title={!client['_id'] ? 'Registrar Cliente' : isEditing ? 'Editar Cliente' : client['name']}
          displayElements={topDisplayConfig}
          onClickOpenFilters={() => null}
          onChangeSearchBar={() => null}
          newElementRoute={''}
          onClickDownload={downloadAsCSV}
          onClickEdit={handleEditButton}
          onClickDelete={() => setDeleteModalOpen(true)}
          onClickDownloadExcel={!!client['_id'] ? downloadAsExcel : undefined}
        />
      </div>
      <div className="formRegister form clientForm">
        <ClientPersonal
          client={client}
          handleChange={handleChange}
          inputsDisabled={!isEditing && !!client['_id']}
          isValidEmail={isValidEmail}
          isValidCi={isValidCi}
        />
        <ClientCompany client={client} handleChange={handleChange} inputsDisabled={!isEditing && !!client['_id']} />
        <ClientInternal client={client} handleChange={handleChange} inputsDisabled={!isEditing && !!client['_id']} />
        <BankAccounts
          client={client}
          handleChange={handleChangeBankAccounts}
          inputsDisabled={!isEditing && !!client['_id']}
          addBankAccount={addBankAccount}
          deleteBankAccount={deleteBankAccount}
        />
        {!!client['_id'] && operations && <ClientOperations operations={operations} client={client} />}
      </div>
      {!isLoading && (isEditing || !client['_id']) ? (
        <ActionButton
          onClick={handleSubmit}
          text={isLoading ? 'Cargando...' : client['_id'] ? 'Editar' : 'Crear'}
          alt={client['_id'] ? 'Edit button' : 'Submit button'}
        />
      ) : null}
      {success && <p className="succsesP">{'Operación realizada con éxito.'}</p>}
    </div>
  );
};

const ClientContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const [client, setClient] = useState<ClientType>(emptyClient);
  const [isFetching, setIsFetching] = useState(false);
  const isLoading = useSelector((state: RootState) => state.client.isLoading);
  const token = localStorage.getItem('token');
  const clients = useSelector((state: RootState) => state.client.clients);

  useEffect(() => {
    dispatch(clearState());
    if (id !== undefined) {
      setIsFetching(true);
      const foundClient = clients.find((client: ClientType) => client._id === id);
      if (!foundClient) {
        dispatch(fetchClients(token || ''));
      }
      foundClient && setClient(foundClient);
    }
  }, []);

  useEffect(() => {
    const foundClient = clients.find((client: ClientType) => client._id === id);
    foundClient && setClient(foundClient);
    setIsFetching(false);
  }, [clients]);

  return <ClientContent client={client} isLoading={isFetching || isLoading} setClient={setClient} />;
};

export default ClientContainer;
