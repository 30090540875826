import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Check } from '../../types/check';

interface CheckState {
  checks: Check[];
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: CheckState = {
  checks: [],
  isLoading: false,
  error: null,
  success: false,
};

export const fetchChecks = createAsyncThunk('checks', async (token: string) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/checks`, { headers: headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) return Promise.reject(error.response.data.error.message);
    return Promise.reject('An error occurred while trying to fetch the checks.');
  }
});

export const createCheck = createAsyncThunk('checks/create', async ({ checkData, token }: { checkData: Check; token: string | undefined | null }) => {
  try {
    const filteredCheckData: Partial<Check> = Object.fromEntries(Object.entries(checkData).filter(([_, value]) => value !== '' && value !== null));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/checks/create`, filteredCheckData, { headers });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to create the check.');
  }
});

export const deleteCheck = createAsyncThunk('checks/delete', async ({ id, token }: { id: string | undefined | null; token: string }) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    await axios.delete(`${process.env.REACT_APP_API_URL}/checks/delete?id=${id}`, { headers });
    return id;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data.error.message);
    }
    return Promise.reject('An error occurred while trying to delete the check.');
  }
});

export const updateCheck = createAsyncThunk(
  'checks/update',
  async ({ id, checkData, token }: { id: string | null | undefined; checkData: Check; token: string }) => {
    try {
      const filteredCheckData: Partial<Check> = Object.fromEntries(Object.entries(checkData).filter(([_, value]) => value !== '' && value !== null));
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/checks/update?id=${id}`, filteredCheckData, { headers });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data.error.message);
      }
      return Promise.reject('An error occurred while trying to update the check.');
    }
  },
);

const checksSlice = createSlice({
  name: 'check',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
    addChecks: (state, action) => {
      const map = new Map();
      [...state.checks, ...action.payload].forEach(item => {
        map.set(item._id, item);
      });
      state.checks = Array.from(map.values());
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChecks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.checks = action.payload.data.checks;
      })
      .addCase(fetchChecks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchChecks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch checks';
      })
      .addCase(createCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.checks.unshift(action.payload.data.check);
        state.success = true;
      })
      .addCase(createCheck.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to create check';
      })
      .addCase(deleteCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        state.checks = state.checks.filter((check) => check._id !== action.meta.arg.id);
      })
      .addCase(deleteCheck.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to delete check';
      })
      .addCase(updateCheck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.success = true;
        const { _id } = action.payload.data.check;
        const index = state.checks.findIndex((check) => check._id === _id);
        if (index !== -1) {
          state.checks[index] = { ...state.checks[index], ...action.payload.data.check };
        }
      })
      .addCase(updateCheck.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to update check';
      });
  },
});

export default checksSlice.reducer;

export const { clearState, addChecks } = checksSlice.actions;
