import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import TopContent from '../../components/TopContent';
import Table from '../../components/Table';
import { NEW_CESSIONS_ROUTE, NEW_CHECK_ROUTE } from '../../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate } from 'react-router';
import { csvHeaderAdapt, formatDataToTable } from './utils';
import { TableHeaderToCessionProperty, TableHeaders } from './consts';
import { fetchCessions } from '../../../services/cessions/cessionSlice';

const Cessions = () => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch<AppDispatch>();
  const cessions = useSelector((state: RootState) => state.cession.cessions);
  const isLoading = useSelector((state: RootState) => state.cession.isLoading);
  const error = useSelector((state: RootState) => state.cession.error);
  const navigate = useNavigate();

  useEffect(() => {
    cessions.length === 0 && dispatch(fetchCessions(token || ''));
  }, []);

  const DisplayConfig = {
    filtersButton: false,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  let filteredCessions = cessions;

  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchText(value.trim().toLowerCase());
  };

  if (searchText) {
    const searchFilteredCessions = cessions.filter((cession) => {
      const cessionAttributesToSearch = Object.keys(TableHeaderToCessionProperty)
        .map((key) => {
          const cessionProperty = TableHeaderToCessionProperty[key as keyof typeof TableHeaderToCessionProperty];
          return cessionProperty === 'client' ? cession.client?.name : cession[cessionProperty];
        })
        .filter((value): value is string => typeof value === 'string');

      const match = cessionAttributesToSearch.some((attr) => {
        if (typeof attr === 'string') {
          return attr.toLowerCase().includes(searchText);
        }
        return false;
      });

      return match;
    });
    filteredCessions =
      filteredCessions.length > 0 ? filteredCessions.filter((cession) => searchFilteredCessions.includes(cession)) : searchFilteredCessions;
  }

  const downloadAsCSV = () => {
    const allKeys: string[] =
      filteredCessions.length > 0
        ? filteredCessions.reduce((keys: string[], cession) => {
            Object.keys(cession).forEach((key) => {
              if (
                !keys.includes(key) &&
                key !== '__v' &&
                key !== 'voucherPhoto' &&
                key !== 'client' &&
                key !== 'operations' &&
                key !== 'user' &&
                key !== 'documents'
              ) {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = filteredCessions.map((cession) => allKeys.map((key) => (cession as any)[key] || '-').join(',')).join('\n');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'cesiones.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const allKeys: string[] =
      filteredCessions.length > 0
        ? filteredCessions.reduce((keys: string[], cession) => {
            Object.keys(cession).forEach((key) => {
              if (
                !keys.includes(key) &&
                key !== '__v' &&
                key !== 'voucherPhoto' &&
                key !== 'client' &&
                key !== 'operations' &&
                key !== 'user' &&
                key !== 'documents'
              ) {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = filteredCessions.map((cession) => allKeys.map((key) => (cession as Record<string, any>)[key] || '-'));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cesiones');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'cesiones.xlsx';

    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleRowClick = (cessionId: string) => {
    navigate(`/cesiones/${cessionId}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <TopContent
        title={'Cesiones'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => null}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_CESSIONS_ROUTE}
        onClickDownload={downloadAsCSV}
        onClickDownloadExcel={downloadAsExcel}
      />
      <div className="cessionTable wrapperTable">
        <Table
          isLoading={isLoading}
          headers={Object.values(TableHeaders)}
          data={formatDataToTable(filteredCessions)}
          onRowClick={handleRowClick}
          showId={true}
        />
      </div>
    </div>
  );
};
export default Cessions;
