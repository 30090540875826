import React, { useState } from 'react';
import { BaseOrigin, CheckState, State, getCheckStateLabel, getCustomLabel } from './utils';
import ActionButton from '../ActionButton';
import FormInput from '../FormInput';
import DatePicker from '../DatePicker';

export interface FilterValues {
  [key: string]: string | boolean | number | Date;
}

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (filters: FilterValues) => void;
  onClear: () => void;
  filters: FilterValues;
}

const FilterModal = (props: FilterModalProps) => {
  const [appliedFilters, setAppliedFilters] = useState<FilterValues>(props.filters);
  const [dateError, setDateError] = useState<string>('');

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value, type } = e.target;

    setAppliedFilters((prevFilters) => {
      if (type === 'checkbox') {
        return {
          ...prevFilters,
          [name]: (e.target as HTMLInputElement).checked,
        };
      } else if (type === 'number') {
        return {
          ...prevFilters,
          [name]: Number(value),
        };
      } else if (type === 'select-one') {
        return {
          ...prevFilters,
          [name]: Number(value),
        };
      } else {
        return {
          ...prevFilters,
          [name]: value,
        };
      }
    });
    updateDateError(name, value);
  };

  const updateDateError = (name: string, value: string) => {
    if (name === 'dateFrom' || name === 'dateTo') {
      if (name === 'dateFrom' && appliedFilters.dateTo && value > appliedFilters.dateTo) {
        setDateError('Conflicto: la fecha de Hasta no puede ser menor a la fecha Desde.');
      } else if (name === 'dateTo' && appliedFilters.dateFrom && value < appliedFilters.dateFrom) {
        setDateError('Conflicto: la fecha de Desde no puede ser mayor a la fecha Hasta.');
      } else {
        setDateError('');
      }
    }
  };

  const handleApplyFilters = () => {
    props.onApply(appliedFilters);
    props.onClose();
  };

  const handleClearFilters = () => {
    props.onClear();
    props.onClose();
  };

  return (
    <div className="filterModal">
      <div>
        <h2>Filtros</h2>
        {Object.keys(props.filters).map((filterKey) => (
          <label key={filterKey}>
            {getCustomLabel(filterKey)}:
            {filterKey === 'state' || filterKey === 'checkState' ? (
              <select name={filterKey} value={appliedFilters[filterKey] as string} onChange={handleFilterChange}>
                {filterKey === 'state'
                  ? Object.entries(State).map(([stateName, stateValue]) => (
                      <option key={stateValue} value={stateValue}>
                        {stateName}
                      </option>
                    ))
                  : Object.entries(CheckState).map(([checkStateName, checkStateValue]) => (
                      <option key={checkStateValue} value={checkStateValue}>
                        {getCheckStateLabel(checkStateValue)}
                      </option>
                    ))}
              </select>
            ) : typeof appliedFilters[filterKey] === 'boolean' ? (
              <label className="switch">
                <input type="checkbox" name={filterKey} checked={appliedFilters[filterKey] as boolean} onChange={handleFilterChange} />
                <span className="slider round"></span>
              </label>
            ) : typeof appliedFilters[filterKey] === 'number' ? (
              <input type="number" min={0} name={filterKey} value={appliedFilters[filterKey] as number} onChange={handleFilterChange} />
            ) : filterKey === 'dateTo' || filterKey === 'dateFrom' ? (
              <DatePicker name={filterKey} value={appliedFilters[filterKey] as string} label={''} onChange={handleFilterChange} />
            ) : (
              <FormInput
                placeholder=""
                label={filterKey}
                name={filterKey}
                value={appliedFilters[filterKey] as string}
                onChange={handleFilterChange}
              />
            )}
          </label>
        ))}
        {dateError !== '' && <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 600, paddingTop: '10px' }}>{dateError}</p>}
        <div className="modalBtns">
          <ActionButton onClick={props.onClose} text="Cerrar" alt="Cancel button" />
          <ActionButton onClick={handleClearFilters} text="Limpiar" alt="Limpiar button" />
          <ActionButton onClick={handleApplyFilters} text="Aplicar" alt="Apply button" />
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
