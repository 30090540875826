import React from 'react';
import FormInput from '../../components/FormInput';
import { ClientSection } from './types';

const ClientCompany = ({ client, handleChange, inputsDisabled }: ClientSection) => {
  return (
    <div className="formRegister__wrapper">
      <h1>Empresariales</h1>
      <div className="formRegister__inputArea">
        <FormInput
          name={'rut'}
          value={client['rut'] ? client['rut']?.toString() : ''}
          label={'RUT'}
          onChange={handleChange}
          type={'number'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'legalName'}
          value={client['legalName'] ? client['legalName']?.toString() : ''}
          label={'Razón social'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'tradeName'}
          value={client['tradeName'] ? client['tradeName']?.toString() : ''}
          label={'Nombre Fantasía'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'mainIndustry'}
          value={client['mainIndustry'] ? client['mainIndustry']?.toString() : ''}
          label={'Rubro Principal'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'fiscalAddress'}
          value={client['fiscalAddress'] ? client['fiscalAddress']?.toString() : ''}
          label={'Domicilio Fiscal'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'reference'}
          value={client['reference'] ? client['reference']?.toString() : ''}
          label={'Referente'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
      </div>
    </div>
  );
};

export default ClientCompany;
