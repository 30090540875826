export type User = {
  _id?: string;
  email: string;
  password: string;
  name: string;
  creationDate?: Date;
};

export const emptyUser: User = {
  email: '',
  name: '',
  password: '',
};
