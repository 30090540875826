import * as XLSX from 'xlsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../services/store';
import { useNavigate } from 'react-router';
import { fetchLoans } from '../../../services/loans/loansSlice';
import TopContent from '../../components/TopContent';
import { NEW_LOANS_ROUTE } from '../../../utils/routes';
import Table from '../../components/Table';
import { TableHeaderToLoanProperty, TableHeaders } from './consts';
import { csvHeaderAdapt, formatDataToTable } from './utils';

const Loans = () => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch<AppDispatch>();
  const loans = useSelector((state: RootState) => state.loan.loans);
  const isLoading = useSelector((state: RootState) => state.loan.isLoading);
  const navigate = useNavigate();

  const DisplayConfig = {
    filtersButton: false,
    searchBar: true,
    addButton: true,
    downloadButton: true,
    editButton: false,
  };

  let filteredLoans = loans;
  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchText(value.trim().toLowerCase());
  };

  if (searchText) {
    const searchFilteredChecks = loans.filter((loan) => {
      const loanAttributesToSearch = Object.keys(TableHeaderToLoanProperty)
        .map((key) => {
          const loanProperty = TableHeaderToLoanProperty[key as keyof typeof TableHeaderToLoanProperty];
          return loanProperty === 'client' ? loan.client?.name : loan[loanProperty];
        })
        .filter((value): value is string => typeof value === 'string');

      const match = loanAttributesToSearch.some((attr) => {
        if (typeof attr === 'string') {
          return attr.toLowerCase().includes(searchText);
        }
        return false;
      });

      return match;
    });
    filteredLoans = filteredLoans.length > 0 ? filteredLoans.filter((loan) => searchFilteredChecks.includes(loan)) : searchFilteredChecks;
  }

  useEffect(() => {
    dispatch(fetchLoans(token || ''));
  }, []);

  const downloadAsCSV = () => {
    const allKeys: string[] =
      filteredLoans.length > 0
        ? filteredLoans.reduce((keys: string[], loan) => {
            Object.keys(loan).forEach((key) => {
              if (!keys.includes(key) && key !== '__v' && key !== 'client') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const headers = allKeys.map(csvHeaderAdapt).join(',') + '\n';
    const content = filteredLoans
      .map((loan) =>
        allKeys
          .map((key) => {
            const value = (loan as any)[key];
            return Array.isArray(value) ? value.join(';') : value || '-';
          })
          .join(','),
      )
      .join('\n');
    const csvContent = headers + content;
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'prestamos.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const allKeys: string[] =
      filteredLoans.length > 0
        ? filteredLoans.reduce((keys: string[], loan) => {
            Object.keys(loan).forEach((key) => {
              if (!keys.includes(key) && key !== '__v' && key !== 'client' && key !== 'loans' && key !== 'cessions') {
                keys.push(key);
              }
            });
            return keys;
          }, [])
        : [];
    const translatedHeaders = allKeys.map(csvHeaderAdapt);
    const data = filteredLoans.map((loan) =>
      allKeys.map((key) => {
        const value = (loan as any)[key];
        return Array.isArray(value) ? value.join(';') : value || '-';
      }),
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([translatedHeaders, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Prestamos');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'prestamos.xlsx';

    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleRowClick = (loanId: string) => {
    navigate(`/prestamos/${loanId}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <TopContent
        title={'Préstamos'}
        displayElements={DisplayConfig}
        onClickOpenFilters={() => null}
        onChangeSearchBar={handleSearch}
        newElementRoute={NEW_LOANS_ROUTE}
        onClickDownload={downloadAsCSV}
        onClickDownloadExcel={downloadAsExcel}
      />
      <div className="loansTable wrapperTable">
        <Table
          headers={Object.values(TableHeaders).filter((header) => header !== TableHeaders.ID)}
          data={formatDataToTable(filteredLoans)}
          onRowClick={handleRowClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Loans;
