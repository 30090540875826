import React from 'react';
import FormInput from '../../components/FormInput';
import { ClientSection } from './types';
import IconWhatsApp from '../../../assets/iconWhatsApp';

const ClientPersonal = ({ client, handleChange, inputsDisabled, isValidEmail, isValidCi }: ClientSection) => {
  const handleWhatsAppMessage = () => {
    const phone = client.phone.replace(/^0/, '');
    window.open(`https://wa.me/${phone}`, '_blank');
  };

  return (
    <div className="formRegister__wrapper">
      <h1>Personales</h1>
      <div className="formRegister__inputArea">
        <FormInput
          name={'_id'}
          value={client ? (client['_id'] ? client['_id']?.toString() : '') : ''}
          label={'ID'}
          onChange={handleChange}
          type={'string'}
          disabled={true}
          placeholder={!client['_id'] ? 'Campo autogenerable' : ''}
        />
        <FormInput
          name={'email'}
          value={client ? client['email']?.toString() : ''}
          label={'Correo (*)'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
          hasError={!isValidEmail}
        />
        <FormInput
          name={'name'}
          value={client ? (client ? client['name']?.toString() : '') : ''}
          label={'Nombre (*)'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <FormInput
          name={'ci'}
          value={client ? (client ? client['ci']?.toString() : '') : ''}
          label={'Cédula (*)'}
          onChange={handleChange}
          type={'number'}
          disabled={inputsDisabled}
          hasError={!isValidCi}
        />
        <FormInput
          name={'address'}
          value={client ? (client ? client['address']?.toString() : '') : ''}
          label={'Direccion'}
          onChange={handleChange}
          type={'string'}
          disabled={inputsDisabled}
        />
        <div className="btnWpWrapper">
          <FormInput
            name={'phone'}
            value={client ? (client ? client['phone']?.toString() : '') : ''}
            label={'Teléfono'}
            onChange={handleChange}
            type={'string'}
            disabled={inputsDisabled}
          />
          <div className="btnWp">
            {!!client['phone'] && inputsDisabled && (
              <button
                style={{ backgroundColor: '#3D9FE0', borderRadius: '8px', color: 'white', cursor: 'pointer', padding: '5px' }}
                onClick={handleWhatsAppMessage}
              >
                <IconWhatsApp />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPersonal;
