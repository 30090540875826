import { Operation } from '../../../types/operation';

export const TableHeaders = {
  ID: 'ID',
  CLIENT: 'Cliente',
  OPERATIONTYPE: 'Tipo de operación',
  // COMPANY: 'Empresa',
  // AMOUNT_OF_CHECKS: 'Cantidad de cheques',
  // AMOUNT_OF_CESSIONS: 'Cantidad de cesiones',
};

export const TableHeaderToOperationProperty: Record<string, keyof Operation> = {
  ID: '_id',
  CLIENT: 'client',
};
