import React from 'react';

export type SearchBarType = {
  onChange: (searchValue: string) => void;
  icon?: string;
  placeholder: string;
};

const SearchBar = (props: SearchBarType) => {
  const { onChange, icon, placeholder } = props;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <input className="inputSearch" onChange={handleSearchChange} placeholder={placeholder}>
      {icon}
    </input>
  );
};

export default SearchBar;
